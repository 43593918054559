import React from 'react';
import MultilineTextField from '../../../../shared/components/multiline_textfield';
import MultiDocumentUpload from '../../../../shared/components/multi_document_upload';

class AdditionalForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      additional_details: this.props.compliance.additional_details,
      additional_documents: this.props.compliance.additional_documents ? this.props.compliance.additional_documents : []
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key], true);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);
  }

  documentsUpdated(documents, parent_data) {
    this.setState({ [parent_data.property]: documents });
    this.props.propertyUpdate(parent_data.property, documents);
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <span>Additional details and documents to clarify the KYC can be specified here</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <MultilineTextField
              label="Additional details"
              variant="outlined"
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.state.additional_details}
              name="additional_details"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.additional_details !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <MultiDocumentUpload
              title="Documents"
              required={false}
              access="escrow_only" 
              allowed_account_ids={[]}
              allow_authorised_accounts={false}
              documentsUpdated={this.documentsUpdated.bind(this)}
              documents={this.state.additional_documents}
              parent_data={{ property: "additional_documents" }} />
          </div>
        </div>

      </div>
    );
  }
}

export default AdditionalForm;

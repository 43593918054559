"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Mercurion Trade Accounts Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = exports.UsersApiFactory = exports.UsersApiFp = exports.UsersApiAxiosParamCreator = exports.InvitesApi = exports.InvitesApiFactory = exports.InvitesApiFp = exports.InvitesApiAxiosParamCreator = exports.ContactsApi = exports.ContactsApiFactory = exports.ContactsApiFp = exports.ContactsApiAxiosParamCreator = exports.AccountsApi = exports.AccountsApiFactory = exports.AccountsApiFp = exports.AccountsApiAxiosParamCreator = exports.UserRole = exports.AccountType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AccountType = {
    Admin: 'admin',
    Customer: 'customer',
    Escrow: 'escrow',
    Partner: 'partner'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.UserRole = {
    ReadOnly: 'read_only',
    Default: 'default',
    Authorised: 'authorised',
    Admin: 'admin'
};
/**
 * AccountsApi - axios parameter creator
 * @export
 */
exports.AccountsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create account
         * @param {AccountCreation} accountCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: function (accountCreation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'accountCreation' is not null or undefined
                    common_1.assertParamExists('createAccount', 'accountCreation', accountCreation);
                    localVarPath = "/accounts/create";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(accountCreation, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Delete user
         * @param {string} accountId
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (accountId, userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            common_1.assertParamExists('deleteUser', 'accountId', accountId);
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('deleteUser', 'userId', userId);
                            localVarPath = "/accounts/{account_id}/users/{user_id}"
                                .replace("{" + "account_id" + "}", encodeURIComponent(String(accountId)))
                                .replace("{" + "user_id" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get account
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: function (accountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            common_1.assertParamExists('getAccount', 'accountId', accountId);
                            localVarPath = "/accounts/{account_id}"
                                .replace("{" + "account_id" + "}", encodeURIComponent(String(accountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get accounts
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: function (page, size, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/accounts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccounts: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/accounts/all";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/accounts/current";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get partner account
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerAccount: function (accountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            common_1.assertParamExists('getPartnerAccount', 'accountId', accountId);
                            localVarPath = "/accounts/partner/{account_id}"
                                .replace("{" + "account_id" + "}", encodeURIComponent(String(accountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user
         * @param {string} accountId
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (accountId, userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            common_1.assertParamExists('getUser', 'accountId', accountId);
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('getUser', 'userId', userId);
                            localVarPath = "/accounts/{account_id}/users/{user_id}"
                                .replace("{" + "account_id" + "}", encodeURIComponent(String(accountId)))
                                .replace("{" + "user_id" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Invite user
         * @param {string} accountId
         * @param {AdminUserInvite} adminUserInvite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: function (accountId, adminUserInvite, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            common_1.assertParamExists('inviteUser', 'accountId', accountId);
                            // verify required parameter 'adminUserInvite' is not null or undefined
                            common_1.assertParamExists('inviteUser', 'adminUserInvite', adminUserInvite);
                            localVarPath = "/accounts/{account_id}/users"
                                .replace("{" + "account_id" + "}", encodeURIComponent(String(accountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(adminUserInvite, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Partner create account
         * @param {PartnerAccountCreation} partnerAccountCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerCreateAccount: function (partnerAccountCreation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'partnerAccountCreation' is not null or undefined
                            common_1.assertParamExists('partnerCreateAccount', 'partnerAccountCreation', partnerAccountCreation);
                            localVarPath = "/accounts/partner/create";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(partnerAccountCreation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set company details
         * @param {CompanyDetails} companyDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompanyDetails: function (companyDetails, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'companyDetails' is not null or undefined
                            common_1.assertParamExists('setCompanyDetails', 'companyDetails', companyDetails);
                            localVarPath = "/accounts/details";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(companyDetails, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update company details
         * @param {CompanyDetailsUpdate} companyDetailsUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyDetails: function (companyDetailsUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'companyDetailsUpdate' is not null or undefined
                            common_1.assertParamExists('updateCompanyDetails', 'companyDetailsUpdate', companyDetailsUpdate);
                            localVarPath = "/accounts/details";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(companyDetailsUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update user
         * @param {string} accountId
         * @param {string} userId
         * @param {AdminUserUpdate} adminUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (accountId, userId, adminUserUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            common_1.assertParamExists('updateUser', 'accountId', accountId);
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('updateUser', 'userId', userId);
                            // verify required parameter 'adminUserUpdate' is not null or undefined
                            common_1.assertParamExists('updateUser', 'adminUserUpdate', adminUserUpdate);
                            localVarPath = "/accounts/{account_id}/users/{user_id}"
                                .replace("{" + "account_id" + "}", encodeURIComponent(String(accountId)))
                                .replace("{" + "user_id" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(adminUserUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * AccountsApi - functional programming interface
 * @export
 */
exports.AccountsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AccountsApiAxiosParamCreator(configuration);
    return {
        /**
         * Create account
         * @param {AccountCreation} accountCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: function (accountCreation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createAccount(accountCreation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete user
         * @param {string} accountId
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (accountId, userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUser(accountId, userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get account
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: function (accountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccount(accountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get accounts
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: function (page, size, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccounts(page, size, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccounts: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllAccounts(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCurrentAccount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get partner account
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerAccount: function (accountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPartnerAccount(accountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user
         * @param {string} accountId
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (accountId, userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUser(accountId, userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Invite user
         * @param {string} accountId
         * @param {AdminUserInvite} adminUserInvite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: function (accountId, adminUserInvite, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.inviteUser(accountId, adminUserInvite, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Partner create account
         * @param {PartnerAccountCreation} partnerAccountCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerCreateAccount: function (partnerAccountCreation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.partnerCreateAccount(partnerAccountCreation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set company details
         * @param {CompanyDetails} companyDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompanyDetails: function (companyDetails, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setCompanyDetails(companyDetails, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update company details
         * @param {CompanyDetailsUpdate} companyDetailsUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyDetails: function (companyDetailsUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCompanyDetails(companyDetailsUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update user
         * @param {string} accountId
         * @param {string} userId
         * @param {AdminUserUpdate} adminUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (accountId, userId, adminUserUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUser(accountId, userId, adminUserUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AccountsApi - factory interface
 * @export
 */
exports.AccountsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AccountsApiFp(configuration);
    return {
        /**
         * Create account
         * @param {AccountCreation} accountCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: function (accountCreation, options) {
            return localVarFp.createAccount(accountCreation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete user
         * @param {string} accountId
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (accountId, userId, options) {
            return localVarFp.deleteUser(accountId, userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get account
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: function (accountId, options) {
            return localVarFp.getAccount(accountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get accounts
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: function (page, size, options) {
            return localVarFp.getAccounts(page, size, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccounts: function (options) {
            return localVarFp.getAllAccounts(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccount: function (options) {
            return localVarFp.getCurrentAccount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get partner account
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerAccount: function (accountId, options) {
            return localVarFp.getPartnerAccount(accountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user
         * @param {string} accountId
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (accountId, userId, options) {
            return localVarFp.getUser(accountId, userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Invite user
         * @param {string} accountId
         * @param {AdminUserInvite} adminUserInvite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: function (accountId, adminUserInvite, options) {
            return localVarFp.inviteUser(accountId, adminUserInvite, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Partner create account
         * @param {PartnerAccountCreation} partnerAccountCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerCreateAccount: function (partnerAccountCreation, options) {
            return localVarFp.partnerCreateAccount(partnerAccountCreation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set company details
         * @param {CompanyDetails} companyDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompanyDetails: function (companyDetails, options) {
            return localVarFp.setCompanyDetails(companyDetails, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update company details
         * @param {CompanyDetailsUpdate} companyDetailsUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyDetails: function (companyDetailsUpdate, options) {
            return localVarFp.updateCompanyDetails(companyDetailsUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update user
         * @param {string} accountId
         * @param {string} userId
         * @param {AdminUserUpdate} adminUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (accountId, userId, adminUserUpdate, options) {
            return localVarFp.updateUser(accountId, userId, adminUserUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
var AccountsApi = /** @class */ (function (_super) {
    __extends(AccountsApi, _super);
    function AccountsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create account
     * @param {AccountCreation} accountCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.createAccount = function (accountCreation, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).createAccount(accountCreation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete user
     * @param {string} accountId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.deleteUser = function (accountId, userId, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).deleteUser(accountId, userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get account
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAccount = function (accountId, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).getAccount(accountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get accounts
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAccounts = function (page, size, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).getAccounts(page, size, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAllAccounts = function (options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).getAllAccounts(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getCurrentAccount = function (options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).getCurrentAccount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get partner account
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getPartnerAccount = function (accountId, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).getPartnerAccount(accountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user
     * @param {string} accountId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getUser = function (accountId, userId, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).getUser(accountId, userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Invite user
     * @param {string} accountId
     * @param {AdminUserInvite} adminUserInvite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.inviteUser = function (accountId, adminUserInvite, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).inviteUser(accountId, adminUserInvite, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Partner create account
     * @param {PartnerAccountCreation} partnerAccountCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.partnerCreateAccount = function (partnerAccountCreation, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).partnerCreateAccount(partnerAccountCreation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set company details
     * @param {CompanyDetails} companyDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.setCompanyDetails = function (companyDetails, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).setCompanyDetails(companyDetails, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update company details
     * @param {CompanyDetailsUpdate} companyDetailsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.updateCompanyDetails = function (companyDetailsUpdate, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).updateCompanyDetails(companyDetailsUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update user
     * @param {string} accountId
     * @param {string} userId
     * @param {AdminUserUpdate} adminUserUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.updateUser = function (accountId, userId, adminUserUpdate, options) {
        var _this = this;
        return exports.AccountsApiFp(this.configuration).updateUser(accountId, userId, adminUserUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AccountsApi;
}(base_1.BaseAPI));
exports.AccountsApi = AccountsApi;
/**
 * ContactsApi - axios parameter creator
 * @export
 */
exports.ContactsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create contact
         * @param {ContactCreation} contactCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: function (contactCreation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contactCreation' is not null or undefined
                            common_1.assertParamExists('createContact', 'contactCreation', contactCreation);
                            localVarPath = "/contacts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(contactCreation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete contact
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteContact', 'id', id);
                            localVarPath = "/contacts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all contacts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContacts: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/contacts/all";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get contact
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getContact', 'id', id);
                            localVarPath = "/contacts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get contacts
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: function (page, size, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/contacts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ContactsApi - functional programming interface
 * @export
 */
exports.ContactsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ContactsApiAxiosParamCreator(configuration);
    return {
        /**
         * Create contact
         * @param {ContactCreation} contactCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: function (contactCreation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createContact(contactCreation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete contact
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteContact(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all contacts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContacts: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllContacts(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get contact
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContact(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get contacts
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: function (page, size, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContacts(page, size, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ContactsApi - factory interface
 * @export
 */
exports.ContactsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ContactsApiFp(configuration);
    return {
        /**
         * Create contact
         * @param {ContactCreation} contactCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: function (contactCreation, options) {
            return localVarFp.createContact(contactCreation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete contact
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: function (id, options) {
            return localVarFp.deleteContact(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all contacts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContacts: function (options) {
            return localVarFp.getAllContacts(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get contact
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: function (id, options) {
            return localVarFp.getContact(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get contacts
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: function (page, size, options) {
            return localVarFp.getContacts(page, size, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
var ContactsApi = /** @class */ (function (_super) {
    __extends(ContactsApi, _super);
    function ContactsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create contact
     * @param {ContactCreation} contactCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    ContactsApi.prototype.createContact = function (contactCreation, options) {
        var _this = this;
        return exports.ContactsApiFp(this.configuration).createContact(contactCreation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    ContactsApi.prototype.deleteContact = function (id, options) {
        var _this = this;
        return exports.ContactsApiFp(this.configuration).deleteContact(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all contacts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    ContactsApi.prototype.getAllContacts = function (options) {
        var _this = this;
        return exports.ContactsApiFp(this.configuration).getAllContacts(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    ContactsApi.prototype.getContact = function (id, options) {
        var _this = this;
        return exports.ContactsApiFp(this.configuration).getContact(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get contacts
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    ContactsApi.prototype.getContacts = function (page, size, options) {
        var _this = this;
        return exports.ContactsApiFp(this.configuration).getContacts(page, size, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ContactsApi;
}(base_1.BaseAPI));
exports.ContactsApi = ContactsApi;
/**
 * InvitesApi - axios parameter creator
 * @export
 */
exports.InvitesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create invite
         * @param {InviteCreation} inviteCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite: function (inviteCreation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'inviteCreation' is not null or undefined
                            common_1.assertParamExists('createInvite', 'inviteCreation', inviteCreation);
                            localVarPath = "/invites";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inviteCreation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvite: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteInvite', 'id', id);
                            localVarPath = "/invites/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvite: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getInvite', 'id', id);
                            localVarPath = "/invites/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invites
         * @param {number} [page]
         * @param {number} [size]
         * @param {boolean} [all]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvites: function (page, size, all, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invites";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (all !== undefined) {
                                localVarQueryParameter['all'] = all;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Resend invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvite: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('resendInvite', 'id', id);
                            localVarPath = "/invites/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * InvitesApi - functional programming interface
 * @export
 */
exports.InvitesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.InvitesApiAxiosParamCreator(configuration);
    return {
        /**
         * Create invite
         * @param {InviteCreation} inviteCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite: function (inviteCreation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createInvite(inviteCreation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvite: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteInvite(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvite: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvite(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invites
         * @param {number} [page]
         * @param {number} [size]
         * @param {boolean} [all]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvites: function (page, size, all, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvites(page, size, all, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Resend invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvite: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.resendInvite(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * InvitesApi - factory interface
 * @export
 */
exports.InvitesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.InvitesApiFp(configuration);
    return {
        /**
         * Create invite
         * @param {InviteCreation} inviteCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite: function (inviteCreation, options) {
            return localVarFp.createInvite(inviteCreation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvite: function (id, options) {
            return localVarFp.deleteInvite(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvite: function (id, options) {
            return localVarFp.getInvite(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invites
         * @param {number} [page]
         * @param {number} [size]
         * @param {boolean} [all]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvites: function (page, size, all, options) {
            return localVarFp.getInvites(page, size, all, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Resend invite
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvite: function (id, options) {
            return localVarFp.resendInvite(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * InvitesApi - object-oriented interface
 * @export
 * @class InvitesApi
 * @extends {BaseAPI}
 */
var InvitesApi = /** @class */ (function (_super) {
    __extends(InvitesApi, _super);
    function InvitesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create invite
     * @param {InviteCreation} inviteCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    InvitesApi.prototype.createInvite = function (inviteCreation, options) {
        var _this = this;
        return exports.InvitesApiFp(this.configuration).createInvite(inviteCreation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete invite
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    InvitesApi.prototype.deleteInvite = function (id, options) {
        var _this = this;
        return exports.InvitesApiFp(this.configuration).deleteInvite(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invite
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    InvitesApi.prototype.getInvite = function (id, options) {
        var _this = this;
        return exports.InvitesApiFp(this.configuration).getInvite(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invites
     * @param {number} [page]
     * @param {number} [size]
     * @param {boolean} [all]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    InvitesApi.prototype.getInvites = function (page, size, all, options) {
        var _this = this;
        return exports.InvitesApiFp(this.configuration).getInvites(page, size, all, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Resend invite
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    InvitesApi.prototype.resendInvite = function (id, options) {
        var _this = this;
        return exports.InvitesApiFp(this.configuration).resendInvite(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InvitesApi;
}(base_1.BaseAPI));
exports.InvitesApi = InvitesApi;
/**
 * UsersApi - axios parameter creator
 * @export
 */
exports.UsersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/users/current";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update current user
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: function (userUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userUpdate' is not null or undefined
                            common_1.assertParamExists('updateCurrentUser', 'userUpdate', userUpdate);
                            localVarPath = "/users/current";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * UsersApi - functional programming interface
 * @export
 */
exports.UsersApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UsersApiAxiosParamCreator(configuration);
    return {
        /**
         * Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCurrentUser(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update current user
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: function (userUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCurrentUser(userUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UsersApi - factory interface
 * @export
 */
exports.UsersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UsersApiFp(configuration);
    return {
        /**
         * Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: function (options) {
            return localVarFp.getCurrentUser(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update current user
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: function (userUpdate, options) {
            return localVarFp.updateCurrentUser(userUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
var UsersApi = /** @class */ (function (_super) {
    __extends(UsersApi, _super);
    function UsersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getCurrentUser = function (options) {
        var _this = this;
        return exports.UsersApiFp(this.configuration).getCurrentUser(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update current user
     * @param {UserUpdate} userUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.updateCurrentUser = function (userUpdate, options) {
        var _this = this;
        return exports.UsersApiFp(this.configuration).updateCurrentUser(userUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UsersApi;
}(base_1.BaseAPI));
exports.UsersApi = UsersApi;

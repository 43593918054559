import React from 'react';
import { logout } from '../services/authentication_service';
import ParentMenu from './parent_menu';

class Menu extends React.Component {

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={[
        {
          name: "Sign out",
          icon: "fas fa-sign-out-alt",
          onClick: () => logout()
        }
      ]}
      sidebar_items={[]}
    />;
  }
}

export default Menu;

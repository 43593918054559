import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import AsyncButton from '../../components/async_button';
import { getCurrentUser, updateCurrentUser } from '../../services/accounts_service';
import { getProperties, updateProperties } from '../../services/admin_service';
import { hasType, isAdmin } from '../../services/authentication_service';
import Mapper from '../../util/mapper';
import DomUtil from '../../util/dom_util';
import PasswordForm from '../../components/password_form';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ErrorHandler from '../../util/error_handler';
import alertDialog from '../../components/alert';
import LoadingTextField from '../../components/loading_text_field'
import LoadingSelectField from '../../components/loading_select_field'

class Settings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: undefined,
      password_errors: [ "length", "case", "numeric", "symbol", "confirm" ],
      properties: undefined
    }
  }

  componentWillMount() {
    this.getCurrentUser();
    if(hasType(["escrow"]) || hasType(["admin"])) {
      this.getProperties();
    }
  }

  getCurrentUser() {
    getCurrentUser().then((user) => {
      if (user) {
        this.setState({ user: user });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getProperties() {
    getProperties().then((properties) => {
      if (properties) {
        this.setState({ properties: properties });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.user[e.target.name] = value;
    this.setState({ user: this.state.user });
  }

  passwordUpdated(password, has_errors) {
    this.state.password = password;
    this.state.password_has_errors = has_errors;
    this.setState({ user: this.state.user });
  }

  handlePropertyChange(index, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.properties[index].value = value;
    this.setState({ properties: this.state.properties });
  }

  updateNotifications(e) {
    e.preventDefault();
    var form = e.currentTarget;

    DomUtil.disableFormSubmitButtonClass(form);
    updateCurrentUser({ notifications: this.state.user.notifications }).then(() => {
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Message", "Notifications updated")
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  updatePassword(e) {
    e.preventDefault();
    var form = e.currentTarget;

    if(this.state.password_has_errors) {
      alertDialog("Alert", "Password does not match all requirements");
      return;
    }
    
    DomUtil.disableFormSubmitButtonClass(form);
    updateCurrentUser({ password: this.state.password }).then(() => {
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Message", "Password has been updated")
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  updateEscrowProperties(e) {
    e.preventDefault();
    var form = e.currentTarget;

    DomUtil.disableFormSubmitButtonClass(form);
    updateProperties(this.state.properties).then(() => {
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Message", "Properties updated")
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  openTerms(e) {
    e.preventDefault();
    var url = `/document/terms-and-conditions?file_name=General-Terms-and-Conditions.pdf`;
    window.open(url, "_blank");
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Settings</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <form autoComplete="off" onSubmit={this.updateNotifications.bind(this)}>

              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Notifications</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  { this.state.user &&
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth>
                      <InputLabel shrink={true} >Notifications *</InputLabel>
                      <Select
                        native
                        required
                        label="Notifications *"
                        value={this.state.user.notifications}
                        name="notifications"
                        onChange={this.handleChange.bind(this)} >
                          <option key={"notifications_empty"} value=""></option>
                          {Object.keys(Mapper.booleans()).map(boolean => {
                            return (<option key={"notifications_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                          })}
                      </Select>
                    </FormControl>
                  }
                  { !this.state.user &&
                    <LoadingSelectField
                      label="Notifications"
                      shrink={true}
                    />
                  }
                </div>
              </div>
              
              <div className="row">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Update" />
                </div>
              </div>

            </form>

          </div>

          <div className="container-body">

            <form autoComplete="off" onSubmit={this.updatePassword.bind(this)}>

              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Password</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <PasswordForm passwordUpdated={this.passwordUpdated.bind(this)} />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Update" />
                </div>
              </div>

            </form>

          </div>

          { (hasType(["escrow"]) || hasType(["admin"])) &&
            <div className="container-body">

              <form autoComplete="off" onSubmit={this.updateEscrowProperties.bind(this)}>

                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Poperties</label>
                    </div>
                  </div>
                </div>

                {this.state.properties && this.state.properties.map((property, index) => {
                  return (
                    <div key={property.type} className="row">
                      <div className="col-md-4">
                        <TextField
                          label={Mapper.fromPropertyType(property.type)}
                          variant="outlined"
                          required
                          disabled={!isAdmin()}
                          type="number"
                          fullWidth
                          margin="dense"
                          value={property.value}
                          onChange={this.handlePropertyChange.bind(this, index)}
                          InputProps={{
                            inputProps: { min: 0, max: 100, step: 0.1 },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          InputLabelProps={{
                            shrink: property.value !== undefined,
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
                {!this.state.properties &&
                  <div className="row">
                    <div className="col-md-4">
                      <LoadingTextField/>
                    </div>
                  </div>
                }
                {!this.state.properties &&
                  <div className="row">
                    <div className="col-md-4">
                      <LoadingTextField/>
                    </div>
                  </div>
                }
                
                { isAdmin() &&
                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Update" />
                    </div>
                  </div>
                }

                <br/>

              </form>

            </div>
          }

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Terms and conditions</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <span>You have read and agreed to the&nbsp;</span>
                <Link
                  className="inline-link"
                  component="button"
                  variant="body2"
                  type="button"
                  onClick={this.openTerms.bind(this)}>
                    terms and conditions
                </Link>
              </div>
            </div>


          </div>
          
        </div>
      </div >
    );
  }
}

export default Settings;

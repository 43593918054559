import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';
import confirm from '../../../../shared/components/confirm.js'
import Mapper from '../../../../shared/util/mapper'
import GeneralForm from '../components/general_form';
import RouteForm from '../components/route_form';
import CargoForm from '../components/cargo_form';
import PaymentMilestonesForm from '../components/payment_milestones_form';
import TransportMilestonesForm from '../components/transport_milestones_form';

class SpecifyDetails extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
        
    this.state = {
      flow_patch: {
        status: "seller_specify_details",
        action: "transaction_details_specified"
      }
    };
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to submit this transaction?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var new_flow_patch = Mapper.toTransactionDetails(this.state.flow_patch);
      this.props.updateTransaction(new_flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  deleteTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to delete this transaction?`).then((result) => {
      DomUtil.disableClass(button);
      this.props.updateTransaction( {
        status: "seller_specify_details",
        action: "deleted"
      }).then(() => {
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }
  
  propertyUpdate(key, value) {
    this.props.transaction[key] = value;
    this.state.flow_patch[key] = value;
    this.setState({ flow_patch: this.state.flow_patch })
  }
  
  hasPaidMilestone() {
    return this.props.transaction.payment_milestones.find(m => m.status && m.status !== "outstanding");
  }

  isTrue(value) {
    return value === "true" || value === true;
  }
  
  render() {
    return (
      <div>

        { this.props.transaction.messages && this.props.transaction.messages.length > 0 &&
          <div className="row action-required-row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">A change of the transaction was requested by {this.props.transaction.messages[0].message_by.registered_name}. Request: {this.props.transaction.messages[0].message}</Alert>
            </div>
          </div>
        }
        
        <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Transaction details</label>
                </div>
              </div>
            </div>

            <GeneralForm transaction={this.props.transaction} propertyUpdate={this.propertyUpdate.bind(this)} />
            
            <RouteForm transaction={this.props.transaction} propertyUpdate={this.propertyUpdate.bind(this)} />

            <CargoForm transaction={this.props.transaction} propertyUpdate={this.propertyUpdate.bind(this)} />

          </div>
          
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Payment milestones</label>
                </div>
              </div>
            </div>

            <PaymentMilestonesForm transaction={this.props.transaction} propertyUpdate={this.propertyUpdate.bind(this)} />

          </div>
          
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Transport milestones</label>
                </div>
              </div>
            </div>

            <TransportMilestonesForm transaction={this.props.transaction} propertyUpdate={this.propertyUpdate.bind(this)} />

          </div>
                    
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <span>The form can be submitted once it has been filled in completly.</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <AsyncButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  icon="check"
                  text="Submit" />
                { !this.hasPaidMilestone() &&
                  <AsyncButton
                    className="button-danger-outlined"
                    variant="outlined"
                    color="danger"
                    onClick={this.deleteTransaction.bind(this)}
                    icon="delete"
                    text="Delete" />
                  }
                { this.hasPaidMilestone() &&
                  <Button
                    className="button-danger-outlined"
                    variant="outlined"
                    color="danger"
                    disabled
                    startIcon={<Icon>delete</Icon>}>
                    Delete
                  </Button>
                }
              </div>
            </div>

          </div>

        </form>
          
      </div>
    );
  }
}

export default SpecifyDetails;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
/* Shared */
import CacheBuster from './apps/shared/components/cache_buster';
import Theme from './apps/shared/components/theme';
import ScrollToTop from "./apps/shared/components/scrolltop";
import Authenticated from './apps/shared/components/authenticated';
import NotAuthenticated from './apps/shared/components/not_authenticated';
import Login from './apps/shared/pages/login/index';
import MfaVerification from './apps/shared/pages/mfa/index';
import Redirect from './apps/shared/pages/redirect/index';
import UpdateUser from './apps/shared/pages/update/index';
import SelectAccount from './apps/shared/pages/select-account/index';
import ViewDocument from './apps/shared/pages/document/index';
import SharedMenu from './apps/shared/components/menu';
import TradeTransactions from './apps/shared/pages/trade-transactions/index';
import FreightTransactions from './apps/shared/pages/freight-transactions/index';
import Compliances from './apps/shared/pages/compliances/index';
import Compliance from './apps/shared/pages/compliances/show';
import Settings from './apps/shared/pages/settings/index';
import Account from './apps/shared/pages/account/index';
import EditAccount from './apps/shared/pages/account/edit';
import InviteUser from './apps/shared/pages/account/invite';
import ShowUser from './apps/shared/pages/account/show';
import Calculator from './apps/shared/pages/calculator/index';
import Invoices from './apps/shared/pages/invoices/index';
import Invoice from './apps/shared/pages/invoices/show';
import Support from './apps/shared/pages/support/index';
import Invites from './apps/shared/pages/invites/index';
import CreateInvite from './apps/shared/pages/invites/create';
import ShowInvite from './apps/shared/pages/invites/show';
import Dashboard from './apps/shared/pages/dashboard/index';
import Authorisation from './apps/shared/pages/authorisation/show';
import CreateApiKey from './apps/shared/pages/api-keys/create';
import ShowApiKey from './apps/shared/pages/api-keys/show';
import CreateWebhook from './apps/shared/pages/webhooks/create';
import ShowWebhook from './apps/shared/pages/webhooks/show';
/* Customer */
import CustomerMenu from './apps/customer/components/menu';
import CreateCustomerTradeTransaction from './apps/customer/pages/trade-transactions/create';
import CustomerTradeTransaction from './apps/customer/pages/trade-transactions/show';
import CustomerFreightTransaction from './apps/customer/pages/freight-transactions/show';
import CustomerCompliance from './apps/customer/pages/compliance/index';
import Contacts from './apps/customer/pages/contacts/index';
import CreateContact from './apps/customer/pages/contacts/create';
import ShowContact from './apps/customer/pages/contacts/show';
/* Escrow */
import EscrowMenu from './apps/escrow/components/menu';
import EscrowTradeTransaction from './apps/escrow/pages/trade-transactions/show';
import EscrowFreightTransaction from './apps/escrow/pages/freight-transactions/show';
/* Partner */
import PartnerMenu from './apps/partner/components/menu';
import PartnerAccounts from './apps/partner/pages/accounts/index';
import PartnerTradeTransaction from './apps/partner/pages/trade-transactions/show';
import PartnerFreightTransaction from './apps/partner/pages/freight-transactions/show';
/* Admin */
import AdminMenu from './apps/admin/components/menu';
import AdminTradeTransaction from './apps/admin/pages/trade-transactions/show';
import AdminFreightTransaction from './apps/admin/pages/freight-transactions/show';
import AdminAccounts from './apps/admin/pages/accounts/index';
import AdminAccount from './apps/admin/pages/accounts/show';
import AdminInvites from './apps/admin/pages/invites/index';
import AdminInvoices from './apps/admin/pages/invoices/index';
import AdminInvoice from './apps/admin/pages/invoices/show';

TimeAgo.addDefaultLocale(en)

const history = createBrowserHistory({forceRefresh:false});

ReactDOM.render((
  <CacheBuster>
    <Theme>
      <Router history={history} basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <Authenticated history={history} types={["customer"]}>
            <Switch>
              <CustomerMenu history={history}>
                <Switch>
                  <Route exact path={"/transactions/trade"} component={TradeTransactions} />
                  <Route exact path={"/transactions/trade/create"} component={CreateCustomerTradeTransaction} />
                  <Route exact path={"/transactions/trade/:transaction_id"} component={CustomerTradeTransaction} />
                  <Route exact path={"/transactions/freight"} component={FreightTransactions} />
                  <Route exact path={"/transactions/freight/:transaction_id"} component={CustomerFreightTransaction} />
                  <Route exact path={"/calculator"} component={Calculator} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/compliance"} component={CustomerCompliance} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/contacts"} component={Contacts} />
                  <Route exact path={"/contacts/create"} component={CreateContact} />
                  <Route exact path={"/contacts/:contact_id"} component={ShowContact} />
                  <Route exact path={"/invites/create"} component={(props, state, params) => <CreateInvite {...props} return_url="/contacts/" return_name="Contacts" />} />
                  <Route exact path={"/invites/:invite_id"} component={(props, state, params) => <ShowInvite {...props} return_url="/contacts/" return_name="Contacts" />} />
                  <Route exact path={"/invoices"} component={Invoices} />
                  <Route exact path={"/invoices/:invoice_id"} component={Invoice} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={"/support"} component={Support} />
                  <Route exact path={`/document/:document_id`} component={ViewDocument} />
                  <Route exact path={`/authorisation/:account_id`} component={Authorisation} />
                </Switch>
              </CustomerMenu>
            </Switch>
          </Authenticated>
          <Authenticated history={history} types={["escrow"]}>
            <Switch>
              <EscrowMenu history={history}>
                <Switch>
                  <Route exact path={"/dashboard"} component={Dashboard} />
                  <Route exact path={"/transactions/trade"} component={TradeTransactions} />
                  <Route exact path={"/transactions/trade/:transaction_id"} component={EscrowTradeTransaction} />
                  <Route exact path={"/transactions/freight"} component={FreightTransactions} />
                  <Route exact path={"/transactions/freight/:transaction_id"} component={EscrowFreightTransaction} />
                  <Route exact path={"/compliances"} component={Compliances} />
                  <Route exact path={"/compliances/:compliance_id"} component={Compliance} />
                  <Route exact path={"/calculator"} component={Calculator} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/invoices"} component={Invoices} />
                  <Route exact path={"/invoices/:invoice_id"} component={Invoice} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={`/document/:document_id`} component={ViewDocument} />
              </Switch>
              </EscrowMenu>
            </Switch>
          </Authenticated>
          <Authenticated history={history} types={["partner"]}>
            <Switch>
              <PartnerMenu history={history}>
                <Switch>
                  <Route exact path={"/dashboard"} component={Dashboard} />
                  <Route exact path={"/accounts"} component={PartnerAccounts} />
                  <Route exact path={"/transactions/trade"} component={TradeTransactions} />
                  <Route exact path={"/transactions/trade/:transaction_id"} component={PartnerTradeTransaction} />
                  <Route exact path={"/transactions/freight"} component={FreightTransactions} />
                  <Route exact path={"/transactions/freight/:transaction_id"} component={PartnerFreightTransaction} />
                  <Route exact path={"/invites"} component={Invites} />
                  <Route exact path={"/invites/create"} component={(props, state, params) => <CreateInvite {...props} return_url="/invites/" return_name="Invitations" />} />
                  <Route exact path={"/invites/:invite_id"} component={(props, state, params) => <ShowInvite {...props} return_url="/invites/" return_name="Invitations" />} />
                  <Route exact path={"/calculator"} component={Calculator} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/edit"} component={EditAccount} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/api-keys/create"} component={CreateApiKey} />
                  <Route exact path={"/account/api-keys/:api_key_id"} component={ShowApiKey} />
                  <Route exact path={"/account/webhooks/create"} component={CreateWebhook} />
                  <Route exact path={"/account/webhooks/:webhook_id"} component={ShowWebhook} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/invoices"} component={Invoices} />
                  <Route exact path={"/invoices/:invoice_id"} component={Invoice} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={"/support"} component={Support} />
                  <Route exact path={`/document/:document_id`} component={ViewDocument} />
                </Switch>
              </PartnerMenu>
            </Switch>
          </Authenticated>
          <Authenticated history={history} types={["admin"]}>
            <Switch>
              <AdminMenu history={history}>
                <Switch>
                  <Route exact path={"/dashboard"} component={Dashboard} />
                  <Route exact path={"/transactions/trade"} component={TradeTransactions} />
                  <Route exact path={"/transactions/trade/:transaction_id"} component={AdminTradeTransaction} />
                  <Route exact path={"/transactions/freight"} component={FreightTransactions} />
                  <Route exact path={"/transactions/freight/:transaction_id"} component={AdminFreightTransaction} />
                  <Route exact path={"/compliances"} component={Compliances} />
                  <Route exact path={"/compliances/:compliance_id"} component={Compliance} />
                  <Route exact path={"/calculator"} component={Calculator} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/edit"} component={EditAccount} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/invoices"} component={Invoices} />
                  <Route exact path={"/invoices/:invoice_id"} component={Invoice} />
                  <Route exact path={"/accounts"} component={AdminAccounts} />
                  <Route exact path={"/accounts/:account_id"} component={AdminAccount} />
                  <Route exact path={"/all_invites"} component={AdminInvites} />
                  <Route exact path={"/all_invoices"} component={AdminInvoices} />
                  <Route exact path={"/all_invoices/:invoice_id"} component={AdminInvoice} />
                  <Route exact path={"/invites"} component={Invites} />
                  <Route exact path={"/invites/create"} component={(props, state, params) => <CreateInvite {...props} return_url="/invites/" return_name="Invitations" />} />
                  <Route exact path={"/invites/:invite_id"} component={(props, state, params) => <ShowInvite {...props} return_url="/invites/" return_name="Invitations" />} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={`/document/:document_id`} component={ViewDocument} />
                </Switch>
              </AdminMenu>
            </Switch>
          </Authenticated>
          <NotAuthenticated history={history}>
            <Switch>
              <Route exact path={`/document/:document_id`} component={(props, state, params) => 
                  <SharedMenu {...props} >
                    <ViewDocument {...props} />
                  </SharedMenu>
                }
              />
            </Switch>
          </NotAuthenticated>
          <Switch>
              <Route exact path={`/update`} component={(props, state, params) => 
                  <SharedMenu {...props} >
                    <UpdateUser {...props} />
                  </SharedMenu>
                }
              />
              <Route exact path={`/select-account`} component={(props, state, params) => 
                  <SharedMenu {...props} >
                    <SelectAccount {...props} />
                  </SharedMenu>
                }
              />
              <Route exact path={`/login`} component={Login} />
              <Route exact path={`/mfa-verification`} component={MfaVerification} />
            <Route exact path={`/`} component={Redirect} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Theme>
  </CacheBuster>
), document.getElementById('root'))

import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import DomUtil from '../../util/dom_util';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { createWebhook } from '../../services/webhooks_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper';

class CreateWebhook extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      webhook: {
        event_types: []
      }
    };
  }

  handleChange(e) {
    this.state.webhook[e.target.name] = e.target.value;
    this.setState({ webhook: this.state.webhook });
  }
  
  createWebhook(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var webhook_creation = Mapper.toWebhookCreation(this.state.webhook);
    createWebhook(webhook_creation).then((new_webhook) => {
      this.props.history.push(`/account/webhooks/${new_webhook.id}`);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }
  
  renderSelectedTypes(selected) {
    var names = [];
    selected.forEach(function (value) {
      names.push(Mapper.fromWebhookEventTypes(value));
    });
    return names.join(', ');
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={"/account" + this.props.location.search}>Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>Create Webhook</BreadcrumbItem>
        </Breadcrumb>
        
        <div className="container-body">
          
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Create Webhook</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.createWebhook.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        label="Callback URL"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="callback_url"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Event types *</InputLabel>
                        <Select
                          required
                          multiple
                          label="Event types *"
                          name="event_types"
                          value={this.state.webhook.event_types}
                          renderValue={(selected) => this.renderSelectedTypes(selected)}
                          onChange={this.handleChange.bind(this)} 
                          >
                            {Object.keys(Mapper.webhookEventTypes()).map(event_type => {
                              return (
                                <MenuItem key={event_type} value={event_type}>
                                  <Checkbox checked={this.state.webhook.event_types.indexOf(event_type) > -1} />
                                  <ListItemText primary={Mapper.fromWebhookEventTypes(event_type)} />
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Create" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default CreateWebhook;

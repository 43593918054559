import React from 'react';
import Skeleton from 'react-loading-skeleton';

class LoadingText extends React.Component {
  
  render() {
    if(this.props.text) {
      return this.props.text;
    } else {
      return <Skeleton count={this.props.rows ? this.props.rows : 1}/>;
    }
  }
}

export default LoadingText;

import React from 'react';

class ReleaseFunds extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Release funds</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for the funds to be released by the Escrow Agent.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ReleaseFunds;

import React from 'react';
import confirm from '../../../../shared/components/confirm.js'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class TrackingNotFound extends React.Component {

  constructor(props) {
    super(props)
    this.previousState = this.previousState.bind(this);
    this.skipTracking = this.skipTracking.bind(this);
  }
  
  previousState(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure you want return to the try again with different tracking details?").then(() => {
      DomUtil.disableClass(button);
      this.props.updateTransaction({
        status: "tracking_not_found",
        action: "previous"
      }).then(() => {
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }

  skipTracking(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to continue the shipment without tracking (not advised)?").then(() => {
      DomUtil.disableClass(button);
      this.props.updateTransaction({
        status: "tracking_not_found",
        action: "tracking_skipped"
      }).then(() => {
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Tracking details not found</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>The provided tracking details did not return any results.</span>
              <br/>
              <span>You can go back and try again with different tracking details, or continue the shipment without tracking (not advised).</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <form autoComplete="off" onSubmit={this.previousState.bind(this)}>

                <div className="row">
                  <div className="col-md">
                  <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="replay"
                      text="Try again" />
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.skipTracking.bind(this)}
                      icon="start"
                      text="Skip tracking" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default TrackingNotFound;

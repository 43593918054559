import React from 'react';
import { loggedIn, mfaVerification, actionRequired } from '../services/authentication_service';

class NotAuthenticated extends React.Component {

  render() {
    if (loggedIn() && !mfaVerification() && !actionRequired()) {
      return <div></div>
    }

    return <div>{this.props.children}</div>
  }
}

export default NotAuthenticated;

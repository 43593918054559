import React from 'react';
import alertDialog from '../components/alert';

class ErrorHandler extends React.Component {

  static showError(error) {
    var message = "Something went wrong";
    if (error.response) {
      var data = error.response.data;
      console.error(data);
      if(data.message) {
        message = data.message;
      }
    } else if (error.message) {
      message = error.message;
      console.error(error);
    } else {
      console.error(error);      
    }    
    alertDialog("Alert", message);
  }
}

export default ErrorHandler;

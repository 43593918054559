import React from 'react';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import confirm from '../../../../shared/components/confirm.js'
import Converter from '../../../../shared/util/converter.js';
import StringUtil from '../../../../shared/util/string_util.js';
import AsyncButton from '../../../../shared/components/async_button.js';
import DomUtil from '../../../../shared/util/dom_util.js';
import Mapper from '../../../../shared/util/mapper.js';

class FreightPayment extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      paid: this.props.transaction.states.find(s => s.status === "freight_payment_notification") === undefined,
      flow_patch: this.initiaState()
    };
  }

  resetState() {
    var initialValues = this.initiaState();
    var that = this;
    Object.keys(this.state.flow_patch).map(function (key) {
      if(initialValues[key]) {
        that.state.flow_patch[key] = initialValues[key];
      } else {
        that.state.flow_patch[key] = "";
      }
    });
    this.setState({ flow_patch: this.state.flow_patch, paid: false });
  }
  
  initiaState() {
    return {
      status: "freight_payment",
      action: "freight_received"
    };
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you received the freight payment?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        this.resetState();
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.flow_patch[e.target.name] = value;
    this.setState({ flow_patch: this.state.flow_patch })
  }

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Receive freight payment</label>
              </div>
            </div>
          </div>

          { this.state.paid &&
            <div>
              <div className="row">
                <div className="col-md-12">
                  <Alert severity="info">The Charterer has indicated that the payment has been made.</Alert>
                </div>
              </div>
            </div>
          }
          
          <div className="row">
            <div className="col-md">
              <span>Please verify that the Charterer has paid the freight payment.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Payment information:</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td>Amount</td>
                    <td>{Converter.toCurrency(this.props.transaction.currency, this.props.transaction.commercial_invoice_amount)}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Payment reference</td>
                    <td>Payment Transaction {StringUtil.shortId(this.props.transaction.id)}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank account number</td>
                    <td>{this.props.transaction.bank_details.bank_account}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank account holder</td>
                    <td>{this.props.transaction.bank_details.bank_account_holder}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank name</td>
                    <td>{this.props.transaction.bank_details.bank_name}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank address</td>
                    <td>{this.props.transaction.bank_details.bank_address}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank SWIFT</td>
                    <td>{this.props.transaction.bank_details.bank_swift_code}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Correspondent bank</td>
                    <td>{this.props.transaction.bank_details.intermediary_bank}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Correspondent bank SWIFT</td>
                    <td>{this.props.transaction.bank_details.intermediary_bank_swift_code}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Amount"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.flow_patch.amount}
                    name="amount"
                    onChange={this.handleChange.bind(this)}
                    InputProps={{
                      inputComponent: Converter.numberFormatter,
                      startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.props.transaction.currency)}</InputAdornment>,
                    }}
                  />
                  </div>                    
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Date received"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.flow_patch.received_at}
                    name="received_at"
                    onChange={this.handleChange.bind(this)} 
                  />
                </div>
              </div>

                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Received" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default FreightPayment;

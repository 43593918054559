import React from 'react';
import { hasType, mfaVerification, actionRequired } from '../../services/authentication_service';

class Redirect extends React.Component {

  componentWillMount() {
    this.redirectToHome();
  }

  redirectToHome() {
    if (mfaVerification()) {
      this.props.history.replace('/mfa-verification');
    } else  if (actionRequired()) {
      this.props.history.replace('/update');
    } else if (hasType(["customer"])) {
      this.props.history.replace('/transactions/trade');
    } else if (hasType(["escrow"]) || hasType(["admin"]) || hasType(["partner"])) {
      this.props.history.replace('/dashboard');
    }
  }

  render() {
    return ( <div/> );
  }
}

export default Redirect;

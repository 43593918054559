import { getToken, setToken, getAccountId } from './authentication_service';

export function getHeaders() {
  var headers = {
    'Authorization': 'Bearer ' + getToken()
  };

  var accountId = getAccountId();
  if(accountId) {
    headers['X-Account-ID'] = accountId;
  }

  return { headers: headers };
}

export function handleResponse(response) {
  try {
    checkStatus(response);
    checkNewAuthorization(response);
    return Promise.resolve(response.data);
  } catch(error) {
    return Promise.reject(error)
  }
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    var error = new Error(response)
    error.response = response
    throw error
  }
}

export function checkNewAuthorization(response) {
  if(response.headers && response.headers.authorization) {
    var token = response.headers.authorization;
    token = token.replace('Bearer ','');
    setToken(token)
  }
}

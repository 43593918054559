"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Mercurion Trade Authentication Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginApi = exports.LoginApiFactory = exports.LoginApiFp = exports.LoginApiAxiosParamCreator = exports.ApiKeysApi = exports.ApiKeysApiFactory = exports.ApiKeysApiFp = exports.ApiKeysApiAxiosParamCreator = exports.ApiRole = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.ApiRole = {
    ReadOnly: 'read_only',
    Default: 'default',
    Admin: 'admin'
};
/**
 * ApiKeysApi - axios parameter creator
 * @export
 */
exports.ApiKeysApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create api key
         * @param {CreateApiKey} createApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey: function (createApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createApiKey' is not null or undefined
                            common_1.assertParamExists('createApiKey', 'createApiKey', createApiKey);
                            localVarPath = "/api-keys";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createApiKey, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete api key
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteApiKey', 'id', id);
                            localVarPath = "/api-keys/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * get api key
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKey: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getApiKey', 'id', id);
                            localVarPath = "/api-keys/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * get api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api-keys";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ApiKeysApi - functional programming interface
 * @export
 */
exports.ApiKeysApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ApiKeysApiAxiosParamCreator(configuration);
    return {
        /**
         * Create api key
         * @param {CreateApiKey} createApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey: function (createApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createApiKey(createApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete api key
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteApiKey(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get api key
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKey: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getApiKey(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getApiKeys(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ApiKeysApi - factory interface
 * @export
 */
exports.ApiKeysApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ApiKeysApiFp(configuration);
    return {
        /**
         * Create api key
         * @param {CreateApiKey} createApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey: function (createApiKey, options) {
            return localVarFp.createApiKey(createApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete api key
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey: function (id, options) {
            return localVarFp.deleteApiKey(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get api key
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKey: function (id, options) {
            return localVarFp.getApiKey(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys: function (options) {
            return localVarFp.getApiKeys(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ApiKeysApi - object-oriented interface
 * @export
 * @class ApiKeysApi
 * @extends {BaseAPI}
 */
var ApiKeysApi = /** @class */ (function (_super) {
    __extends(ApiKeysApi, _super);
    function ApiKeysApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create api key
     * @param {CreateApiKey} createApiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    ApiKeysApi.prototype.createApiKey = function (createApiKey, options) {
        var _this = this;
        return exports.ApiKeysApiFp(this.configuration).createApiKey(createApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete api key
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    ApiKeysApi.prototype.deleteApiKey = function (id, options) {
        var _this = this;
        return exports.ApiKeysApiFp(this.configuration).deleteApiKey(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get api key
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    ApiKeysApi.prototype.getApiKey = function (id, options) {
        var _this = this;
        return exports.ApiKeysApiFp(this.configuration).getApiKey(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get api keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    ApiKeysApi.prototype.getApiKeys = function (options) {
        var _this = this;
        return exports.ApiKeysApiFp(this.configuration).getApiKeys(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ApiKeysApi;
}(base_1.BaseAPI));
exports.ApiKeysApi = ApiKeysApi;
/**
 * LoginApi - axios parameter creator
 * @export
 */
exports.LoginApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Forgot password
         * @param {ForgotPassword} forgotPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: function (forgotPassword, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'forgotPassword' is not null or undefined
                    common_1.assertParamExists('forgotPassword', 'forgotPassword', forgotPassword);
                    localVarPath = "/login/forgot-password";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(forgotPassword, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Login
         * @param {LoginCredentials} loginCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (loginCredentials, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'loginCredentials' is not null or undefined
                    common_1.assertParamExists('login', 'loginCredentials', loginCredentials);
                    localVarPath = "/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(loginCredentials, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Send MFA
         * @param {UserMfa} [userMfa]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMfa: function (userMfa, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/login/mfa/send";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userMfa, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Temp login
         * @param {TempToken} tempToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempLogin: function (tempToken, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tempToken' is not null or undefined
                    common_1.assertParamExists('tempLogin', 'tempToken', tempToken);
                    localVarPath = "/login/temp";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(tempToken, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Verify MFA
         * @param {MfaVerification} mfaVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMfa: function (mfaVerification, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'mfaVerification' is not null or undefined
                            common_1.assertParamExists('verifyMfa', 'mfaVerification', mfaVerification);
                            localVarPath = "/login/mfa/verify";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(mfaVerification, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * LoginApi - functional programming interface
 * @export
 */
exports.LoginApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.LoginApiAxiosParamCreator(configuration);
    return {
        /**
         * Forgot password
         * @param {ForgotPassword} forgotPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: function (forgotPassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.forgotPassword(forgotPassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Login
         * @param {LoginCredentials} loginCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (loginCredentials, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.login(loginCredentials, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Send MFA
         * @param {UserMfa} [userMfa]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMfa: function (userMfa, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendMfa(userMfa, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Temp login
         * @param {TempToken} tempToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempLogin: function (tempToken, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tempLogin(tempToken, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Verify MFA
         * @param {MfaVerification} mfaVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMfa: function (mfaVerification, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyMfa(mfaVerification, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * LoginApi - factory interface
 * @export
 */
exports.LoginApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.LoginApiFp(configuration);
    return {
        /**
         * Forgot password
         * @param {ForgotPassword} forgotPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: function (forgotPassword, options) {
            return localVarFp.forgotPassword(forgotPassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Login
         * @param {LoginCredentials} loginCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (loginCredentials, options) {
            return localVarFp.login(loginCredentials, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Send MFA
         * @param {UserMfa} [userMfa]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMfa: function (userMfa, options) {
            return localVarFp.sendMfa(userMfa, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Temp login
         * @param {TempToken} tempToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempLogin: function (tempToken, options) {
            return localVarFp.tempLogin(tempToken, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Verify MFA
         * @param {MfaVerification} mfaVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMfa: function (mfaVerification, options) {
            return localVarFp.verifyMfa(mfaVerification, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
var LoginApi = /** @class */ (function (_super) {
    __extends(LoginApi, _super);
    function LoginApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Forgot password
     * @param {ForgotPassword} forgotPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.forgotPassword = function (forgotPassword, options) {
        var _this = this;
        return exports.LoginApiFp(this.configuration).forgotPassword(forgotPassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Login
     * @param {LoginCredentials} loginCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.login = function (loginCredentials, options) {
        var _this = this;
        return exports.LoginApiFp(this.configuration).login(loginCredentials, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Send MFA
     * @param {UserMfa} [userMfa]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.sendMfa = function (userMfa, options) {
        var _this = this;
        return exports.LoginApiFp(this.configuration).sendMfa(userMfa, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Temp login
     * @param {TempToken} tempToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.tempLogin = function (tempToken, options) {
        var _this = this;
        return exports.LoginApiFp(this.configuration).tempLogin(tempToken, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Verify MFA
     * @param {MfaVerification} mfaVerification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.verifyMfa = function (mfaVerification, options) {
        var _this = this;
        return exports.LoginApiFp(this.configuration).verifyMfa(mfaVerification, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LoginApi;
}(base_1.BaseAPI));
exports.LoginApi = LoginApi;

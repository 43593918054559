import React from 'react';
import TextField from '@material-ui/core/TextField';

class PasswordForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      password: "",
      confirm_password: "",
      password_errors: [ "length", "case", "numeric", "symbol", "confirm" ]
    }
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value }, function() {
      var password_errors = this.getPasswordErrors();
      this.setState({ password_errors: password_errors });
      this.props.passwordUpdated(this.state.password, password_errors.length > 0);
    });
  }

  getPasswordErrors() {
    var errors = [];

    if(!this.state.password) {
      return [ "length", "case", "numeric", "symbol", "confirm" ];
    }

    if(this.state.password.length < 8) {
      errors.push("length");
    }

    if(!(/[a-z]/.test(this.state.password)) || !(/[A-Z]/.test(this.state.password))) {
      errors.push("case");
    }

    if(!(/\d/.test(this.state.password))) {
      errors.push("numeric");
    }

    if(!(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.state.password))) {
      errors.push("symbol");
    }

    if(this.state.password !== this.state.confirm_password) {
      errors.push("confirm");
    }

    return errors;
  }

  render() {
    return (        
      <div>

        <div className="row">
          <div className="col-md">
            <TextField
              label="Password"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              type="password"
              name="password"
              autoComplete="off"
              onChange={this.handleChange.bind(this)} 
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <TextField
              label="Confirm password"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              type="password"
              name="confirm_password"
              autoComplete="off"
              onChange={this.handleChange.bind(this)}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <span>Requirements:</span>
            <ul className="requirements-list">
              <li>{this.state.password_errors.includes("length") ? (<i className="fas fa-times"></i>) : (<i className="fas fa-check"></i>)}&nbsp;&nbsp;At least 8 characters</li>
              <li>{this.state.password_errors.includes("case") ? (<i className="fas fa-times"></i>) : (<i className="fas fa-check"></i>)}&nbsp;&nbsp;Uppercase and lowercase letter (A, z)</li>
              <li>{this.state.password_errors.includes("numeric") ? (<i className="fas fa-times"></i>) : (<i className="fas fa-check"></i>)}&nbsp;&nbsp;Numeric character (0-9)</li>
              <li>{this.state.password_errors.includes("symbol") ? (<i className="fas fa-times"></i>) : (<i className="fas fa-check"></i>)}&nbsp;&nbsp;Special character (!, %, @, #, etc.)</li>
              <li>{this.state.password_errors.includes("confirm") ? (<i className="fas fa-times"></i>) : (<i className="fas fa-check"></i>)}&nbsp;&nbsp;Confirm password is the same</li>
            </ul>
          </div>
        </div>
    
      </div>
    );
  }
}

export default PasswordForm;

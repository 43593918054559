import React from 'react';

class ProvideQuote extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Waiting for quote</label>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md">
              <span>The transaction has been submitted, waiting for the quote to be completed.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ProvideQuote;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Mapper from '../../util/mapper';
import TradeCalculator from './components/trade_calculator'
import FreightCalculator from './components/freight_calculator'

class Calculator extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      type: "trade_transaction"
    }
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  render() {
    return (
      <div>
                
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Calculator</BreadcrumbItem>
          </Breadcrumb>
          

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Input</label>
                </div>
              </div>
            </div>
          
            <div className="row">
              <div className="col-md-4">
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth>
                  <InputLabel>Type *</InputLabel>
                  <Select
                    native
                    required
                    label="Type *"
                    value={this.state.type}
                    name="type"
                    onChange={this.handleChange.bind(this)} >
                      {Object.keys(Mapper.calculationTypes()).map(type => {
                        return (<option key={"type_"+type} value={type}>{Mapper.fromCalculationType(type)}</option>);
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          
          {this.state.type === "trade_transaction" &&
            <TradeCalculator/>
          }
          
          {this.state.type === "freight_transaction" &&
            <FreightCalculator/>
          }
          
        </div>
      </div >
    );
  }
}

export default Calculator;

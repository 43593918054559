import React from 'react';

class Pagination extends React.Component {
  
  onPageChange(page, e) {
    this.props.onNavigate(page);
  }

  render() {
    var parameters = this.props.search;

    if (!parameters) {
      parameters = "";
    }

    if (parameters.length > 0) {
      parameters = parameters.substr(1);
      const pageRegex = new RegExp(/(.*)(&page=\d+)(.*)/g)
      parameters = parameters.replace(pageRegex, "$1$3");
    }

    var page = Number(this.props.page);
    var pages = Number(this.props.pages);

    let items = [];

    if (pages > 1 && page > 1) {
      items.push(<li key="first" className="page-item"><a className="page-link" onClick={this.onPageChange.bind(this, 1)} ><i className="bi bi-chevron-double-left"></i></a></li>);
      items.push(<li key="previous" className="page-item"><a className="page-link" onClick={this.onPageChange.bind(this, Number(page) - 1)} ><i className="bi bi-chevron-left"></i></a></li>);
    } else {
      items.push(<li key="first" className="page-item disabled"><a className="page-link"><i className="bi bi-chevron-double-left"></i></a></li>);
      items.push(<li key="previous" className="page-item disabled"><a className="page-link"><i className="bi bi-chevron-left"></i></a></li>);
    }

    items.push(<li key="pages" className="page-item pages">{page} of {pages === 0 ? 1 : pages}</li>);

    if (pages > 1 && page < pages) {
      items.push(<li key="next" className="page-item"><a className="page-link" onClick={this.onPageChange.bind(this, Number(page) + 1)} ><i className="bi bi-chevron-right"></i></a></li>);
      items.push(<li key="last" className="page-item"><a className="page-link" onClick={this.onPageChange.bind(this, Number(pages))} ><i className="bi bi-chevron-double-right"></i></a></li>);
    } else {
      items.push(<li key="next" className="page-item disabled"><a className="page-link" ><i className="bi bi-chevron-right"></i></a></li>);
      items.push(<li key="last" className="page-item disabled"><a className="page-link" ><i className="bi bi-chevron-double-right"></i></a></li>);
    }

    return (
      <nav className="pagination-nav">
        <ul className="pagination">
          {items}
        </ul>
      </nav>
    );
  }
}

export default Pagination;

import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import DomUtil from '../../util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ErrorHandler from '../../util/error_handler';
import confirm from '../../components/confirm'
import { isReadOnly, getApiKey, deleteApiKey } from '../../services/authentication_service';
import Mapper from '../../util/mapper';
import LoadingText from '../../components/loading_text';

class ShowApiKey extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let secret = params.get('secret');

    this.state = {
      api_key_id: this.props.match.params.api_key_id,
      api_key_secret: secret,
      api_key: undefined
    };
  }

  componentDidMount() {
    this.getApiKey();
  }

  getApiKey() {
    getApiKey(this.state.api_key_id).then((api_key) => {
      if (api_key) {
        this.setState({ api_key: api_key });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  deleteApiKey(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to delete this API key?`).then((result) => {
      DomUtil.disableClass(button);
      deleteApiKey(this.state.api_key_id).then(() => {
        this.props.history.push("/account");
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
          <BreadcrumbItem><Link to="/account">Account</Link></BreadcrumbItem>
            <BreadcrumbItem active>Show API key</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>API key</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                { !isReadOnly() &&
                  <div className="row">
                    <div className="col-md">
                        <AsyncButton
                          className="button-danger-outlined"
                          variant="outlined"
                          color="danger"
                          onClick={this.deleteApiKey.bind(this)}
                          icon="delete"
                          text="Delete" />
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">Name</td>
                      <td><LoadingText text={this.state.api_key ? this.state.api_key.name : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Client ID</td>
                      <td><LoadingText text={this.state.api_key ?  this.state.api_key.client_id : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Client Secret</td>
                      <td><LoadingText text={this.state.api_key ?  this.state.api_key_secret ? <span>{this.state.api_key_secret}<b><i> (one time view)</i></b></span> : "****************" : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Role</td>
                      <td><LoadingText text={this.state.api_key ?  Mapper.fromApiRole(this.state.api_key.role) : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Enabled</td>
                      <td><LoadingText text={this.state.api_key ?  Mapper.fromBoolean(this.state.api_key.enabled) : undefined} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default ShowApiKey;

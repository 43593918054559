import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import confirm from '../../../../shared/components/confirm.js'
import AsyncButton from '../../../../shared/components/async_button.js';
import DocumentUpload from '../../../../shared/components/document_upload.js'
import Mapper from '../../../../shared/util/mapper.js'
import DomUtil from '../../../../shared/util/dom_util.js';
import Converter from '../../../../shared/util/converter.js';

class ReleaseBrokerFunds extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
    this.transactionDocumentUploaded = this.transactionDocumentUploaded.bind(this);
    this.transactionDocumentCleared = this.transactionDocumentCleared.bind(this);

    this.state = {
      transfer_amount: undefined,
      flow_patch: {
        status: "release_broker_funds",
        action: "funds_released"
      }
    };
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you have released the funds to the Carrier?").then(() => {
      var release_patch = Mapper.toReleaseFunds(this.state.flow_patch);
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(release_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.flow_patch[e.target.name] = value;
    this.setState({ flow_patch: this.state.flow_patch })
  }
  
  transactionDocumentUploaded(id, file_name, parent_data) {
    this.state.flow_patch[parent_data.name] = { id: id, file_name: file_name };
    this.setState({ flow_patch: this.state.flow_patch });
  }

  transactionDocumentCleared(parent_data) {
    this.state.flow_patch[parent_data.name] = undefined;
    this.setState({ flow_patch: this.state.flow_patch });
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Release Broker funds</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                <div className="row">
                  <div className="col-md">
                    <span>Funds to be transferred to the Broker: <strong>{Converter.toCurrency(this.props.transaction.currency, this.props.transaction.broker_fee)}</strong></span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <span>Please upload proof of the bank transfer once the funds have been released to the Broker.</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <DocumentUpload
                      title="Proof of bank transfer"
                      access="allowed_accounts_and_escrow"
                      allowed_account_ids={[ this.props.transaction.charterer.id, this.props.transaction.carrier.id]}
                      allow_authorised_accounts={true}
                      documentUploaded={this.transactionDocumentUploaded}
                      documentCleared={this.transactionDocumentCleared}
                      document_id={this.state.flow_patch.proof_bank_transfer ? this.state.flow_patch.proof_bank_transfer.id : undefined}
                      file_name={this.state.flow_patch.proof_bank_transfer ? this.state.flow_patch.proof_bank_transfer.file_name : undefined}
                      parent_data={{ name: "proof_bank_transfer" }} />
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      label="Amount"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      value={this.state.flow_patch.amount}
                      name="amount"
                      onChange={this.handleChange.bind(this)}
                      InputProps={{
                        inputComponent: Converter.numberFormatter,
                        startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.props.transaction.currency)}</InputAdornment>,
                      }}
                    />
                    </div>                    
                </div>

                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Confirm" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ReleaseBrokerFunds;

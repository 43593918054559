import React from 'react';
import ParentActionRenderer from '../../../../shared/pages/trade-transactions/actions/parent_action_renderer';
import SellerSpecifyDetails from './seller_specify_details';
import ReviewTransaction from './review_transaction';
import ReviewCosts from './review_costs';
import Compliance from './compliance';
import AgreeTransactionAction from '../../../../shared/pages/trade-transactions/actions/agree_transaction_action';
import { isReadOnly } from '../../../../shared/services/authentication_service';
import MilestoneDocumentsUpload from './milestone_documents_upload';
import MilestonePayment from './milestone_payment';
import ReviewAmendment from '../../../../shared/pages/trade-transactions/actions/review_amendment_action'
import StartShipment from './start_shipment';
import LoadOnShip from './load_on_ship';
import TrackingNotFound from './tracking_not_found';
import ArriveShipment from './arrive_shipment';

class ActionRenderer extends ParentActionRenderer {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    return this.props.updateTransaction(data);
  }

  render() {
    if(!this.props.transaction.states || isReadOnly()) {
      return super.render();
    }

    if(this.props.transaction.states.some(s => s.status === "seller_specify_details" && s.action_allowed)) {
      return <SellerSpecifyDetails transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    
    if(this.props.transaction.states.some(s => s.status === "seller_review_fee" && s.action_allowed)) {
      return <ReviewCosts transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="seller_review_fee"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "buyer_review_transaction" && s.action_allowed)) {
      return <ReviewTransaction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="buyer_review_transaction"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "seller_compliance" && s.action_allowed)) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="seller_compliance"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "buyer_compliance" && s.action_allowed)) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="buyer_compliance"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "seller_agree_transaction" && s.action_allowed)) {
      return <AgreeTransactionAction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="seller_agree_transaction" type="seller"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "buyer_agree_transaction" && s.action_allowed)) {
      return <AgreeTransactionAction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="buyer_agree_transaction" type="buyer"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "milestone_documents_upload" && s.action_allowed)) {
      return <MilestoneDocumentsUpload transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "milestone_payment" && s.action_allowed)) {
      return <MilestonePayment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "buyer_review_amendment_request" && s.action_allowed)) {
      return <ReviewAmendment transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="buyer_review_amendment_request" type="buyer"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "seller_review_amendment_request" && s.action_allowed)) {
      return <ReviewAmendment transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="seller_review_amendment_request" type="seller"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "start_shipment" && s.action_allowed)) {
      return <StartShipment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "load_on_ship" && s.action_allowed)) {
      return <LoadOnShip transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "tracking_not_found" && s.action_allowed)) {
      return <TrackingNotFound transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "arrive_shipment" && s.action_allowed)) {
      return <ArriveShipment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    return super.render();
  }
}

export default ActionRenderer;

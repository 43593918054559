import { CountriesApi } from "generated-countries-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new CountriesApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getCountries() {
  return API.getCountries(getHeaders()).then(handleResponse);
}

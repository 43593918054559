import React from 'react';
import confirm from '../../../../shared/components/confirm.js'
import DocumentUpload from '../../../../shared/components/document_upload.js'
import Mapper from '../../../../shared/util/mapper'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class LoadOnShip extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      flow_patch: {
        status: "load_on_ship",
        action: "loaded_on_ship",
        bill_of_lading: {}
      }
    };
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure the cargo is loaded on the ship?").then(() => {
      var load_flow_patch = Mapper.toFreightLoadedOnShip(this.state.flow_patch);
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(load_flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  documentUploaded(id, file_name) {
    var flow_patch = this.state.flow_patch
    flow_patch.bill_of_lading.id = id;
    flow_patch.bill_of_lading.file_name = file_name;
    this.setState({ flow_patch: flow_patch });
  }

  documentCleared() {
    var flow_patch = this.state.flow_patch
    flow_patch.bill_of_lading.id = undefined;
    flow_patch.bill_of_lading.file_name = undefined;
    this.setState({ flow_patch: flow_patch });
  }

  render() {
    return (
      <div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Load on ship</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please upload a copy of the bill of lading once the goods have been loaded onto the ship.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                <div className="row">
                  <div className="col-md-6">
                    <DocumentUpload 
                      required
                      title="Copy of bill of lading"
                      access="allowed_accounts_and_escrow"
                      allowed_account_ids={[ this.props.transaction.charterer.id, this.props.transaction.carrier.id]}
                      allow_authorised_accounts={true}
                      documentUploaded={this.documentUploaded.bind(this)}
                      documentCleared={this.documentCleared.bind(this)}
                      document_id={this.state.flow_patch.bill_of_lading.id}
                      file_name={this.state.flow_patch.bill_of_lading.file_name} />
                  </div>
                </div>                
                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Submit" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default LoadOnShip;
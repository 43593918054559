import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import DomUtil from '../../util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCurrentAccount, updateCompanyDetails } from '../../services/accounts_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper'
import LoadingTextField from '../../components/loading_text_field'

class EditAccount extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account: undefined
    };
  }

  componentDidMount() {
    this.getCurrentAccount();
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }


  handleChange(e) {
    this.state.account[e.target.name] = e.target.value;
    this.setState({ account: this.state.account });
  }

  updateCompanyDetails(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var update = Mapper.toCompanyDetailsUpdate(this.state.account);
    updateCompanyDetails(update).then(() => {
      this.props.history.push(`/account/` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/account/` + this.props.location.search}>Account</Link></BreadcrumbItem>
            <BreadcrumbItem active>Edit account</BreadcrumbItem>
          </Breadcrumb>
              
          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Edit account</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.updateCompanyDetails.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Registered company name"
                        variant="outlined"
                        required
                        fullWidth
                        disabled
                        margin="dense"
                        value={this.state.account.registered_name}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Registered company name"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Registered address"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="registered_address"
                        value={this.state.account.registered_address}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Registered address"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Country of incorporation"
                        variant="outlined"
                        required
                        fullWidth
                        disabled
                        margin="dense"
                        value={this.state.account.country_of_incorporation}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Country of incorporation"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Company registration number"
                        variant="outlined"
                        required
                        fullWidth
                        disabled
                        margin="dense"
                        value={this.state.account.registration_number}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Company registration number"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Bank account number"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="bank_account_number"
                        value={this.state.account.bank_account_number}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Bank account number"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Bank account holder"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="beneficiary_name"
                        value={this.state.account.beneficiary_name}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Bank account holder"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Bank name"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="bank_name"
                        value={this.state.account.bank_name}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Bank name"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.account &&
                      <TextField
                        label="Bank SWIFT"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="bank_swift_code"
                        value={this.state.account.bank_swift_code}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.account &&
                        <LoadingTextField
                          label="Bank SWIFT"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Update" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default EditAccount;

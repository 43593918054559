import { createConfirmation } from 'react-confirm';
import AlertDialog from './alert_dialog';

const confirm = createConfirmation(AlertDialog);
var openDialog = false;

export default function (title, message, options = {}) {
  if(openDialog) {
    return; // There is already a alert dialog open
  }

  openDialog = true;  
  var dialog = confirm({ title, message, options });
  dialog.then(() => {
    openDialog = false;
  }).catch(() => {
    openDialog = false;
  })
  return dialog;
}

import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { createTradeTransaction } from '../../../shared/services/trade_transactions_service';
import { getAllContacts  } from '../../../shared/services/accounts_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import Alert from '@material-ui/lab/Alert';
import LoadingSelectField from "../../../shared/components/loading_select_field";

class CreateTradeTransaction extends React.Component {

  constructor(props) {
    super(props)
    this.createTradeTransaction = this.createTradeTransaction.bind(this);

    this.state = {
      counterparty_header: "Counterparty",
      contacts: undefined,
      transaction: {
        my_role: "",
        counterparty: { registered_name: "" },
        main_transport_mode: "",
        incoterms: ""
      }
    };
  }

  componentDidMount() {
    this.getAllContacts();
  }

  getAllContacts() {
    getAllContacts().then((contacts) => {
      if (contacts) {
        var validContacts = contacts.filter(c => c.registered_name);
        this.setState({ contacts: validContacts });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  createTradeTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var new_transaction = Mapper.toTransactionCreation(this.state.transaction);
    createTradeTransaction(new_transaction).then((transaction) => {
      this.props.history.push(`/transactions/trade/` + transaction.id + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  handleChange(e) {
    this.state.transaction[e.target.name] = e.target.value;

    if(e.target.name === "my_role") {
      this.state.transaction.counterparty = { name: "" };

      if(e.target.value === "seller") {
        this.state.counterparty_header = "Buyer";
      } else if(e.target.value === "buyer") {
        this.state.counterparty_header = "Seller";
      } else {
        this.state.counterparty_header = "Counterparty";
      }
    }

    this.setState({
      transaction: this.state.transaction,
      counterparty_header: this.state.counterparty_header
    });
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.transaction.counterparty = newValue;
    } else {
      this.state.transaction.counterparty = { registered_name: "" };
    }
    this.setState({ transaction: this.state.transaction });
  }
  
  firstLetter(option) {
    return option.registered_name[0].toUpperCase();
  }

  findName(contacts, symbol) {
    if(!symbol) {
      return;
    }
    for(const contact of contacts) {
      if(contact.symbol === symbol) {
        return contact.registered_name;
      }
    }
  }
  
  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/transactions/trade/` + this.props.location.search}>Trade transactions</Link></BreadcrumbItem>
            <BreadcrumbItem active>New trade transaction</BreadcrumbItem>
          </Breadcrumb>
          
          { this.state.contacts && this.state.contacts.length === 0 &&
            <div className="row action-required-row">
              <div className="col-md-12">
                <Alert  severity="warning">You contacts list is empty, a counterparty contact is required to start a transaction. A new contact can be added <Link to={`/contacts/`}>here</Link>.</Alert>
              </div>
            </div>
          }

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>New trade transaction</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.createTradeTransaction.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>My role *</InputLabel>
                        <Select
                          native
                          required
                          label="My role *"
                          value={this.state.transaction.my_role}
                          name="my_role"
                          onChange={this.handleChange.bind(this)} >
                            <option key={"transaction_role_empty"} value=""></option>
                            {Object.keys(Mapper.transactionRoles()).map(transaction_role => {
                              return (<option key={"transaction_role_"+transaction_role} value={transaction_role}>{Mapper.fromTransactionRole(transaction_role)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      { this.state.contacts &&
                        <Autocomplete
                          key="counterparty_symbol"
                          options={this.state.contacts}
                          groupBy={this.firstLetter.bind(this)}
                          getOptionLabel={(option) => option.registered_name}
                          value={this.state.transaction.counterparty}
                          onChange={this.handleAutoCompleteChange.bind(this)}
                          renderInput={(params) => 
                            <TextField {...params}
                              label={this.state.counterparty_header}
                              variant="outlined"
                              required
                              fullWidth
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />                      
                          }
                        />
                      }
                      { !this.state.contacts &&
                        <LoadingSelectField
                          label={this.state.counterparty_header}
                          shrink={false}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Main transport mode *</InputLabel>
                        <Select
                          native
                          required
                          label="Main transport mode *"
                          value={this.state.transaction.type}
                          name="main_transport_mode"
                          onChange={this.handleChange.bind(this)} >
                            <option key={"mode_empty"} value=""></option>
                            {Object.keys(Mapper.mainTransportModes()).map(mode => {
                              return (<option key={"mode_"+mode} value={mode}>{Mapper.fromMainTransportMode(mode)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Incoterms *</InputLabel>
                        <Select
                          native
                          required
                          label="Incoterms *"
                          value={this.state.transaction.incoterms}
                          name="incoterms"
                          onChange={this.handleChange.bind(this)} >
                            <option key={"incoterms_empty"} value=""></option>
                            {Object.keys(Mapper.incoterms()).map(incoterms => {
                              return (<option key={"incoterms_"+incoterms} value={incoterms}>{Mapper.fromIncoterms(incoterms)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Create" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default CreateTradeTransaction;

import React from 'react';
import Mapper from '../../../util/mapper'

class ArriveShipment extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Awaiting shipment arrival</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for shipment to arrive at the {Mapper.toDestination(this.props.transaction.incoterms)}.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ArriveShipment;

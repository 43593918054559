import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import confirm from '../../../../shared/components/confirm'
import Mapper from '../../../../shared/util/mapper';
import DocumentUpload from '../../../../shared/components/document_upload';

class AuthorisedRepresentativesForm extends React.Component {

  constructor(props) {
    super(props)
    
    var authorised_representatives = this.props.compliance.authorised_representatives;
    if(authorised_representatives) {
      for(const authorised_representative of authorised_representatives) {
        authorised_representative.key = this.getUniqueKey();
        if(authorised_representative.country_residency_code) {
          authorised_representative.country_residency = this.findCountry(authorised_representative.country_residency_code);
        }
        if(authorised_representative.user_account_id) {
          authorised_representative.user_account = this.findUser(authorised_representative.user_account_id);
        }
        if(!authorised_representative.passport) {
          authorised_representative.passport = {};
        }
        if(!authorised_representative.residential_address_document) {
          authorised_representative.residential_address_document = {};
        }
      }
    } else {
      authorised_representatives = [];
    }

    this.state = {
      authorised_representatives: authorised_representatives
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key], true);
    });
  }
  

  addToList() {
    this.state.authorised_representatives.push({ key: this.getUniqueKey(), passport: {}, residential_address_document: {} });
    this.setState({ authorised_representatives: this.state.authorised_representatives });
    this.props.propertyUpdate("authorised_representatives", this.state.authorised_representatives);
  }

  removeFromList(index) {
    confirm("Please confirm", "Are you sure you want to remove this entry?").then(() => {
      this.state.authorised_representatives.splice(index, 1);
      this.setState({ authorised_representatives: this.state.authorised_representatives });
      this.props.propertyUpdate("authorised_representatives", this.state.authorised_representatives);
    });
  }
  
  handleListChange(index, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.authorised_representatives[index][e.target.name] = value;
    this.setState({ authorised_representatives: this.state.authorised_representatives });
    this.props.propertyUpdate("authorised_representatives", this.state.authorised_representatives);
  }

  documentUploaded(id, file_name, parent_data) {
    this.state.authorised_representatives[parent_data.index][parent_data.document].id = id;
    this.state.authorised_representatives[parent_data.index][parent_data.document].file_name = file_name;
    this.setState({ authorised_representatives: this.state.authorised_representatives });
    this.props.propertyUpdate("authorised_representatives", this.state.authorised_representatives);
  }

  documentCleared(parent_data) {
    this.state.authorised_representatives[parent_data.index][parent_data.document].id = undefined;
    this.state.authorised_representatives[parent_data.index][parent_data.document].file_name = undefined;
    this.setState({ authorised_representatives: this.state.authorised_representatives });
    this.props.propertyUpdate("authorised_representatives", this.state.authorised_representatives);
  }

  handleAutoCompleteChange(index, property, e, newValue) {
    if(newValue) {
      this.state.authorised_representatives[index][property] = newValue;
    } else {
      this.state.authorised_representatives[index][property] = { };
    }
    this.setState({ authorised_representatives: this.state.authorised_representatives });
    this.props.propertyUpdate("authorised_representatives", this.state.authorised_representatives);
  }

  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  findCountry(code) {
    if(!code) {
      return;
    }
    return this.props.countries.find(c => c.code === code);
  }

  findUser(userId) {
    if(!userId) {
      return;
    }
    return this.props.users.find(u => u.id === userId);
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <span>An authorised representative is a user account on the platform that is authorised by the company to approve the escrow agreement. As a result, the director does not have to approve the escrow agreement for each transaction, but the authorised users are also authorized to do so.</span>
          </div>
        </div>

        {this.state.authorised_representatives.map((authorised_representative, index) => {
          return (
            <div key={authorised_representative.key} className="list-row-item">
              <span className="list-row-item-header-text">Authorised Representative</span>

              <div>
                <div className="col-md-12">
                  <Button
                    className="button-icon-only button-inline clear-list-row-item-button"
                    color="secondary"
                    type="button"
                    onClick={this.removeFromList.bind(this, index)}
                    startIcon={<Icon>clear</Icon>} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Autocomplete
                    key="user_account"
                    options={this.props.users}
                    getOptionLabel={(option) => option.full_name}
                    value={this.state.authorised_representatives[index].user_account}
                    onChange={this.handleAutoCompleteChange.bind(this, index, "user_account")}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Full name"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />                      
                    }
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    label="Passport No."
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.authorised_representatives[index].passport_number}
                    name="passport_number"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: this.state.authorised_representatives[index].passport_number !== undefined,
                    }}
                  />
                </div>
                <div className="col-md-4">
                    <DocumentUpload 
                      title="Passport"
                      access="escrow_only"
                      allowed_account_ids={[]}
                      allow_authorised_accounts={false}
                      documentUploaded={this.documentUploaded.bind(this)}
                      documentCleared={this.documentCleared.bind(this)}
                      document_id={this.state.authorised_representatives[index].passport.id}
                      file_name={this.state.authorised_representatives[index].passport.file_name}
                      parent_data={{ index: index, document: "passport" }}  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <TextField
                    label="Nationality"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.authorised_representatives[index].nationality}
                    name="nationality"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: this.state.authorised_representatives[index].nationality !== undefined,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <Autocomplete
                    key="country_residency"
                    options={this.props.countries}
                    groupBy={this.firstLetter.bind(this)}
                    getOptionLabel={(option) => option.name}
                    value={this.state.authorised_representatives[index].country_residency}
                    onChange={this.handleAutoCompleteChange.bind(this, index, "country_residency")}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Country of Residency"
                        variant="outlined"
                        fullWidth
                        required
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    }
                  />
                </div>
                <div className="col-md-4">
                    <DocumentUpload 
                      title="Proof residential address"
                      access="escrow_only"
                      allowed_account_ids={[]}
                      allow_authorised_accounts={false}
                      documentUploaded={this.documentUploaded.bind(this)}
                      documentCleared={this.documentCleared.bind(this)}
                      document_id={this.state.authorised_representatives[index].residential_address_document.id}
                      file_name={this.state.authorised_representatives[index].residential_address_document.file_name}
                      parent_data={{ index: index, document: "residential_address_document" }}  />
                </div>
              </div>              
              
              <div className="row">
                <div className="col-md-4">
                  <TextField
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.authorised_representatives[index].date_of_birth}
                    name="date_of_birth"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel shrink={!this.isEmpty(this.state.authorised_representatives[index].gender) } >Gender *</InputLabel>
                    <Select
                      native
                      required
                      label="Gender *"
                      value={this.state.authorised_representatives[index].gender}
                      name="gender"
                      onChange={this.handleListChange.bind(this, index)} >
                        <option key={"authorised_representatives_"+index+"empty"} value=""></option>
                        {Object.keys(Mapper.genders()).map(gender => {
                          return (<option key={"authorised_representatives_"+index+gender} value={gender}>{Mapper.fromGender(gender)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <TextField
                    label="Position"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.authorised_representatives[index].position}
                    name="position"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: this.state.authorised_representatives[index].position !== undefined,
                    }}
                  />
                </div>
              </div>

            </div>
          );
        })}

        <div className="row">
          <div className="col-md-12">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addToList.bind(this)}
              startIcon={<Icon>add</Icon>}>
              Add Authorised Representative
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthorisedRepresentativesForm;

import React from 'react';
import MultilineTextField from '../../../../shared/components/multiline_textfield';

class CargoForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      cargo_description: this.props.transaction.cargo_description
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key]);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md">
            <MultilineTextField
              label="Cargo description"
              variant="outlined"
              required
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.state.cargo_description}
              name="cargo_description"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.cargo_description !== undefined,
              }}
            />
          </div>
        </div>
        
      </div>
    );
  }
}

export default CargoForm;

import React from 'react';
import { Link } from 'react-router-dom';
import alertDialog from '../../../../shared/components/alert';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { downloadComplianceDocumentAttachments } from '../../../../shared/services/compliances_service';
import { getDocument } from '../../../../shared/services/documents_service';
import { getUserId } from '../../../../shared/services/authentication_service';
import fileDownload from 'js-file-download';
import confirm from '../../../../shared/components/confirm'
import ErrorHandler from '../../../../shared/util/error_handler';
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class ComplianceConfirmation extends React.Component {

  constructor(props) {
    super(props)

    this.confirmComplianceDocument = this.confirmComplianceDocument.bind(this);

    this.state = {
      authorised: this.isAuthorised(),
      compliance_document: this.props.compliance_document,
      compliance_document_url: undefined,
    };
  }

  isAuthorised() {
    var userId = getUserId();
    return this.props.compliance_document.authorised_users.find(c => c.user_account_id === userId);
  }

  componentDidMount() {
    window.scroll({top: 0, left: 0, behavior: 'smooth' }) 
    this.getDocument();
  }

  getDocument() {
    getDocument(this.state.compliance_document.document.id).then((document) => {
      document = document.slice(0, document.size, "application/pdf")
      const fileURL = URL.createObjectURL(document);
      this.setState({ compliance_document_url: fileURL });      
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  downloadAttachments(e) {
    var file_name = this.state.compliance_document.document.file_name.replace(".pdf", "") + " - attachments.zip"
    var form = e.currentTarget;
    DomUtil.disableClass(form);

    downloadComplianceDocumentAttachments(this.state.compliance_document.id).then(blob => {
      fileDownload(blob, file_name);
      DomUtil.enableClass(form);
    }).catch(error => {
      DomUtil.enableClass(form);
      alertDialog("Alert", "Failed to download attachments.")
    });
  }

  confirmComplianceDocument(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to confirm this KYC document?").then(() => {
      DomUtil.disableClass(button);
      this.props.complianceDocumentConfirmed().then(() => {
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }
  
  render() {    
    return (
      <div>
        <div className="row">
          <div className="col-md">
            <span>Please confirm your latest approved KYC document below, if anything needs to be changed please update <Link to={`/compliance/`}>here</Link> before proceeding.</span>
          </div>
        </div>

        { this.state.authorised &&
          <div className="row">
            <div className="col-md">            
              <AsyncButton
                variant="outlined"
                color="primary"
                onClick={this.confirmComplianceDocument.bind(this)}
                icon="check"
                text="Confirm" />
            </div>
          </div>
        }

        { !this.state.authorised &&
          <div>
            <div className="row">
              <div className="col-md-12">
                <Alert severity="error">You don't have the permission to confirm this document. It can only be confirmed by an authorised user, director or key executive.</Alert>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md">
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled
                    startIcon={<Icon>check</Icon>}>
                    Confirm
                  </Button>
              </div>
            </div>
          </div>
        }
        
        <br/>

        <div className="row">
          <div className="col-md">  
            <span className="header-text">KYC Form</span>
            <div>
              <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
              <Button
                className="download-document-button"
                color="primary"
                onClick={this.downloadDocument.bind(this, this.state.compliance_document.document.id, this.state.compliance_document.document.file_name)} >
                {this.state.compliance_document.document.file_name}
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md">  
            <span className="header-text">Attachments</span>
            <div>
              <AsyncButton
                variant="outlined"
                color="primary"
                disabled={this.props.read_only}
                onClick={this.downloadAttachments.bind(this)}
                icon="get_app"
                text="Download" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <span className="header-text">Document viewer</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <iframe className="pdf-viewer" src={this.state.compliance_document_url} type="application/pdf" />
          </div>
        </div>
      </div>
    );
  }
}

export default ComplianceConfirmation;

import React from 'react';
import GeneralTab from './general_tab';
import DetailsTab from './details_tab';
import PaymentsTab from './payments_tab';
import EscrowTab from './escrow_tab';
import TransportTab from './transport_tab';
import TrackingTab from './tracking_tab';

class ParentTabRenderer extends React.Component {

  updateTab(tab) {
    if(!this.isDisabled(tab)) {
      this.setState({ current_tab: tab });
    }
  }

  renderTab() {
    if(this.isDisabled(this.state.current_tab)) {
      this.setState({ current_tab: "general" });
    }

    switch(this.state.current_tab) {
      case "general":
        return (<GeneralTab transaction={this.props.transaction} read_only={this.state.read_only} />);
      case "details":
        return (<DetailsTab transaction={this.props.transaction} read_only={this.state.read_only} />);
      case "payments":
        return (<PaymentsTab transaction={this.props.transaction} read_only={this.state.read_only} />);
      case "escrow":
        return (<EscrowTab transaction={this.props.transaction} read_only={this.state.read_only} />);
      case "transport":
        return (<TransportTab transaction={this.props.transaction} read_only={this.state.read_only} />);
      case "tracking":
        return (<TrackingTab transaction={this.props.transaction} read_only={this.state.read_only} />);
      default:
        return;
    }
  }

  getTabName(tab) {
    switch(tab) {
      case "general":
        return "General"
      case "details":
        return "Details"
      case "payments":
        return "Payments"
      case "escrow":
        return "Escrow"
      case "transport":
        return "Transport"
      case "tracking":
        return "Tracking"
      default:
        return;
    }
  }
  
  getTabClassName(name) {
    var className = "nav-link";

    if(name === this.state.current_tab) {
      className += " active";
    }

    if(this.isDisabled(name)) {
      className += " disabled";
    }
    
    return className;
  }

  isDisabled(name) {
    if(!this.props.transaction.states) {
      return name !== "general";
    }

    var states = [];

    switch(name) {
      case "escrow":
        states.push(
          "calculate_escrow_fee"
        );
        // no break
      case "tracking":
      case "transport":
      case "payments":
      case "details":
        states.push(
          "seller_specify_details",
          "deleted"
        );
        // no break
    }

    return this.includesAnyState(states);
  }

  includesAnyState(states) {
    for(const state of states) {
     if(this.props.transaction.states.some(s => s.status === state)) {
       return true;
     }
    }

    return false;
  }

  includesAllStates(states) {
    var containsAll = true;
    for(const state of states) {
      containsAll &= this.props.transaction.states.some(s => s.status === state);
    }
    
    return containsAll;
  }
  
  render() {
    return (
      <div className="container-body">
        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <ul className="nav nav-tabs">
                {this.state.tabs.map(tab => {
                  return (
                    <li key={tab} className="nav-item">
                      <a className={this.getTabClassName(tab)} onClick={this.updateTab.bind(this, tab)}>{this.getTabName(tab)}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="tabs">
          {this.renderTab()}
        </div>
      </div>
    );
  }
}

export default ParentTabRenderer;

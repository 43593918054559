import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import StringUtil from '../../util/string_util';
import { getTradeTransaction, updateTradeTransaction } from '../../../shared/services/trade_transactions_service';
import EventBus from '../../components/event_bus';
import ErrorHandler from '../../util/error_handler';
import FlowView from '../../components/trade_flow_view';
import Alert from '@material-ui/lab/Alert';

class ParentTradeTransaction extends React.Component {

  constructor(props) {
    super(props)
    this.updateTradeTransaction = this.updateTradeTransaction.bind(this);

    this.state = {
      transaction_id: this.props.match.params.transaction_id,
      transaction: {}
    };
  }

  componentDidMount() {
    this.getTradeTransaction();
  }

  getTradeTransaction() {
    return new Promise((resolve, reject) => {
      getTradeTransaction(this.state.transaction_id).then((transaction) => {
        if (transaction) {
          this.setState({ transaction: transaction }, () => {
            resolve();
          });
        }
      }).catch(error => {
        ErrorHandler.showError(error);
        reject();
      });
    });
  }
  
  updateTradeTransaction(data) {
    return new Promise((resolve, reject) => {
      updateTradeTransaction(this.state.transaction_id, data).then(() => {
        EventBus.dispatch("updateTradeTransaction");
        
        if(data.action === "deleted") {
          this.props.history.push(`/transactions/trade/` + this.props.location.search);
        } else {
          this.getTradeTransaction().then(() => {
            resolve();
          }).catch(error => {
            ErrorHandler.showError(error);
            reject();
          });
        }
      }).catch(error => {
        ErrorHandler.showError(error);
        reject();
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/transactions/trade/` + this.props.location.search}>Trade transactions</Link></BreadcrumbItem>
            <BreadcrumbItem active>{StringUtil.shortId(this.props.match.params.transaction_id)}</BreadcrumbItem>
          </Breadcrumb>

          <FlowView transaction={this.state.transaction} />

          { this.state.transaction.action_allowed &&
            <div className="row action-required-row">
              <div className="col-md-12">
                <Alert  severity="info">There are pending tasks, please follow the instructions below to complete.</Alert>
              </div>
            </div>
          }
          
          {this.getTabRenderer(this.state.transaction, this.updateTab)}

          {this.getActionRenderer(this.state.transaction, this.updateTradeTransaction)}
                    
        </div>
      </div>
    );
  }
}

export default ParentTradeTransaction;

import { AccountsApi, ContactsApi, InvitesApi, UsersApi } from "generated-accounts-api";
import { getHeaders, handleResponse } from './web_service';

export const ACCOUNTS_API = new AccountsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export const CONTACTS_API = new ContactsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export const INVITES_API = new InvitesApi({
  basePath: window.appConfig.API_HOSTNAME
});

export const USERS_API = new UsersApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function createAccount(data) {
  return ACCOUNTS_API.createAccount(data).then(handleResponse);
}

export function getAccounts(page, size) {
  return ACCOUNTS_API.getAccounts(page, size, getHeaders()).then(handleResponse);
}

export function getAllAccounts() {
  return ACCOUNTS_API.getAllAccounts(getHeaders()).then(handleResponse);
}

export function getAccount(accountId) {
  return ACCOUNTS_API.getAccount(accountId, getHeaders()).then(handleResponse);
}

export function getPartnerAccount(accountId) {
  return ACCOUNTS_API.getPartnerAccount(accountId, getHeaders()).then(handleResponse);
}

export function getCurrentAccount() {
  return ACCOUNTS_API.getCurrentAccount(getHeaders()).then(handleResponse);
}

export function setCompanyDetails(details) {
  return ACCOUNTS_API.setCompanyDetails(details, getHeaders()).then(handleResponse);
}

export function updateCompanyDetails(details) {
  return ACCOUNTS_API.updateCompanyDetails(details, getHeaders()).then(handleResponse);
}

export function inviteUser(accountId, invite) {
  return ACCOUNTS_API.inviteUser(accountId, invite, getHeaders()).then(handleResponse);
}

export function getUser(accountId, userId) {
  return ACCOUNTS_API.getUser(accountId, userId, getHeaders()).then(handleResponse);
}

export function updateUser(accountId, userId, user) {
  return ACCOUNTS_API.updateUser(accountId, userId, user, getHeaders()).then(handleResponse);
}

export function deleteUser(accountId, userId) {
  return ACCOUNTS_API.deleteUser(accountId, userId, getHeaders()).then(handleResponse);
}

export function createContact(data) {
  return CONTACTS_API.createContact(data, getHeaders()).then(handleResponse);
}

export function getContacts(page, size) {
  return CONTACTS_API.getContacts(page, size, getHeaders()).then(handleResponse);
}

export function getAllContacts() {
  return CONTACTS_API.getAllContacts(getHeaders()).then(handleResponse);
}

export function getContact(id) {
  return CONTACTS_API.getContact(id, getHeaders()).then(handleResponse);
}

export function deleteContact(id) {
  return CONTACTS_API.deleteContact(id, getHeaders()).then(handleResponse);
}

export function createInvite(data) {
  return INVITES_API.createInvite(data, getHeaders()).then(handleResponse);
}

export function getInvites(all=false, page, size) {
  return INVITES_API.getInvites(all, page, size, getHeaders()).then(handleResponse);
}

export function getInvite(id) {
  return INVITES_API.getInvite(id, getHeaders()).then(handleResponse);
}

export function resendInvite(id) {
  return INVITES_API.resendInvite(id, getHeaders()).then(handleResponse);
}

export function deleteInvite(id) {
  return INVITES_API.deleteInvite(id, getHeaders()).then(handleResponse);
}

export function updateCurrentUser(data) {
  return USERS_API.updateCurrentUser(data, getHeaders()).then(handleResponse);
}

export function getCurrentUser() {
  return USERS_API.getCurrentUser(getHeaders()).then(handleResponse);
}

import React from 'react';

class FlowView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      flow: this.createFlow(this.props.transaction)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ flow: this.createFlow(nextProps.transaction) });
  }

  getFlow() {
    return [ 
      { key: "details", name: "Details", classes: []},
      { key: "compliance", name: "Compliance", classes: []},
      { key: "agreement", name: "Agreement", classes: []},
      { key: "payment", name: "Payment", classes: []},
      { key: "departure", name: "Departure", classes: []},
      { key: "in_transit", name: "In Transit", classes: []},
      { key: "release", name: "Release", classes: []},
      { key: "completed", name: "Completed", classes: []}
    ];
  }

  createFlow(transaction) {
    var flow = this.getFlow();
    var containsState = flow.some(entry=> transaction && entry.key === transaction.status);
    var stateFound = false;
    for (const [index, entry] of flow.entries()) {
      if(index != 0) {
        entry.classes.push("left");
      } else {
        entry.classes.push("first");
      }
      if(index != flow.length - 1) {
        entry.classes.push("right");
      } else {
        entry.classes.push("last");
      }
      if(!containsState) {
        entry.classes.push("skipped");
      } else if(transaction.status === entry.key) {
        entry.classes.push("active");
        stateFound = true;
      } else if(!stateFound) {
        entry.classes.push("passed");
      }
    }
    
    return flow;
  }
  
  render() {
    return (
      <div className="flowBoxes flowBoxes-8">
        {this.state.flow.map(entry => {
          return (<div key={entry.key} className={entry.classes.join(' ')}>            
            <span><i style={{display:"none"}} className="fas fa-check passed"/>&nbsp;{entry.name}</span>
          </div>);
        })}
      </div>
    );
  }
}

export default FlowView;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { getDocument, uploadDocument } from '../services/documents_service';
import alertDialog from './alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import fileDownload from 'js-file-download';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

class MultiDocumentUpload extends React.Component {
  
  constructor(props) {
    super(props)
    this.progressEvent = this.progressEvent.bind(this);

    var documents = [];
    if(this.props.documents) {
      documents = this.props.documents;
    }

    this.state = {
      progress: undefined,
      documents: documents,
      read_only: this.props.read_only,
      required: this.props.required !== undefined ? this.props.required : true,
    };
  }

  clearDocument(index, e) {
    var document = this.state.documents[index];
    this.state.documents.splice(index, 1);
    this.setState({ progress: undefined, documents: this.state.documents });
    this.props.documentsUpdated(this.state.documents, this.props.parent_data);
  }

  handleFileChange(files) {
    for(const file of files) {
      this.uploadFile(file);
    }
  }

  uploadFile(file) {
    if(!file) {
      return;
    }

    uploadDocument(file, this.props.access, this.props.allowed_account_ids, this.props.allow_authorised_accounts, this.progressEvent).then((document) => {
      this.state.documents.push(document);
      this.setState({ progress: undefined, documents: this.state.documents });
      this.props.documentsUpdated(this.state.documents, this.props.parent_data);
    }).catch(error => {
      console.error(error)

      var message = "Failed to upload document, max allowed document size 10 MB.";
      if (error.response) {
        var data = error.response.data;
        console.error(data);
        if(data.message) {
          message = data.message;
        }
      }
      
      this.setState({ progress: undefined });
      alertDialog("Alert", message)
    });
  }

  progressEvent(progressEvent) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    this.setState({ progress: percentCompleted });
  }

  downloadDocument(index, e) {
    e.preventDefault();
    
    var document = this.state.documents[index];
    
    getDocument(document.id).then(blob => {
      fileDownload(blob, document.file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  renderInput() {
    if(this.state.progress) {
      return(
        <LinearProgressWithLabel
          className="file-progress-bar field-side-margins"
          value={this.state.progress} />
      );
    }

    return (
      <TextField
        className="field-no-border field-no-margins"
        variant="outlined"
        required={this.state.required && this.state.documents.length === 0}
        fullWidth
        margin="dense"
        type="file"
        inputProps={{
          multiple: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={ (e) => this.handleFileChange(e.target.files) }
      />
    );
  }

  renderDocuments() {
    var rows = [];
    var that = this;

    this.state.documents.forEach(function (document, i) {
      rows.push(
        <div key={document.id} className='file-input-download-parent field-side-margins'>
          <TextField
            className="field-no-border"
            variant="outlined"
            fullWidth
            margin="dense"
            disabled
            value=""
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className="icon-span file-input-download-icon"><i className="far fa-file"/></span>
          <Link
            className="file-input-download-link"
            component="button"
            variant="body2"
            type="button"
            onClick={that.downloadDocument.bind(that, i)}>
          {document.file_name}</Link>
          {!that.state.read_only &&
            <Button
              className="button-icon-only button-inline clear-form-button"
              color="secondary"
              type="button"
              onClick={that.clearDocument.bind(that, i)}
              startIcon={<Icon>clear</Icon>} />
          }
        </div>
      );
    });

    return rows;
  }

  render() {
    return (
      <div className='multi-document-upload-field'>
        <div className='multi-document-upload-title'>
          <span>{this.props.title}{this.state.required && this.state.documents.length === 0 ? " *" : ""}</span>
        </div>
        {this.renderInput()}
        {this.renderDocuments()}
      </div>
    )
  }
}

export default MultiDocumentUpload;

import React from 'react';
import Loader from 'react-spinners/BarLoader';

class ContentLoading extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      min_time_passed: false,
    };

    this.setLoadingTimeout();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.content_loaded !== this.props.content_loaded) {
      this.setState({ min_time_passed: false });
      this.setLoadingTimeout();
    }
  }

  setLoadingTimeout() {
    setTimeout(function(){ 
      this.setState({ min_time_passed: true });
    }.bind(this), 300)
  }

  render() {
    if(!this.props.content_loaded || !this.state.min_time_passed) {
    return (
        <div className={ "loading_overlay " + (this.props.fullscreen ? "loading_overlay_fullscreen" : "") }>
          <div className="loading_overlay_content">
            <div className="loading_overlay_content_loader">
              <Loader
                sizeUnit={"px"}
                size={70}
                color={'#1d1d1b'}
              />
            </div>
            <span className="loading_overlay_content_text" >Loading..</span>
          </div>
        </div>
        );
    }

    return ( <div></div>);
  }
}

export default ContentLoading;

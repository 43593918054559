import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import DomUtil from '../../util/dom_util';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { createApiKey } from '../../services/authentication_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper';
import { hasType } from '../../services/authentication_service';

class CreateApiKey extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      api_key: {}
    };
  }

  handleChange(e) {
    this.state.api_key[e.target.name] = e.target.value;
    this.setState({ api_key: this.state.api_key });
  }
  
  createApiKey(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var api_key_creation = Mapper.toApiKeyCreation(this.state.api_key);
    createApiKey(api_key_creation).then((new_api_key) => {
      this.props.history.push(`/account/api-keys/${new_api_key.client_id}?secret=${new_api_key.client_secret}`);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={"/account" + this.props.location.search}>Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>Create API key</BreadcrumbItem>
        </Breadcrumb>
        
        <div className="container-body">
          
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Create API key</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.createApiKey.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        label="Name"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="name"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm">
                      <span>Roles</span>
                      <br/>
                      <span>- <strong>Read only:</strong> only has read access to resource on the platform.</span>
                      <br/>
                      <span>- <strong>Default:</strong> can create and update resource on the platform.</span>
                      <br/>
                      { hasType("escrow") &&
                        <div>
                          <span>- <strong>Authorised:</strong> can approved the escrow agreement on the platform.</span>
                          <br/>
                        </div>
                      }
                      <span>- <strong>Admin:</strong> can invite and edit users within the account.</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Role *</InputLabel>
                        <Select
                          native
                          required
                          label="Role *"
                          name="role"
                          onChange={this.handleChange.bind(this)} >
                            <option key={"role_empty"} value=""></option>
                            {Object.keys(Mapper.apiRoles()).map(role => {
                              return (<option key={"role_"+role} value={role}>{Mapper.fromApiRole(role)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Create" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default CreateApiKey;

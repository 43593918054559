import React from 'react';
import SellerSpecifyDetails from './seller_specify_details';
import ProvideQuote from './provide_quote';
import ReviewFee from './review_fee';
import ReviewTransaction from './review_transaction';
import Compliance from './compliance';
import AgreeTransaction from './agree_transaction';
import MilestoneDocumentsUpload from './milestone_documents_upload';
import MilestonePayment from './milestone_payment';
import ReviewAmendment from './review_amendment'
import LoadOnShip from './load_on_ship';
import RetrieveTracking from './retrieve_tracking';
import TrackingNotFound from './tracking_not_found';
import InTransit from './in_transit';
import StartShipment from './start_shipment';
import ArriveShipment from './arrive_shipment';
import ReleaseFunds from './release_funds';
import Completed from './completed';
import Rejected from './rejected';
import Deleted from './deleted';
import Invalid from './invalid';
import Loading from './loading';

class ParentActionRenderer extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    this.props.updateTransaction(data);
  }

  render() {
    if(!this.props.transaction.states) {
      return <Loading/>;
    }

    if(this.props.transaction.states.some(s => s.status === "seller_specify_details")) {
      return <SellerSpecifyDetails transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "calculate_escrow_fee")) {
      return <ProvideQuote transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "seller_review_fee")) {
      return <ReviewFee transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "buyer_review_transaction")) {
      return <ReviewTransaction transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "seller_compliance") || this.props.transaction.states.some(s => s.status === "buyer_compliance")) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "seller_agree_transaction") || this.props.transaction.states.some(s => s.status === "buyer_agree_transaction") || this.props.transaction.states.some(s => s.status === "escrow_agree_transaction")) {
      return <AgreeTransaction transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "milestone_documents_upload")) {
      return <MilestoneDocumentsUpload transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "milestone_payment")) {
      return <MilestonePayment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "buyer_review_amendment_request")) {
      return <ReviewAmendment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "seller_review_amendment_request")) {
      return <ReviewAmendment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "escrow_review_amendment_request")) {
      return <ReviewAmendment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "start_shipment")) {
      return <StartShipment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "load_on_ship")) {
      return <LoadOnShip transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "retrieve_tracking")) {
      return <RetrieveTracking transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "tracking_not_found")) {
      return <TrackingNotFound transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "in_transit")) {
      return <InTransit transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "arrive_shipment")) {
      return <ArriveShipment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "release_funds")) {
      return <ReleaseFunds transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "completed")) {
      return <Completed/>;
    }
    if(this.props.transaction.states.some(s => s.status === "rejected")) {
      return <Rejected transaction={this.props.transaction} />;
    }
    if(this.props.transaction.states.some(s => s.status === "deleted")) {
      return <Deleted/>;
    }

    return <Invalid/>;
  }
}

export default ParentActionRenderer;

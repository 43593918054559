import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAllAccounts } from '../../services/accounts_service';
import { setAccountId } from '../../services/authentication_service';
import ErrorHandler from '../../util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Table from "../../components/table";

class SelectAccount extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      accounts: []
    }
  }

  componentDidMount() {
    this.getAllAccounts();
  }
  
  getAllAccounts() {
    this.setState({ account_rows: undefined });
    getAllAccounts(this.state.page - 1, 10).then((accounts) => {
      if (accounts) {
        this.setState({ accounts: accounts }, () => {
          this.setAccountRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  openAccount(account) {
    setAccountId(account.id);
    this.redirect();
  }
  
  redirect() {
    const params = new URLSearchParams(this.props.location.search);
    var ref = params.get('ref') ? params.get('ref') : "/";
    var search = "?" + params;
    this.props.history.replace({
      pathname: ref,
      search: search
     });
  }
  
  setAccountRows() {
    var rows = [];
    this.state.accounts.map(account => {
      rows.push({
        id: account.id,
        registered_name: account.registered_name,
        type: StringUtil.capitalize(account.type),
        country_of_incorporation: account.country_of_incorporation,
        registration_number: account.registration_number
      });
    });
    this.setState({ account_rows: rows });
  }

  getAccountHeaders() {
    return [
      { name: "Registered name", key: "registered_name" },
      { name: "Account type", key: "type" },
      { name: "Registration number", key: "registration_number" },
      { name: "Country of incorporation", key: "country_of_incorporation" }
    ];
  }

  render() {
    return (
      <div>
                
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Select account</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Accounts</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getAccountHeaders()}
                  rows={this.state.account_rows}
                  selectable={true}
                  onRowSelected={this.openAccount.bind(this)}
                  empty_rows_text="There are no accounts"
                />
              </div>
            </div>

          </div>
        </div>
      </div >
    );
  }
}

export default SelectAccount;

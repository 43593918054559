import { WebhooksApi } from "generated-webhooks-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new WebhooksApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getWebhooks() {
  return API.getWebhooks(getHeaders()).then(handleResponse);
}

export function createWebhook(data) {
  return API.createWebhook(data, getHeaders()).then(handleResponse);
}

export function getWebhook(id) {
  return API.getWebhook(id, getHeaders()).then(handleResponse);
}

export function deleteWebhook(id) {
  return API.deleteWebhook(id, getHeaders()).then(handleResponse);
}

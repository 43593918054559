import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Mapper from '../util/mapper';

class DocumentTypeField extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      required_document: this.props.required_document
    };
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.required_document[e.target.name] = value;
    this.setState({ required_document: this.state.required_document });
    this.props.requiredDocumentTypeUpdated(this.state.required_document, this.props.parent_data);
  }

  render() {
    if(this.state.required_document.type === "other") {
      return (
        <TextField
          label="Other document type"
          variant="outlined"
          required
          disabled={this.props.disabled}
          fullWidth
          margin="dense"
          value={this.state.required_document.other_name}
          name="other_name"
          onChange={this.handleChange.bind(this)}
        />
      );
    }
    
    return (
      <FormControl
        variant="outlined"
        margin="dense"
        disabled={this.props.disabled}
        fullWidth>
        <InputLabel>Required Document *</InputLabel>
        <Select
          native
          required
          label="Document type *"
          value={this.state.required_document.type}
          name="type"
          onChange={this.handleChange.bind(this)} >
            <option key="empty" value=""></option>
            {Object.keys(this.props.disabled ? Mapper.allDocumentTypes() : Mapper.allowedDocumentTypes()).map(type => {
              return (<option key={type} value={type}>{Mapper.fromDocumentType(type)}</option>);
            })}
        </Select>
      </FormControl>
    );
  }
}

export default DocumentTypeField;

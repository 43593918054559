import React from 'react';

class Deleted extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Deleted</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>This transaction has been deleted.</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Deleted;

import React from 'react';
import confirm from '../../../../shared/components/confirm.js'
import MultiDocumentUpload from '../../../../shared/components/multi_document_upload.js'
import Mapper from '../../../../shared/util/mapper'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class ArriveShipment extends React.Component {

  constructor(props) {
    super(props)

    var required_documents = [];
    var milestone = this.props.transaction.transport_milestones.find(m => m.type === "at_arrival");
    if(milestone) {
      required_documents = milestone.required_documents;
    }

    this.state = {
      flow_patch: {
        status: "arrive_shipment",
        action: "shipment_arrived",
        required_documents: required_documents
      }
    };
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you the shipment has arrived at " + Mapper.toDestination(this.props.transaction.incoterms) + "?").then(() => {
      var arrive_flow_patch = Mapper.toArriveShipment(this.state.flow_patch);
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(arrive_flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  documentsUpdated(documents, parent_data) {
    this.state.flow_patch.required_documents[parent_data.index].documents = documents;
    this.setState({ flow_patch: this.state.flow_patch });
  }

  getDocumentType(required_document) {
    if(required_document.type === "other") {
      return required_document.other_name;
    }
    return Mapper.fromDocumentType(required_document.type);
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Arrival confirmation</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please notify when the cargo shipment has arrived at {Mapper.toDestination(this.props.transaction.incoterms)} and upload all required documents.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                {this.state.flow_patch.required_documents.map((required_document, index) => {
                  return (
                    <div key={required_document.id} className="row">
                      <div className="col-md-6">
                        <MultiDocumentUpload
                          title={this.getDocumentType(required_document)}
                          access="allowed_accounts_and_escrow"
                          allowed_account_ids={[ this.props.transaction.buyer.id, this.props.transaction.seller.id]}
                          allow_authorised_accounts={true}
                          required
                          documentsUpdated={this.documentsUpdated.bind(this)}
                          parent_data={{ index: index }} />
                      </div>
                    </div>
                  )
                })}
                
                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Submit" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ArriveShipment;
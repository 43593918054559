import React from 'react';

class AgreeTransaction extends React.Component {
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Escrow agreement</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for all parties to accept the escrow agreement.</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgreeTransaction;

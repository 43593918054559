import React from 'react';

class ReleaseFunds extends React.Component {

  getParty() {
    if(this.props.type === "carrier") {
      return "Carrier";
    } else if(this.props.type === "broker") {
      return "Broker";
    }
  }

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Release {this.getParty()} funds</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for the {this.getParty()} funds to be released by the Escrow Agent.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ReleaseFunds;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

class Loading extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Loading..</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Skeleton count={3} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;

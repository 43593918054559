import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DocumentUpload from '../../../../shared/components/document_upload';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mapper from '../../../../shared/util/mapper';
import Converter from '../../../../shared/util/converter'

class GeneralForm extends React.Component {

  constructor(props) {
    super(props)
    this.transactionDocumentUploaded = this.transactionDocumentUploaded.bind(this);
    this.transactionDocumentCleared = this.transactionDocumentCleared.bind(this);
    
    this.state = {
      currency: this.props.transaction.currency,
      commercial_invoice: this.props.transaction.commercial_invoice,
      commercial_invoice_amount: this.props.transaction.commercial_invoice_amount
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key]);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);
  }

  transactionDocumentUploaded(id, file_name, parent_data) {
    var value = { id: id, file_name: file_name };
    this.setState({ [parent_data.name]: value })
    this.props.propertyUpdate(parent_data.name, value);
  }

  transactionDocumentCleared(parent_data) {
    this.setState({ [parent_data.name]: undefined })
    this.props.propertyUpdate(parent_data.name, undefined);
  }

  hasPaidMilestone() {
    return this.props.transaction.payment_milestones.find(m => m.status && m.status !== "outstanding");
  }

  isTrue(value) {
    return value === "true" || value === true;
  }

  render() {
    return (
      <div>
        
        <div className="row">
          <div className="col-md">
            <span>The escrow fee is deducted from the funds held in escrow. This means that the seller pays the escrow fee and receives less funds than specified on the commercial invoice. If the buyer is responsible for (a part of) the payment of the escrow fee, it needs to be specified on the commercial invoice. The <Link to={`/calculator/`}>calculator</Link> can help to calculate the required addition for the invoice amount. Note: an invoice will be generated for the deducted escrow fee that can be used for administrative purposes.</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              disabled={this.hasPaidMilestone()}
              fullWidth>
              <InputLabel>Currency *</InputLabel>
              <Select
                native
                required
                label="Currency *"
                value={this.state.currency}
                name="currency"
                onChange={this.handleChange.bind(this)} >
                  <option key={"currency_empty"} value=""></option>
                  {Object.keys(Mapper.currencies()).map(currency => {
                    return (<option key={"currency_"+currency} value={currency}>{Mapper.fromCurrency(currency)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4">
            <TextField
              label="Commercial invoice amount"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.commercial_invoice_amount}
              name="commercial_invoice_amount"
              onChange={this.handleChange.bind(this)}
              InputProps={{
                inputComponent: Converter.numberFormatter,
                startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.state.currency)}</InputAdornment>,
              }}
            />
          </div>
          <div className="col-md-4">
            <DocumentUpload
              title="Commercial invoice"
              access="allowed_accounts_and_escrow"
              allowed_account_ids={[ this.props.transaction.buyer.id, this.props.transaction.seller.id]}
              allow_authorised_accounts={true}
              documentUploaded={this.transactionDocumentUploaded}
              documentCleared={this.transactionDocumentCleared}
              document_id={this.state.commercial_invoice ? this.state.commercial_invoice.id : undefined}
              file_name={this.state.commercial_invoice ? this.state.commercial_invoice.file_name : undefined}
              parent_data={{ name: "commercial_invoice" }} />
          </div>
        </div>
        
      </div>
    );
  }
}

export default GeneralForm;

import React from 'react';
import { Link } from 'react-router-dom';
import alertDialog from '../../components/alert';
import AsyncButton from '../../components/async_button';
import DomUtil from '../../util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getInvite, resendInvite, deleteInvite } from '../../services/accounts_service';
import ErrorHandler from '../../util/error_handler';
import confirm from '../../components/confirm'
import { isReadOnly, hasType } from '../../services/authentication_service';
import StringUtil from '../../util/string_util';
import Converter from '../../util/converter';
import LoadingText from '../../components/loading_text';
import ReactTimeAgo from 'react-time-ago'

class ShowInvite extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invite_id: this.props.match.params.invite_id,
      invite: undefined
    };
  }

  componentDidMount() {
    this.getInvite();
  }

  getInvite() {
    getInvite(this.state.invite_id).then((invite) => {
      if (invite) {
        this.setState({ invite: invite });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  resendInvite(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to resend this invite?`).then((result) => {
      DomUtil.disableClass(button);
      resendInvite(this.state.invite_id).then(() => {
        alertDialog("Alert", "Invite has been resend to email address.");
        DomUtil.enableClass(button);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }

  deleteInvite(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to delete this invite?`).then((result) => {
      DomUtil.disableClass(button);
      deleteInvite(this.state.invite_id).then(() => {
        this.props.history.push(this.props.return_url + this.props.location.search);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={this.props.return_url + this.props.location.search}>{this.props.return_name}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Show</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invite</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                { !isReadOnly() &&
                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        variant="outlined"
                        color="primary"
                        onClick={this.resendInvite.bind(this)}
                        icon="send"
                        text="Resend" />
                        <AsyncButton
                          className="button-danger-outlined"
                          variant="outlined"
                          color="danger"
                          onClick={this.deleteInvite.bind(this)}
                          icon="delete"
                          text="Delete" />
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">Company email</td>
                      <td><LoadingText text={this.state.invite ? this.state.invite.email : undefined} /></td>
                    </tr>
                      <tr>
                        <td className="header-text">Invited</td>
                        <td><LoadingText text={this.state.invite ?  <ReactTimeAgo date={new Date(this.state.invite.invited_at)} /> : undefined} /></td>
                      </tr>
                    { hasType("admin") &&
                      <tr>
                        <td className="header-text">Account type</td>
                        <td><LoadingText text={this.state.invite ? StringUtil.capitalize(this.state.invite.type) : undefined} /></td>
                      </tr>
                    }
                    { hasType("admin") &&
                      <tr>
                        <td className="header-text">Partner percentage</td>
                        <td><LoadingText text={this.state.invite ? (this.state.invite.partner_percentage || this.state.invite.partner_percentage === 0) ? Converter.toDigits(this.state.invite.partner_percentage, 2) + "%" : " " : undefined} /></td>
                      </tr>
                    }
                    { hasType("admin") &&
                      <tr>
                        <td className="header-text">Partner territory</td>
                        <td><LoadingText text={this.state.invite ? this.state.invite.partner_territory ? StringUtil.commaSeparated(this.state.invite.partner_territory.map(country => country.name)) : " " : undefined} /></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default ShowInvite;

import { TradeTransactionsApi } from "generated-trade-transactions-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new TradeTransactionsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function createTradeTransaction(data) {
  return API.createTradeTransaction(data, getHeaders()).then(handleResponse);
}

export function getTradeTransaction(id) {
  return API.getTradeTransaction(id, getHeaders()).then(handleResponse);
}

export function getTradeTransactions(page, size) {
  return API.getTradeTransactions(page, size, getHeaders()).then(handleResponse);
}

export function getTradeTransactionCount() {
  return API.getTradeTransactionCount(getHeaders()).then(handleResponse);
}

export function updateTradeTransaction(id, data) {
  return API.updateTradeTransaction(id, data, getHeaders()).then(handleResponse);
}

export function calculateTradeTransactionCharges(calculation) {
  return API.calculateTradeTransactionCharges(calculation, getHeaders()).then(handleResponse);
}

export function getTradeTransactionsStatistics(startDate, endDate) {
  return API.getTradeTransactionsStatistics(startDate, endDate, getHeaders()).then(handleResponse);
}

export function getTradeTransactionsPaymentsStatistics() {
  return API.getTradeTransactionsPaymentsStatistics(getHeaders()).then(handleResponse);
}

export function getCarriers() {
  return API.getCarriers(getHeaders()).then(handleResponse);
}

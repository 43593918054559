import React from 'react';

class VesselTab extends React.Component {

  openContainers(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <div className="row no-spacing-bottom">
          <div className="col-sm-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td className="header-text">Vessel name</td>
                  <td>{this.props.transaction.vessel_name}</td>
                </tr>
                <tr>
                  <td className="header-text">Vessel country</td>
                  <td>{this.props.transaction.vessel_country}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td className="header-text">Vessel IMO</td>
                  <td>{this.props.transaction.vessel_imo}</td>
                </tr>
                <tr>
                  <td className="header-text">Vessel MMSI</td>
                  <td>{this.props.transaction.vessel_mmsi}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default VesselTab;

import { CompliancesApi, ComplianceDocumentsApi } from "generated-compliances-api";
import { getHeaders, handleResponse } from './web_service';

export const COMPLIANCE_API = new CompliancesApi({
  basePath: window.appConfig.API_HOSTNAME
});

export const COMPLIANCE_DOCUMENT_API = new ComplianceDocumentsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getLatestComplianceDocument() {
  return COMPLIANCE_DOCUMENT_API.getLatestComplianceDocument(undefined, getHeaders()).then(handleResponse);
}

export function createComplianceDocument(compliance) {
  return COMPLIANCE_DOCUMENT_API.createComplianceDocument(compliance, undefined, getHeaders()).then(handleResponse);
}

export function getComplianceDocuments(page, size) {
  return COMPLIANCE_DOCUMENT_API.getComplianceDocuments(page, size, getHeaders()).then(handleResponse);
}

export function getComplianceDocumentCount() {
  return COMPLIANCE_DOCUMENT_API.getComplianceDocumentCount(getHeaders()).then(handleResponse);
}

export function signComplianceDocument(id, complianceSignature) {
  return COMPLIANCE_DOCUMENT_API.signComplianceDocument(id, complianceSignature, getHeaders()).then(handleResponse);
}

export function getComplianceDocument(id) {
  return COMPLIANCE_DOCUMENT_API.getComplianceDocument(id, getHeaders()).then(handleResponse);
}

export function updateComplianceDocument(id, complianceDocumentUpdate) {
  return COMPLIANCE_DOCUMENT_API.updateComplianceDocument(id, complianceDocumentUpdate, getHeaders()).then(handleResponse);
}

export function getAccountCompliance() {
  return COMPLIANCE_API.getAccountCompliance(getHeaders()).then(handleResponse);
}

export function updateAccountCompliance(compliance) {
  return COMPLIANCE_API.updateAccountCompliance(compliance, getHeaders()).then(handleResponse);
}

export function downloadComplianceDocumentAttachments(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return COMPLIANCE_DOCUMENT_API.downloadComplianceDocumentAttachments(id, headers).then(handleResponse);
}

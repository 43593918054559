import { AuthorisationsApi } from "generated-authorisation-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new AuthorisationsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getAccountAuthorisations() {
  return API.getAccountAuthorisations(getHeaders()).then(handleResponse);
}

export function createAccountAuthorisation(accountAuthorisation) {
  return API.createAccountAuthorisation(accountAuthorisation, getHeaders()).then(handleResponse);
}

export function deleteAccountAuthorisation(id) {
  return API.deleteAccountAuthorisation(id, getHeaders()).then(handleResponse);
}

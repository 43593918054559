import { DocumentsApi } from "generated-documents-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DocumentsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getDocument(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getDocument(id, headers).then(handleResponse);
}

export function uploadDocument(file, access, allowed_account_ids, allow_authorised_accounts, progress_event) {
  var allowed_account_id_list = [];
  if(allowed_account_ids) {
    allowed_account_id_list = allowed_account_ids.join(",");
  }
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.uploadDocument(file, access, allowed_account_id_list, allow_authorised_accounts, headers).then(handleResponse);
}
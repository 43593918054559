import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import confirm from '../../../../shared/components/confirm.js'
import MultiDocumentUpload from '../../../../shared/components/multi_document_upload.js'
import { getCarriers } from '../../../../shared/services/trade_transactions_service.js';
import ErrorHandler from '../../../../shared/util/error_handler';
import Mapper from '../../../../shared/util/mapper'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class LoadOnShip extends React.Component {

  constructor(props) {
    super(props)

    var required_documents = [];
    var milestone = this.props.transaction.transport_milestones.find(m => m.type === "at_ship_loading");
    if(milestone) {
      required_documents = milestone.required_documents;
    }

    this.state = {
      flow_patch: {
        status: "load_on_ship",
        action: "loaded_on_ship",
        required_documents: required_documents
      },
      carriers: []
    };
  }

  componentWillMount() {
    this.getCarriers();
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure the cargo is loaded on the ship and the correct tracking details are provided?").then(() => {
      var load_flow_patch = Mapper.toLoadedOnShip(this.state.flow_patch);
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(load_flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  documentsUpdated(documents, parent_data) {
    this.state.flow_patch.required_documents[parent_data.index].documents = documents;
    this.setState({ flow_patch: this.state.flow_patch });
  }

  getDocumentType(required_document) {
    if(required_document.type === "other") {
      return required_document.other_name;
    }
    return Mapper.fromDocumentType(required_document.type);
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.flow_patch[e.target.name] = value;
    this.setState({ flow_patch: this.state.flow_patch });
  }


  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.flow_patch.carrier = newValue.id;
    } else {
      this.state.flow_patch.carrier = undefined;
    }
    this.setState({ flow_patch: this.state.flow_patch });
  }

  getCarriers() {
    getCarriers().then((carriers) => {
      if (carriers) {
        this.setState({ carriers: carriers.carriers });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  render() {
    return (
      <div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Load on ship</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please upload all required documents once the goods have been loaded onto the ship.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                {this.state.flow_patch.required_documents.map((required_document, index) => {
                  return (
                    <div key={required_document.id} className="row">
                      <div className="col-md-6">
                        <MultiDocumentUpload
                          title={this.getDocumentType(required_document)}
                          access="allowed_accounts_and_escrow"
                          allowed_account_ids={[ this.props.transaction.buyer.id, this.props.transaction.seller.id]}
                          allow_authorised_accounts={true}
                          required
                          documentsUpdated={this.documentsUpdated.bind(this)}
                          parent_data={{ index: index }} />
                      </div>
                    </div>
                  )
                })}

                <div className="row">
                  <div className="col-md">
                    <span>Provide the carrier and container or master B/L number, these are used to track the shipment during the transaction.</span>
                    <br/>
                    <span>If the carrier is not present in the list of carriers, select <i>Others</i>.</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Autocomplete
                      key="carrier"
                      options={this.state.carriers}
                      groupBy={this.firstLetter.bind(this)}
                      getOptionLabel={(option) => option.name}
                      value={this.state.flow_patch.carrier}
                      onChange={this.handleAutoCompleteChange.bind(this)}
                      renderInput={(params) => 
                        <TextField {...params}
                          label="Carrier"
                          variant="outlined"
                          fullWidth
                          required
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth>
                      <InputLabel>Tracking document type *</InputLabel>
                      <Select
                        native
                        required
                        label="Tracking document type *"
                        value={this.state.flow_patch.tracking_document_type}
                        name="tracking_document_type"
                        onChange={this.handleChange.bind(this)} 
                      >
                        <option key={"tracking_document_type_empty"} value=""></option>
                        {Object.keys(Mapper.trackingDocumentTypes()).map(type => {
                          return (<option key={"tracking_document_type_" + type} value={type}>{Mapper.fromTrackingDocumentType(type)}</option>);
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      label={Mapper.fromTrackingDocumentType(this.state.flow_patch.tracking_document_type)}
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      value={this.state.flow_patch.tracking_number}
                      name="tracking_number"
                      onChange={this.handleChange.bind(this)} 
                    />
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Submit" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default LoadOnShip;
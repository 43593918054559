import React from 'react';
import Button from '@material-ui/core/Button';
import Mapper from '../../../util/mapper'
import Converter from '../../../util/converter'
import alertDialog from '../../../components/alert';
import { getDocument } from '../../../services/documents_service';
import fileDownload from 'js-file-download';

class PaymentsTab extends React.Component {

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  getSortedPaymentMilestones() {
    return this.props.transaction.payment_milestones.sort((a, b) => a.rank - b.rank);
  }

  getPaymentMilestoneAmountPercentage(payment_milestone) {
    var commercial_invoice_amount = this.props.transaction.commercial_invoice_amount;
    return Converter.toDigits((payment_milestone.amount / commercial_invoice_amount) * 100, 2);
  }

  getPaymentMilestoneRows(payment_milestone, index) {
    var rows = [];

    rows.push(
      <tr key={payment_milestone.id}>
        <td>{index + 1}</td>
        <td>{payment_milestone.condition}</td>
        <td>{Converter.toCurrency(this.props.transaction.currency, payment_milestone.amount)} ({this.getPaymentMilestoneAmountPercentage(payment_milestone)}%)</td>
        <td><span className={"badge " + Mapper.toPaymentStatusBadge(payment_milestone.status)} >{Mapper.fromPaymentStatus(payment_milestone.status)}</span></td>
        <td>{Converter.toDate(payment_milestone.received_at)}</td>
      </tr>
    );

    rows.push(
      <tr key="header" className="row-no-top-border">
        <td></td>
        <td colspan="4" className="header-text cell-bottom-border">Documents</td>
      </tr>
    );

    if(payment_milestone.required_documents.length === 0) {
      rows.push(
        <tr key="header" className="row-no-top-border">
          <td></td>
          <td colspan="4"><i>There are no required documents</i></td>
        </tr>
      );
    } else {
      for(const required_document of payment_milestone.required_documents) {
        rows.push(this.getDocumentsRow(required_document));
      }
    }

    return rows;
  }

  getDocumentsRow(required_document) {
    return (
      <tr key="header" className="row-no-top-border">
        <td></td>
        <td>{this.getDocumentType(required_document)}</td>
        <td colspan="3">{this.getDocumentFiles(required_document.documents)}</td>
      </tr>);
  }

  getDocumentType(required_document) {
    if(required_document.type === "other") {
      return required_document.other_name;
    }
    return Mapper.fromDocumentType(required_document.type);
  }

  getDocumentFiles(documents) {
    var docs = [];
    
    if(documents.length === 0) {
      return (
        <i>Document not available yet</i>
      );
    }
    
    for(const document of documents) {
      docs.push(        
        <div className="inline-download-document">
          <span className="icon-span"><i className="far fa-file"/></span>
          <Button
            className="download-document-button"
            color="primary"
            disabled={this.props.read_only}
            onClick={this.downloadDocument.bind(this, document.id, document.file_name)} >
            {document.file_name}
          </Button>
        </div>
      );
    }

    return docs;
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-details" >
              <tbody>
                <tr key="header" className="row-no-top-border-and-padding">
                  <td className="header-text">#</td>
                  <td className="header-text">Condition</td>
                  <td className="header-text">Amount</td>
                  <td className="header-text">Status</td>
                  <td className="header-text">Date received</td>
                </tr>
                {this.getSortedPaymentMilestones().map((payment_milestone, index) => {
                  return this.getPaymentMilestoneRows(payment_milestone, index);
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentsTab;

import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import confirmText from '../../../components/confirm_text.js'
import alertDialog from '../../../components/alert';
import confirm from '../../../components/confirm.js'
import AsyncButton from '../../../components/async_button';
import DomUtil from '../../../util/dom_util';
import ErrorHandler from '../../../util/error_handler';
import { getUserId, isAdmin, isAuthorised } from '../../../services/authentication_service';
import { getLatestComplianceDocument } from '../../../services/compliances_service';


class AgreeTransactionAction extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: {
        status: this.props.flow_status,
        action: "amendment_request_accepted"
      }
    };
  }

  componentDidMount() {
    this.setAuthorisedUser();
  }

  setAuthorisedUser() {
    if(this.props.type === "buyer" || this.props.type === "seller") {
      this.setComplianceAuthorisedUser();
    } else if(this.props.type === "escrow") {
      this.setSessionAuthorisedUser();
    }
  }

  setSessionAuthorisedUser() {
    var is_authorised = isAdmin() || isAuthorised();
    this.setState({ is_authorised: is_authorised });
  }
  
  setComplianceAuthorisedUser() {
    var userId = getUserId();
    getLatestComplianceDocument().then((compliance_document) => {
      if(compliance_document) {
        var authorised_user = compliance_document.authorised_users.find(c => c.user_account_id === userId);
        if(authorised_user) {
          this.setState({ is_authorised: true });
        } else {
          this.setState({ is_authorised: false });
        }
      } else {
        this.setState({ is_authorised: false });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to accept this amendment request?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  rejectTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "Are you sure you want to decline this amendment request? Please state the reason.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({ 
          status: this.props.flow_status,
          action: "rejected",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  render() {
    return (
      <div>

        { this.props.transaction.messages && this.props.transaction.messages.length > 0 &&
          <div className="row action-required-row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">An amendment of the transaction was requested by {this.props.transaction.messages[0].message_by.registered_name}. Request: {this.props.transaction.messages[0].message}</Alert>
            </div>
          </div>
        }
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Review amendment request</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                <div className="row">
                  <div className="col-md">
                    <span>An amendment of the transaction has been requested. Please read the reason stated above and accept or decline the amendment request.</span>
                    <br/>
                    <br/>
                    <span>If the amendment request is accepted it will allow the Seller to amend the transaction details. Payment milestones that are already paid in escrow cannot be changed and remain safe in escrow. After the amendment a new Escrow Agreement will be generated that needs to be signed. All parties need to approve the amendment request.</span>
                    <br/>
                    <br/>
                    <span>If the amendment request is declined the transaction will continue without any change.</span>
                  </div>
                </div>

                { !this.state.is_authorised &&
                  <div className="row">
                    <div className="col-md-12">
                      <Alert severity="error">You don't have the permission to accept or declines this request. It can only be accepted or declined by a director, key executive or authorised representative.</Alert>              
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-md">
                    { this.state.is_authorised &&
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Accept" />
                    }
                    { !this.state.is_authorised &&
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disabled
                        startIcon={<Icon>check</Icon>}>
                        Accept
                      </Button>
                    }
                    { this.state.is_authorised &&
                      <AsyncButton
                        className="button-danger-outlined"
                        variant="outlined"
                        color="danger"
                        onClick={this.rejectTransaction.bind(this)}
                        icon="clear"
                        text="Decline" />
                    }
                    { !this.state.is_authorised &&
                      <Button
                        className="button-danger-outlined"
                        variant="outlined"
                        color="danger"
                        disabled
                        startIcon={<Icon>clear</Icon>}>
                        Decline
                      </Button>
                    }
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgreeTransactionAction;

import { InvoicesApi } from "generated-invoices-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new InvoicesApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getInvoices(page, size, all=false) {
  return API.getInvoices(page, size, undefined, all, getHeaders()).then(handleResponse);
}

export function getInvoice(id) {
  return API.getInvoice(id, getHeaders()).then(handleResponse);
}

export function getInvoiceCount(all=false) {
  return API.getInvoiceCount(all, getHeaders()).then(handleResponse);
}

export function updateInvoice(id, data) {
  return API.updateInvoice(id, data, getHeaders()).then(handleResponse);
}

import { FreightTransactionsApi } from "generated-freight-transactions-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new FreightTransactionsApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function createFreightTransaction(data) {
  return API.createFreightTransaction(data, getHeaders()).then(handleResponse);
}

export function getFreightTransaction(id) {
  return API.getFreightTransaction(id, getHeaders()).then(handleResponse);
}

export function getFreightTransactions(page, size) {
  return API.getFreightTransactions(page, size, getHeaders()).then(handleResponse);
}

export function getFreightTransactionCount() {
  return API.getFreightTransactionCount(getHeaders()).then(handleResponse);
}

export function updateFreightTransaction(id, data) {
  return API.updateFreightTransaction(id, data, getHeaders()).then(handleResponse);
}

export function calculateFreightTransactionCharges(calculation) {
  return API.calculateFreightTransactionCharges(calculation, getHeaders()).then(handleResponse);
}

export function getFreightTransactionsStatistics(startDate, endDate) {
  return API.getFreightTransactionsStatistics(startDate, endDate, getHeaders()).then(handleResponse);
}

export function getFreightTransactionsPaymentsStatistics() {
  return API.getFreightTransactionsPaymentsStatistics(getHeaders()).then(handleResponse);
}

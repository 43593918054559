import React from 'react';
import confirmText from '../../../../shared/components/confirm_text.js'
import alertDialog from '../../../../shared/components/alert';
import confirm from '../../../../shared/components/confirm.js'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class ReviewTransaction extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: {
        status: this.props.flow_status,
        action: "transaction_accepted"
      }
    };
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to accept this transaction?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  requestChangeTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "This will return the transaction to the details phase and allow the Seller to change the transaction details. Are you sure you want to request a change? Please describe what needs to be changed.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({ 
          status: this.props.flow_status,
          action: "transaction_change_requested",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  rejectTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "Are you sure you want to decline this transaction? Please state the reason.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({
          status: this.props.flow_status,
          action: "rejected",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Confirmation</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please review the transaction details, to accept this transaction please confirm below.</span>
              <br/>
              <span>If any transaction details are incorrect, please decline the transaction so the details can be updated.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Accept" />
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.requestChangeTransaction.bind(this)}
                      icon="edit"
                      text="Request change" />
                    <AsyncButton
                      className="button-danger-outlined"
                      variant="outlined"
                      color="danger"
                      onClick={this.rejectTransaction.bind(this)}
                      icon="clear"
                      text="Decline" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ReviewTransaction;

import ParentActionRenderer from '../../../../shared/pages/freight-transactions/actions/parent_action_renderer';
import { isReadOnly } from '../../../../shared/services/authentication_service';

class ActionRenderer extends ParentActionRenderer {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    return this.props.updateTransaction(data);
  }

  render() {
    if(!this.props.transaction.states || isReadOnly()) {
      return super.render();
    }

    return super.render();
  }
}

export default ActionRenderer;

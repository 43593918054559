import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import fileDownload from 'js-file-download';
import alertDialog from '../../../shared/components/alert';
import AsyncButton from '../../../shared/components/async_button';
import { getDocument } from '../../../shared/services/documents_service';
import { getComplianceDocument, downloadComplianceDocumentAttachments, updateComplianceDocument } from '../../../shared/services/compliances_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import StringUtil from '../../../shared/util/string_util';
import DomUtil from '../../../shared/util/dom_util';
import Converter from '../../../shared/util/converter';
import confirm from '../../../shared/components/confirm'
import EventBus from '../../../shared/components/event_bus';
import LoadingText from '../../../shared/components/loading_text';
import confirmText from '../../../shared/components/confirm_text.js'
import { hasType, isAdmin, isAuthorised, isReadOnly } from '../../../shared/services/authentication_service';

class Compliance extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      compliance_id: this.props.match.params.compliance_id,
      compliance_document: undefined,
      compliance_document_url: undefined,
    };
  }

  componentDidMount() {
    this.getComplianceDocument();
  }

  getComplianceDocument() {
    getComplianceDocument(this.state.compliance_id).then((compliance_document) => {
      if (compliance_document) {
        this.setState({ compliance_document: compliance_document });
        this.getDocument(compliance_document);
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getDocument(compliance_document) {
    getDocument(compliance_document.document.id).then((document) => {
      document = document.slice(0, document.size, "application/pdf")
      const fileURL = URL.createObjectURL(document);
      this.setState({ compliance_document_url: fileURL });      
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  downloadAttachments(e) {
    var file_name = this.state.compliance_document.document.file_name.replace(".pdf", "") + " - attachments.zip"
    var form = e.currentTarget;
    DomUtil.disableClass(form);

    downloadComplianceDocumentAttachments(this.state.compliance_document.id).then(blob => {
      fileDownload(blob, file_name);
      DomUtil.enableClass(form);
    }).catch(error => {
      DomUtil.enableClass(form);
      alertDialog("Alert", "Failed to download attachments.")
    });
  }

  getStatus(compliance_document) {
    if(this.isExpired(compliance_document)) {
      return "expired";
    }
    return compliance_document.status;
  }

  updateCompliance(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to approve this KYC?").then(() => {
      DomUtil.disableClass(button);
      updateComplianceDocument(this.state.compliance_id, {
        status: "approved"
      }).then(() => {
        EventBus.dispatch("updateCompliance");
        DomUtil.enableClass(button);
        this.getComplianceDocument();
      }).catch((error) => {
        DomUtil.enableClass(button);
        ErrorHandler.showError(error);
      });
    });
  }
  
  clarificationCompliance(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "Are you sure you want to ask for clarification? Please type your message below.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        updateComplianceDocument(this.state.compliance_id, {
          status: "clarification",
          clarification: result
        }).then(() => {
          EventBus.dispatch("updateCompliance");
          DomUtil.enableClass(button);
          this.getComplianceDocument();
        }).catch((error) => {
          DomUtil.enableClass(button);
          ErrorHandler.showError(error);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  rejectCompliance(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to decline this KYC? The customer will not be able to proceed once the platform.").then(() => {
      DomUtil.disableClass(button);
      updateComplianceDocument(this.state.compliance_id, {
        status: "rejected"
      }).then(() => {
        EventBus.dispatch("updateCompliance");
        DomUtil.enableClass(button);
        this.getComplianceDocument();
      }).catch((error) => {
        DomUtil.enableClass(button);
        ErrorHandler.showError(error);
      });
    });
  }

  showComplianceEscrowActions() {
    if(!hasType("escrow") || !this.state.compliance_document) {
      return false;
    }
    return this.state.compliance_document.status === "submitted" && !this.isExpired(this.state.compliance_document);
  }

  isExpired(compliance_document) {
    if(!compliance_document) {
      return false;
    }
    return new Date(compliance_document.expires_at) < Date.now();
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/compliances/` + this.props.location.search}>Compliances</Link></BreadcrumbItem>
            <BreadcrumbItem active>{StringUtil.shortId(this.state.compliance_id)}</BreadcrumbItem>
          </Breadcrumb>

          { this.state.compliance_document && this.state.compliance_document.clarification && 
            <div className="row action-required-row">
              <div className="col-md-12">
                <Alert style={{ whiteSpace: "pre-line" }} severity="warning">Clarification has been requested for this KYC: {this.state.compliance_document.clarification}</Alert>
              </div>
            </div>
          }

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Compliance</label>
                </div>
              </div>
            </div>
          
            {this.showComplianceEscrowActions() &&
              <div className="row">
                <div className="col-md">
                  {(isAuthorised() || isAdmin()) &&
                    <AsyncButton
                      variant="outlined"
                      color="primary"
                      onClick={this.updateCompliance.bind(this)}
                      icon="check"
                      text="Approve" />
                  }
                  {(!isReadOnly()) &&
                  <AsyncButton
                    variant="outlined"
                    color="secondary"
                    onClick={this.clarificationCompliance.bind(this)}
                    icon="message"
                    text="Ask clarification" />
                  }
                  {(isAuthorised() || isAdmin()) &&
                    <AsyncButton
                      className="button-danger-outlined"
                      variant="outlined"
                      color="danger"
                      onClick={this.rejectCompliance.bind(this)}
                      icon="clear"
                      text="Decline" />
                    }
                </div>
              </div>
            }
            
            <div className="row">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">ID</td>
                      <td><LoadingText text={this.state.compliance_document ? StringUtil.shortId(this.state.compliance_document.id) : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Status</td>
                      <td><LoadingText text={this.state.compliance_document ? <span className={"badge " + Mapper.toComplianceDocumentStatusBadge(this.getStatus(this.state.compliance_document))} >{Mapper.fromComplianceDocumentStatus(this.getStatus(this.state.compliance_document))}</span> : undefined} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "230px"}} className="header-text">Company name</td>
                      <td><LoadingText text={this.state.compliance_document ? this.state.compliance_document.account.registered_name : undefined} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "230px"}} className="header-text">Registration number</td>
                      <td><LoadingText text={this.state.compliance_document ? this.state.compliance_document.account.registration_number : undefined} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "230px"}} className="header-text">Country of incorporation</td>
                      <td><LoadingText text={this.state.compliance_document ? this.state.compliance_document.account.country_of_incorporation : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Signed at</td>
                      <td><LoadingText text={this.state.compliance_document ? Converter.toDatetime(this.state.compliance_document.signed_at) : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Approved at</td>
                      <td><LoadingText text={this.state.compliance_document ? Converter.toDatetime(this.state.compliance_document.approved_at) : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Expires at</td>
                      <td><LoadingText text={this.state.compliance_document ? Converter.toDatetime(this.state.compliance_document.expires_at) : undefined} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Compliance document</td>
                      <td>
                        {this.state.compliance_document &&
                          <div>
                            <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                            <Button
                              className="download-document-button"
                              color="primary"
                              onClick={this.downloadDocument.bind(this, this.state.compliance_document.document.id, this.state.compliance_document.document.file_name)} >
                              {this.state.compliance_document.document.file_name}
                            </Button>
                          </div>
                        }
                        {!this.state.compliance_document &&
                          <LoadingText />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="header-text">Attachments</td>
                      <td>
                        {this.state.compliance_document &&
                          <AsyncButton
                            variant="outlined"
                            color="primary"
                            onClick={this.downloadAttachments.bind(this)}
                            icon="get_app"
                            text="Download" />
                        }
                        {!this.state.compliance_document &&
                          <LoadingText />
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>KYC document</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <iframe className="pdf-viewer" src={this.state.compliance_document_url} type="application/pdf" />
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default Compliance;

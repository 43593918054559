import React from 'react';
import TextField from '@material-ui/core/TextField';
import alertDialog from '../../../components/alert';
import { login } from '../../../services/authentication_service';
import AsyncButton from '../../../components/async_button';
import DomUtil from '../../../util/dom_util';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }

  login(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    login(this.state.email, this.state.password).then(res => {
      DomUtil.enableFormSubmitButtonClass(form);
      this.props.redirect();
    })
    .catch(err => {
      console.log(err);
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Login failed", "Login failed, please try again.");
    });
  }

  render() {
    return (
      <div>

        <form onSubmit={this.login.bind(this)}>

          <div className="row">
              <div className="col-md">
                <TextField
                  label="Email"
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  name="email"
                  autoFocus
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <TextField
                  label="Password"
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <AsyncButton
                  rootClassName="fullwidth-button"
                  className="fullwidth-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  icon="lock"
                  text="Login" />
              </div>
            </div>

        </form>

      </div >
    );
  }
}

export default LoginForm;

import React from 'react';
import { Link } from 'react-router-dom';

class ParentMenu extends React.Component {

  getMenuItem(item) {
    if(item.onClick) {
      return (
        <a className="nav-item" href="/" onClick={item.onClick}>
          {this.getContent(item)}
        </a>
      );
    }
    return (
      <Link to={'/' + item.link}>
        {this.getContent(item)}
      </Link>
    );
  }

  getSubMenuItems(item) {
    var items = [];
    item.sub_items.map(sub_item => {
      items.push(
        <li key={item.name} className={'nav-item ' + (this.isActive(sub_item.link)  ? "active" : "")}>
          {this.getMenuItem(sub_item)}
        </li>
      )
    });
    return items;
  }

  getMobileMenuItem(item) {
    if(item.onClick) {
      return (
        <a className="nav-link" href="/" onClick={item.onClick}>
          {this.getContent(item)}
        </a>
      );
    }
    return (
      <Link className="nav-link" to={'/' + item.link}>
        {this.getContent(item)}
      </Link>
    );
  }

  getContent(item) {
    var rows = [];
    if(item.icon) {
      rows.push(<i key={item.icon} className={item.icon}></i> );
    }
    rows.push(<span key={item.name} >{item.name}</span>);
    rows.push(item.counter > 0 && <span className="numberCircle">{item.counter}</span>);
    rows.push(item.customerPostfix);
    return rows;
  }

  getTopNavMenuItem(item) {
    if(item.onClick) {
      return (
        <a className="nav-link nav-top-link" href="/" onClick={item.onClick}>
          <i className={item.icon}></i>
        </a>
      );
    }
    return (
      <Link className="nav-link nav-top-link" to={'/' + item.link}>
        <i className={item.icon}></i>
      </Link>
    );
  }

  isActive(link) {
    var path = this.props.location.pathname;
    var paths = path.split("/");
    var rootPath = paths.length > 1 ? paths[1] : "";
    var subPath = paths.length > 2 ? paths[2] : "";
    
    if(subPath) {
      return rootPath === link || `${rootPath}/${subPath}` === link; 
    }
    return rootPath === link; 
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-light navbar-top fixed-top d-none d-md-block">
          <div className="navbar-collapse collapse">
            <Link className="navbar-brand" to='/'>
              <img alt="logo" src="/images/logo.png" />
            </Link>
            <div className="navbar-collapse">
              <ul className="navbar-nav ml-auto">
                {this.props.topbar_items.map(topbar_item => {
                  return (
                    <li key={topbar_item.name} className={'nav-item ' + (this.isActive(topbar_item.link)  ? "active" : "")}>
                      {this.getTopNavMenuItem(topbar_item)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        <div className="nav-bar-top-breadcrumbs"/>

        <nav id="sidebar" className="d-none d-md-block" >
          <div className="sidebar-sticky">
              {this.props.sidebar_items.map(sidebar_item => {
                return (
                  <div>
                    <div className="sidebar-title">
                      <p>{sidebar_item.title}</p>
                    </div>
                    <ul className="list-unstyled components">
                      {sidebar_item.items.map(item => {
                        if(item.sub_items) {
                          return (
                            <li key={item.name} className={'nav-item'}>
                              {this.getMenuItem(item)}
                              <ul className="list-unstyled components submenu">
                                {this.getSubMenuItems(item)}
                              </ul>
                            </li>
                          );
                        }
                        return (
                          <li key={item.name} className={'nav-item ' + (this.isActive(item.link)  ? "active" : "")}>
                            {this.getMenuItem(item)}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </nav>
        
        <nav className="navbar navbar-expand-lg navbar-light bg-light d-block d-md-none navbar-mobile">
          <Link className="navbar-brand" to='/'>
            <img alt="logo" src="/images/logo.png" />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {this.props.sidebar_items.map(sidebar_item => {
                return sidebar_item.items.map(item => {
                  if(item.sub_items) {
                    return item.sub_items.map(sub_item => {
                      var aggregate = {
                        icon: item.icon,
                        name: `${sub_item.name} ${item.name}`,
                        link: sub_item.link,
                        counter: sub_item.counter
                      }
                      return (<li key={aggregate.name} className={'nav-item ' + (this.isActive(aggregate.link)  ? "active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show">
                        {this.getMobileMenuItem(aggregate)}
                      </li>)
                    });
                  }
                  return (
                    <li key={item.name} className={'nav-item ' + (this.isActive(item.link)  ? "active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show">
                      {this.getMobileMenuItem(item)}
                    </li>
                  );
                });
              })}
              {this.props.topbar_items.map(topbar_item => {
                return (
                  <li key={topbar_item.name} className={'nav-item ' + (this.isActive(topbar_item.link)  ? "active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show">
                    {this.getMobileMenuItem(topbar_item)}
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>

        <div className="container-fluid">
            { this.props.children }
        </div>
      </div>
    );
  }
}

export default ParentMenu;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ContentLoading from "../../components/content_loading";
import { getDocument } from '../../services/documents_service';
import ErrorHandler from '../../util/error_handler';
import fileDownload from 'js-file-download';

class ViewDocument extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let file_name = params.get('file_name') ? params.get('file_name') : "Document viewer";

    this.state = {
      file_name: file_name,
      document_id: this.props.match.params.document_id,
      document: "",
      document_url: "",
      document_loaded: false,
    };
  }

  componentDidMount() {
    this.getDocument();
  }

  getDocument() {
    getDocument(this.state.document_id).then((document_blob) => {
      var document = document_blob.slice(0, document_blob.size, "application/pdf")
      var document_url = URL.createObjectURL(document);
      this.setState({ document_blob: document_blob, document_url: document_url, document_loaded: true })
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  downloadDocument() {
    fileDownload(this.state.document_blob, this.state.file_name);
  }  

  render() {
    return (
      <div>

        <ContentLoading content_loaded={( this.state.document_loaded )} fullscreen={true}/>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem>Document</BreadcrumbItem>
            <BreadcrumbItem active>{this.state.file_name}</BreadcrumbItem>
          </Breadcrumb>

          <div className="row page-title">
            <div style={{marginTop: "-3px"}} >
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Icon>download</Icon>}
                onClick={this.downloadDocument.bind(this)} >
                Download
              </Button>
            </div>
            <div className="col-md">
              <h4>{this.state.file_name}</h4>
            </div>
          </div>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <iframe className="pdf-viewer" src={this.state.document_url} type="application/pdf" />
              </div>
            </div>
          </div>

        </div>
      </div >
    );
  }
}

export default ViewDocument;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import EventBus from '../../../shared/components/event_bus';
import ErrorHandler from '../../../shared/util/error_handler';
import ComplianceForm from './components/compliance_form';
import ComplianceSign from './components/compliance_sign';
import ComplianceViewer from './components/compliance_viewer';
import ComplianceRejected from './components/compliance_rejected';
import { getLatestComplianceDocument } from '../../../shared/services/compliances_service';

class Compliance extends React.Component {

  constructor(props) {
    super(props)

    this.complianceDocumentCreated = this.complianceDocumentCreated.bind(this);
    this.complianceDocumentSigned = this.complianceDocumentSigned.bind(this);
    this.changeComplianceDocument = this.changeComplianceDocument.bind(this);
    
    this.state = {
      compliance_document: this.props.compliance_document,
      compliance_document_loaded: false
    };
  }

  componentDidMount() {
    this.getLatestComplianceDocument();
  }
  
  getLatestComplianceDocument() {
    getLatestComplianceDocument().then((compliance_document) => {
      this.setState({ compliance_document: compliance_document, compliance_document_loaded: true });
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  complianceDocumentCreated(compliance_document) {
    EventBus.dispatch("updateCompliance");
    this.setState({ compliance_document: compliance_document });
  }
  
  complianceDocumentSigned(compliance_document) {
    EventBus.dispatch("updateCompliance");
    this.getLatestComplianceDocument();
  }

  changeComplianceDocument() {
    this.setState({ compliance_document: undefined });
  }

  isExpired() {
    return new Date(this.state.compliance_document.expires_at) < Date.now();
  }

  getComplianceComponent() {
    if(!this.state.compliance_document_loaded) {
      return (
        <div>
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>KYC document</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md">
                <span>Loading KYC..</span>
              </div>
            </div>
          </div>
        </div>
        );
    }

    if(!this.state.compliance_document || this.state.compliance_document.status === "clarification" || this.isExpired()) {
      return <ComplianceForm
                compliance_document={this.state.compliance_document}
                complianceDocumentCreated={this.complianceDocumentCreated}/>;
    }

    if(this.state.compliance_document.status === "draft") {
      return <ComplianceSign
                compliance_document={this.state.compliance_document}
                changeComplianceDocument={this.changeComplianceDocument}
                complianceDocumentSigned={this.complianceDocumentSigned} />;
    }

    if(this.state.compliance_document.status === "submitted" || this.state.compliance_document.status === "approved") {
      return <ComplianceViewer
                compliance_document={this.state.compliance_document}
                changeComplianceDocument={this.changeComplianceDocument} />;
    }

    return <ComplianceRejected />
  }

  render() {
    return (
      <div>
                
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Compliance</BreadcrumbItem>
          </Breadcrumb>

          {this.getComplianceComponent()}

        </div>
      </div >
    );
  }
}

export default Compliance;

import React from 'react';

class ReviewTransaction extends React.Component {
  
  render() {
    return (
      <div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Transaction review</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Awaiting Buyer's acceptance of this transaction.</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewTransaction;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Skeleton from 'react-loading-skeleton';

class LoadingTextField extends React.Component {
  
  render() {
    return (
      <div>
        <FormControl
          fullWidth
          required
          InputLabelProps={{
            shrink: this.props.shrink
          }}>
          <div className="loading-text-field-overlay">
            <Skeleton />
          </div>
          <TextField
            variant="outlined"
            required
            fullWidth
            margin="dense"
            label={this.props.label}
            InputLabelProps={{
              shrink: this.props.shrink
            }} />
        </FormControl>
      </div>
    );
  }
}

export default LoadingTextField;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import AsyncButton from '../../../components/async_button';
import DomUtil from '../../../util/dom_util';
import { forgotPassword } from '../../../services/authentication_service';
import ErrorHandler from '../../../util/error_handler';
import alertDialog from '../../../components/alert';

class ForgotPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }

  forgotPassword(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    forgotPassword({ email: this.state.email }).then((res) => {
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Message", "Password recovery has been sent.")
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <form onSubmit={this.forgotPassword.bind(this)}>

          <div className="row">
              <div className="col-md">
                <TextField
                  label="Email"
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  name="email"
                  autoFocus
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <AsyncButton
                  rootClassName="fullwidth-button"
                  className="fullwidth-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  icon="send"
                  text="Reset password" />
              </div>
            </div>

        </form>

      </div >
    );
  }
}

export default ForgotPasswordForm;

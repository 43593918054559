import ParentTabRenderer from '../../../../shared/pages/freight-transactions/tabs/parent_tab_renderer';

class TabRenderer extends ParentTabRenderer {

  constructor(props) {
    super(props)

    this.state = {
      current_tab: "general",
      tabs: [ "general", "details", "vessel", "escrow" ]
    };
  }
}

export default TabRenderer;

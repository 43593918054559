import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { getCurrentAccount } from '../../services/accounts_service';
import { isAdmin, hasType, getApiKeys } from '../../services/authentication_service';
import { getWebhooks } from '../../services/webhooks_service';
import Mapper from '../../util/mapper';
import ErrorHandler from '../../util/error_handler';
import Table from "../../components/table";
import LoadingText from '../../components/loading_text';

class Account extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account: {},
      api_keys: [],
      webhooks: []
    }
  }

  componentDidMount() {
    this.getCurrentAccount();
    if(hasType("partner")) {
      this.getApiKeys();
      this.getWebhooks();
    }
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account }, () => {
          this.setUserRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getApiKeys() {
    getApiKeys().then((api_keys) => {
      if (api_keys) {
        this.setState({ api_keys: api_keys }, () => {
          this.setApiKeyRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getWebhooks() {
    getWebhooks().then((webhooks) => {
      if (webhooks) {
        this.setState({ webhooks: webhooks }, () => {
          this.setWebhookRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  openUser(user) {
    this.props.history.push(`/account/` + user.id + this.props.location.search);
  }

  openApiKey(api_key) {
    this.props.history.push(`/account/api-keys/` + api_key.client_id + this.props.location.search);
  }

  openWebhook(webhook) {
    this.props.history.push(`/account/webhooks/` + webhook.id + this.props.location.search);
  }

  getUserHeaders() {
    return [
      { name: "Email", key: "email" },
      { name: "Full name", key: "full_name" },
      { name: "Role", key: "role" }
    ];
  }

  setUserRows() {
    var rows = [];
    this.state.account.users.map(user => {
      rows.push({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
        role: Mapper.fromUserRole(user.role)
      });
    });
    this.setState({ user_rows: rows });
  }

  getApiKeyHeaders() {
    return [
      { name: "Name", key: "name" },
      { name: "Client ID", key: "client_id" },
      { name: "Role", key: "role" },
      { name: "Enabled", key: "enabled" }
    ];
  }

  setApiKeyRows() {
    var rows = [];
    this.state.api_keys.map(api_key => {
      rows.push({
        name: api_key.name,
        client_id: api_key.client_id,
        role: Mapper.fromUserRole(api_key.role),
        enabled: Mapper.fromBoolean(api_key.enabled)
      });
    });
    this.setState({ api_key_rows: rows });
  }

  getWebhookHeaders() {
    return [
      { name: "Callback URL", key: "callback_url" },
      { name: "Types", key: "types" }
    ];
  }

  setWebhookRows() {
    var rows = [];
    this.state.webhooks.map(webhook => {
      rows.push({
        id: webhook.id,
        callback_url: webhook.callback_url,
        types: this.getEventTypes(webhook.event_types)
      });
    });
    this.setState({ webhook_rows: rows });
  }
  
  getEventTypes(event_types) {
    var names = [];
    event_types.forEach(function (value) {
      names.push(Mapper.fromWebhookEventTypes(value));
    });
    return names.join(', ');
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Account</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Company information</label>
                </div>
              </div>
            </div>

            { ((hasType("admin") || hasType("partner")) && isAdmin()) &&
              <div className="row">
                <div className="col-sm">
                  <Link className="link-button-wrapper" to={`/account/edit` + this.props.location.search}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>edit</Icon>}>
                      Edit
                    </Button>
                  </Link>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">Company name</td>
                      <td><LoadingText text={this.state.account.registered_name} /></td>
                    </tr>
                    { !hasType("customer") &&
                      <tr>
                        <td className="header-text">Registered address</td>
                        <td><LoadingText text={this.state.account.registered_address} /></td>
                      </tr>
                    }
                    <tr>
                      <td className="header-text">Country of incorporation</td>
                      <td><LoadingText text={this.state.account.country_of_incorporation} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Registration number</td>
                      <td><LoadingText text={this.state.account.registration_number} /></td>
                    </tr>
                    { (hasType("admin") || hasType("partner")) &&
                      <tr>
                        <td className="header-text">Bank account number</td>
                        <td><LoadingText text={this.state.account.bank_account_number} /></td>
                      </tr>
                    }
                    { (hasType("admin") || hasType("partner")) &&
                      <tr>
                        <td className="header-text">Bank account holder</td>
                        <td><LoadingText text={this.state.account.beneficiary_name} /></td>
                      </tr>
                    }
                    { (hasType("admin") || hasType("partner")) &&
                      <tr>
                        <td className="header-text">Bank name</td>
                        <td><LoadingText text={this.state.account.bank_name} /></td>
                      </tr>
                    }
                    { (hasType("admin") || hasType("partner")) &&
                      <tr>
                        <td className="header-text">Bank SWIFT</td>
                        <td><LoadingText text={this.state.account.bank_swift_code} /></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Users</label>
                </div>
              </div>
            </div>

            { isAdmin() &&
              <div className="row">
                <div className="col-sm">
                  <Link className="link-button-wrapper" to={`/account/invite` + this.props.location.search}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>add</Icon>}>
                      Invite
                    </Button>
                  </Link>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getUserHeaders()}
                  rows={this.state.user_rows}
                  selectable={isAdmin()}
                  onRowSelected={this.openUser.bind(this)}
                  empty_rows_text="There are no users"
                />
              </div>
            </div>
            
          </div>
          
          { hasType("partner") &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>API keys</label>
                  </div>
                </div>
              </div>

              { isAdmin() &&
                <div className="row">
                  <div className="col-sm">
                    <Link className="link-button-wrapper" to={`/account/api-keys/create` + this.props.location.search}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Icon>add</Icon>}>
                        Create
                      </Button>
                    </Link>
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-sm">
                  <Table
                    loading_rows = {5}
                    headers={this.getApiKeyHeaders()}
                    rows={this.state.api_key_rows}
                    selectable={isAdmin()}
                    onRowSelected={this.openApiKey.bind(this)}
                    empty_rows_text="There are no API keys"
                  />
                </div>
              </div>
              
            </div>
          }
          
          { hasType("partner") &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Webhooks</label>
                  </div>
                </div>
              </div>

              { isAdmin() &&
                <div className="row">
                  <div className="col-sm">
                    <Link className="link-button-wrapper" to={`/account/webhooks/create` + this.props.location.search}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Icon>add</Icon>}>
                        Create
                      </Button>
                    </Link>
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-sm">
                  <Table
                    loading_rows = {5}
                    headers={this.getWebhookHeaders()}
                    rows={this.state.webhook_rows}
                    selectable={isAdmin()}
                    onRowSelected={this.openWebhook.bind(this)}
                    empty_rows_text="There are no Webhooks"
                  />
                </div>
              </div>
              
            </div>
          }
          
        </div>
      </div >
    );
  }
}

export default Account;

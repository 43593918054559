import React from 'react';
import ErrorHandler from '../../../util/error_handler';
import { tempLogin } from '../../../services/authentication_service';

class TokenLogin extends React.Component {

  constructor(props) {
    super(props);
    this.tempLogin = this.tempLogin.bind(this);
  }

  componentWillMount() {
    this.tempLogin(this.props.token);
  }

  tempLogin(token) {
    tempLogin({ token: token }).then((res) => {
      this.props.redirect();
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  render() {
    return (
      <div>

        <span>One moment please..</span> 

      </div >
    );
  }
}

export default TokenLogin;

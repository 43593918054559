import React from 'react';
import Alert from '@material-ui/lab/Alert';

class ReviewAmendment extends React.Component {

  getText() {
    if(this.props.transaction.states.some(s => s.status === "buyer_review_amendment_request")) {
      return "Waiting for Buyer to accept or reject the requested amendment."
    }
    if(this.props.transaction.states.some(s => s.status === "seller_review_amendment_request")) {
      return "Waiting for Seller to accept or reject the requested amendment."
    }
    if(this.props.transaction.states.some(s => s.status === "escrow_review_amendment_request")) {
      return "Waiting for Escrow to accept or reject the requested amendment."
    }
    return "Waiting for review of requested amendment."
  }
  
  render() {
    return (
      <div>

        { this.props.transaction.messages && this.props.transaction.messages.length > 0 &&
          <div className="row action-required-row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">An amendment of the transaction was requested by {this.props.transaction.messages[0].message_by.registered_name}. Request: {this.props.transaction.messages[0].message}</Alert>
            </div>
          </div>
        }

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Review amendment request</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>{this.getText()}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewAmendment;

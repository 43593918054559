import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import Converter from "../util/converter";
import moment from 'moment'

class MonthPicker extends React.Component {
  
  constructor(props) {
    super(props)
    this.openDateSelector = this.openDateSelector.bind(this);
    this.dateSelected = this.dateSelected.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);

    var selected_date = moment().startOf('month').toDate();
    if(this.props.default_date) {
      selected_date = this.props.default_date;
    }

    this.state = {
      selected_date: selected_date,
      open: false
    };

    this.props.onDateSelected(this.state.selected_date);
  }

  openDateSelector() {
    this.setState({ open: !this.state.open});
  }

  dateSelected(value) {
    this.setState({ selected_date: value, open: false }, function() {
      this.props.onDateSelected(this.state.selected_date);
    });
  }

  prev() {
    this.setState({ selected_date: moment(this.state.selected_date).subtract(1, 'months').toDate() }, function() {
      this.props.onDateSelected(this.state.selected_date);
    });
  }

  next() {
    this.setState({ selected_date: moment(this.state.selected_date).add(1, 'months').toDate() }, function() {
      this.props.onDateSelected(this.state.selected_date);
    });
  }

  render() {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifycontent="center"
        alignItems="center" >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.prev}
            startIcon={<Icon>arrow_back_ios</Icon>}>
            Prev
          </Button>
        </Grid>
        <Grid item>
          <Button
            className="react-datepicker-button"
            variant="outlined"
            color="primary"
            onClick={this.openDateSelector}
            startIcon={<Icon>today</Icon>}>
            {Converter.toMonth(this.state.selected_date)}
          </Button>
          {this.state.open && (
            <DatePicker
              inline
              selected={this.state.selected_date}
              onChange={this.dateSelected}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
            />
          )}
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.next}
            endIcon={<Icon>arrow_forward_ios</Icon>}>
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default MonthPicker;

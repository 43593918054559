import React from 'react';

class ReviewFee extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Confirm details</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>The Carrier is reviewing the transaction details.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ReviewFee;

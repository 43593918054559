import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Converter from '../../util/converter'
import Mapper from '../../util/mapper'
import MonthPicker from '../../components/month_picker'
import ErrorHandler from '../../util/error_handler';
import moment from 'moment'
import { getTradeTransactionsStatistics, getTradeTransactionsPaymentsStatistics } from '../../services/trade_transactions_service'
import { hasType } from '../../services/authentication_service';
import Table from "../../components/table";

import "react-datepicker/dist/react-datepicker.css";

class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let start_date = params.get('start_date') ? params.get('start_date') : moment().startOf('month').toDate();

    this.state = {
      start_date: start_date,
      end_date: undefined,
      statistics_loaded: false,
      statistics: {},
      pending_rows: undefined,
      completed_rows: undefined,
      payments_statistics: undefined,
    }
  }

  componentDidMount() {
    if(hasType("escrow") || hasType("admin")) {
      this.getTradeTransactionsPaymentsStatistics();
    }
  }

  getTradeTransactionsPaymentsStatistics() {
    getTradeTransactionsPaymentsStatistics().then((payments_statistics) => {
            var rows = [];
      payments_statistics.payments_statistics.map(payments_statistic => {
        rows.push({
          currency: Mapper.fromCurrency(payments_statistic.currency),
          in_escrow: Converter.toCurrency(payments_statistic.currency, payments_statistic.in_escrow)
        });
      });
      this.setState({ payments_statistics: rows });
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  setMonthSelection(value) {
    this.setState({ 
      statistics_loaded: false,
      start_date: moment(value).startOf('month').toDate(),
      end_date: moment(value).endOf('month').toDate()
    }, function() {
      this.getTradeTransactionsStatistics();
    });
  }

  getTradeTransactionsStatistics(e) {
    if(e) {
      e.preventDefault();
    }
    
    this.updateSearchParams();
    this.setState({ completed_rows: undefined, pending_rows: undefined });
    
    getTradeTransactionsStatistics(this.state.start_date, this.state.end_date).then((statistics) => {
      if(this.state.start_date.getTime() === new Date(statistics.start_date).getTime()
          && this.state.end_date.getTime() === new Date(statistics.end_date).getTime()) {
        this.setState({ statistics: statistics, statistics_loaded: true }, function() {
          this.setCompletedTransactionsRow();
          this.setPendingTransactionsRow();
        });
      }
    }).catch((error) => {
      ErrorHandler.showError(error);
    });
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      start_date: moment(this.state.start_date).format()
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }

  getInEscrowHeaders() {
    return [
      { name: "Currency", key: "currency", weight: 2 },
      { name: "Amount", key: "in_escrow", weight: 3 },
    ];
  }


  getHeaders() {
    if(hasType("escrow")) {
      return [
        { name: "Currency", key: "currency", weight: 2 },
        { name: "Transactions", key: "transaction", weight: 2 },
        { name: "Total transaction amount", key: "total_transaction_amount", weight: 3 },
        { name: "In escrow", key: "in_escrow", weight: 3 },
        { name: "Escrow fee", key: "escrow_fee", weight: 3 }
      ];
    } else {
      return [
        { name: "Currency", key: "currency", weight: 2 },
        { name: "Transactions", key: "transaction", weight: 2 },
        { name: "Total transaction amount", key: "total_transaction_amount", weight: 3 },
        { name: "In escrow", key: "in_escrow", weight: 3 },
        { name: "Escrow fee", key: "escrow_fee", weight: 3 },
        { name: "Partner fee", key: "partner_fee", weight: 3 }
      ];
    }
  }

  setCompletedTransactionsRow() {
    var rows = [];
    Object.keys(Mapper.currencies()).map(currency => {
      rows.push(this.createRow(currency, this.state.statistics.completed_transactions));
    });
    this.setState({ completed_rows: rows });
  }

  setPendingTransactionsRow() {
    var rows = [];
    Object.keys(Mapper.currencies()).map(currency => {
      rows.push(this.createRow(currency, this.state.statistics.pending_transactions));
    });
    this.setState({ pending_rows: rows });
  }

  createRow(currency, transactions) {
    if(!transactions) {
      return <tr key={Math.random()}/>;
    }
  
    var transactionsOfCurrency = transactions.filter(t => t.currency === currency);
    var numOfTransactions = 0;
    var totalAmount = 0;
    var totalInEscrow = 0;
    var totalEscrowFee = 0;
    var totalPartnerFee = 0;

    transactionsOfCurrency.map(transaction => {
      numOfTransactions += 1;
      totalAmount += transaction.amount;
      totalInEscrow += transaction.in_escrow;
      totalEscrowFee += transaction.escrow_fee;
      totalPartnerFee += transaction.partner_fee;
    });

    return {
      currency: Mapper.fromCurrency(currency),
      transaction: numOfTransactions,
      total_transaction_amount: Converter.toCurrency(currency, totalAmount),
      in_escrow: Converter.toCurrency(currency, totalInEscrow),
      escrow_fee: Converter.toCurrency(currency, totalEscrowFee),
      partner_fee: Converter.toCurrency(currency, totalPartnerFee)
    };
  }

  render() {
    return (
      <div>
                
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Dashboard</BreadcrumbItem>
          </Breadcrumb>

          { (hasType("escrow") || hasType("admin")) &&

            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Amount in escrow</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <Table
                    loading_rows = {2}
                    headers={this.getInEscrowHeaders()}
                    rows={this.state.payments_statistics}
                  />
                </div>
              </div>
            </div>
            
          }

          <div className="container-body">
            <div className="row">
              <div className="col-sm">
                <MonthPicker
                default_date={this.state.start_date}
                onDateSelected={this.setMonthSelection.bind(this)}
                />
              </div>
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Pending transactions</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {2}
                  headers={this.getHeaders()}
                  rows={this.state.pending_rows}
                />
              </div>
            </div>
          </div>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Completed transactions</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {2}
                  headers={this.getHeaders()}
                  rows={this.state.completed_rows}
                />
              </div>
            </div>
          </div>

        </div>
      </div >
    );
  }
}

export default Dashboard;

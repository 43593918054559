import React from 'react';
import Button from '@material-ui/core/Button';
import Mapper from '../../../util/mapper';
import Converter from '../../../util/converter';
import alertDialog from '../../../components/alert';
import { getDocument } from '../../../services/documents_service';
import fileDownload from 'js-file-download';

class TransportTab extends React.Component {

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  findMilestone(type) {
    return this.props.transaction.transport_milestones.find(m => m.type === type);
  }

  getType(type) {
    var rows = [];
    var milestone = this.findMilestone(type);
    
    if(!milestone || milestone.required_documents.length === 0) {
      rows.push(
        <tr key="header">
          <td>{Mapper.fromTransportMilestoneTypes(type)}</td>
          <td colSpan={2}><i>There are no required documents</i></td>
        </tr>
      );
    } else {
      var that = this;
      milestone.required_documents.forEach(function (required_document, i) {
        rows.push(
          <tr key="header" className={i == 0 ? "" : "row-no-top-border"}>
            <td>{i == 0 ? Mapper.fromTransportMilestoneTypes(type) : ""}</td>
            <td>{that.getDocumentType(required_document)}</td>
            <td>{that.getDocumentFiles(required_document.documents)}</td>
          </tr>
        );
      });
    }

    return rows;
  }

  getDocumentType(required_document) {
    if(required_document.type === "other") {
      return required_document.other_name;
    }
    return Mapper.fromDocumentType(required_document.type);
  }

  getDocumentFiles(documents) {
    var docs = [];

    if(documents.length === 0) {
      return (
        <i>Document not available yet</i>
      );
    }
    
    for(const document of documents) {
      docs.push(
        <div className="inline-download-document">
          <span className="icon-span"><i className="far fa-file"/></span>
          <Button
            className="download-document-button"
            color="primary"
            disabled={this.props.read_only}
            onClick={this.downloadDocument.bind(this, document.id, document.file_name)} >
            {document.file_name}
          </Button>
        </div>
      );
    }

    return docs;
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <table className="table table-details" >
            <tbody>
              <tr className="row-no-top-border-and-padding">
                <td className="header-text">Status</td>
                <td><span className={"badge " + Mapper.toTransportStatusBadge(this.props.transaction.transport_status)} >{Mapper.fromTransportStatus(this.props.transaction.transport_status)}</span></td>
              </tr>
              <tr>
                <td className="header-text">ETA of delivery</td>
                <td>{Converter.toDate(this.props.transaction.eta_delivery)}</td>
              </tr>
              <tr key="header" className="row-no-top-border">
                <td className="header-text">Milestone</td>
                <td className="header-text">Documents</td>
              </tr>
              {this.getType("at_departure")}
              {this.getType("at_ship_loading")}
              {this.getType("at_arrival")}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TransportTab;

import React from 'react';
import ParentTradeTransaction from '../../../shared/pages/freight-transactions/show';
import ActionRenderer from './actions/action_renderer';
import TabRenderer from './tabs/tab_renderer';

class TradeTransaction extends ParentTradeTransaction {

  getTabRenderer(transaction, updateTab) {
    return <TabRenderer transaction={transaction} updateTab={updateTab} />
  }

  getActionRenderer(transaction, updateTransaction) {
    return <ActionRenderer transaction={transaction} updateTransaction={updateTransaction} />
  }
}

export default TradeTransaction;

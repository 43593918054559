import React from 'react';
import TextField from '@material-ui/core/TextField';
import alertDialog from '../../../../shared/components/alert';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { getDocument } from '../../../../shared/services/documents_service';
import { downloadComplianceDocumentAttachments, signComplianceDocument } from '../../../../shared/services/compliances_service';
import { getUserId, isReadOnly } from '../../../../shared/services/authentication_service';
import fileDownload from 'js-file-download';
import Signature from '../../../../shared/components/signature'
import confirm from '../../../../shared/components/confirm'
import ErrorHandler from '../../../../shared/util/error_handler';
import Mapper from '../../../../shared/util/mapper'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';
import Converter from '../../../../shared/util/converter'

class ComplianceSign extends React.Component {

  constructor(props) {
    super(props)

    this.signatureCompleted = this.signatureCompleted.bind(this);

    this.state = {
      director: this.getDirector(),
      compliance_document: this.props.compliance_document,
      compliance_document_url: undefined,
      compliance_signature: {}
    };
  }

  getDirector() {
    var userId = getUserId();
    return this.props.compliance_document.authorised_users.find(c => c.type === "director" && c.user_account_id === userId);
  }

  componentDidMount() {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
    this.getDocument();
  }

  getDocument() {
    getDocument(this.state.compliance_document.document.id).then((document) => {
      document = document.slice(0, document.size, "application/pdf")
      const fileURL = URL.createObjectURL(document);
      this.setState({ compliance_document_url: fileURL });      
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  downloadAttachments(e) {
    var file_name = this.state.compliance_document.document.file_name.replace(".pdf", "") + " - attachments.zip"
    var form = e.currentTarget;
    DomUtil.disableClass(form);

    downloadComplianceDocumentAttachments(this.state.compliance_document.id).then(blob => {
      fileDownload(blob, file_name);
      DomUtil.enableClass(form);
    }).catch(error => {
      DomUtil.enableClass(form);
      alertDialog("Alert", "Failed to download attachments.")
    });
  }

  signComplianceDocument(e) {
    e.preventDefault();
    var form = e.currentTarget;
    if(!this.state.compliance_signature.signature) {
      alertDialog("Alert", "Signature is required");
      return;
    }
    confirm("Please confirm", "Are you sure you want to sign this KYC document?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var compliance_signature = Mapper.toComplianceSignature(this.state.compliance_signature, this.state.agree_terms);
      signComplianceDocument(this.state.compliance_document.id, compliance_signature).then(() => {
        this.props.complianceDocumentSigned(this.state.compliance_document);
      }).catch(error => {
        DomUtil.enableFormSubmitButtonClass(form);
        ErrorHandler.showError(error);
      });
    });
  }

  changeComplianceDocument(e) {
    e.preventDefault();
    confirm("Please confirm", "Are you sure you want to change the KYC document?").then(() => {
      this.props.changeComplianceDocument();
    });
  }
  
  signatureCompleted(signature) {
    this.state.compliance_signature.signature = signature;
    this.setState({ compliance_signature: this.state.compliance_signature });
  }

  openTerms(e) {
    e.preventDefault();
    var url = `/document/terms-and-conditions?file_name=General-Terms-and-Conditions.pdf`;
    window.open(url, "_blank");
  }

  handleCheckboxChange(e) {
    this.setState({ agree_terms: e.target.checked });
  }

  render() {    
    return (
      <div>

        <div className="container-body">
          
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Compliance</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td className="header-text">Status</td>
                    <td><span className={"badge " + Mapper.toComplianceDocumentStatusBadge(this.state.compliance_document.status)} >{Mapper.fromComplianceDocumentStatus(this.state.compliance_document.status)}</span></td>
                  </tr>
                  <tr>
                    <td className="header-text">Signed at</td>
                    <td>{Converter.toDatetime(this.state.compliance_document.signed_at)}</td>
                  </tr>
                  <tr>
                    <td className="header-text">Approved at</td>
                    <td>{Converter.toDatetime(this.state.compliance_document.approved_at)}</td>
                  </tr>
                  <tr>
                    <td className="header-text">Expires at</td>
                    <td>{Converter.toDatetime(this.state.compliance_document.expires_at)}</td>
                  </tr>
                  <tr>
                    <td className="header-text">Compliance document</td>
                    <td>
                      <div>
                        <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                        <Button
                          className="download-document-button"
                          color="primary"
                          onClick={this.downloadDocument.bind(this, this.state.compliance_document.document.id, this.state.compliance_document.document.file_name)} >
                          {this.state.compliance_document.document.file_name}
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="header-text">Attachments</td>
                    <td>
                      <AsyncButton
                        variant="outlined"
                        color="primary"
                        disabled={this.props.read_only}
                        onClick={this.downloadAttachments.bind(this)}
                        icon="get_app"
                        text="Download" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Sign KYC document</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please review the draft KYC document below, if anything needs to be changed please update accordingly.</span>
              <br/>
              <span>If everything is in order, and you agree with the decleration, please sign the document.</span>
              <br/>
              <span>After the document has been signed it will be send to the Escrow Agent for review.</span>
            </div>
          </div>


          <form autoComplete="off" onSubmit={this.signComplianceDocument.bind(this)}>
            { this.state.director &&
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      label="Signed by"
                      variant="outlined"
                      disabled
                      fullWidth
                      margin="dense"
                      name="completed_by"
                      value={this.state.director.full_name}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      label="Designation"
                      variant="outlined"
                      disabled
                      fullWidth
                      margin="dense"
                      value={this.state.director.position}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <span className="button-explanation">Please draw your signature in the box below</span>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6">
                    <Signature signatureCompleted={this.signatureCompleted} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <Checkbox
                      required
                      color="primary"
                      name="terms"
                      onChange={this.handleCheckboxChange.bind(this)} 
                    />
                    <span>I have read and agree to the&nbsp;</span>
                    <Link
                      className="inline-link"
                      component="button"
                      variant="body2"
                      type="button"
                      onClick={this.openTerms.bind(this)}>
                        terms and conditions
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Sign" />
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      onClick={this.changeComplianceDocument.bind(this)}
                      icon="edit"
                      text="Change" />
                  </div>
                </div>
              </div>
            }
            
            { !this.state.director &&
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <Alert severity="error">You don't have the permission to sign this document. It can only be signed by a director or key executive. The user account of a director / key executive can be linked to the to the respective director / key executive in the KYC form.</Alert>              
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled
                      startIcon={<Icon>check</Icon>}>
                      Sign
                    </Button>
                    { !isReadOnly() &&
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        onClick={this.changeComplianceDocument.bind(this)}
                        icon="edit"
                        text="Change" />
                    }
                    { isReadOnly() &&
                      <Button
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        disabled
                        startIcon={<Icon>edit</Icon>}>
                        Change
                      </Button>
                    }
                  </div>
                </div>
              </div>
            }

          </form>
          
          <br/>

          <div className="row">
            <div className="col-md">
              <iframe className="pdf-viewer" src={this.state.compliance_document_url} type="application/pdf" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComplianceSign;

import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import DomUtil from '../../util/dom_util';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { createInvite } from '../../services/accounts_service';
import { getCountries } from '../../services/countries_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper';
import { hasType } from '../../services/authentication_service';

class CreateInvite extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invite: { 
        type: "customer"
      },
      countries: []
    };
  }

  componentDidMount() {
    this.getCountries();
  }


  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  handleChange(e) {
    this.state.invite[e.target.name] = e.target.value;
    if(e.target.name === "type" && e.target.value !== "partner") {
      this.state.invite.partner_percentage = undefined;
      this.state.invite.partner_territory = undefined;
    }
    this.setState({ invite: this.state.invite });
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.invite.partner_territory = newValue.map(v => v.code);      
    } else {
      this.state.invite.partner_territory = [];
    }
    this.setState({ invite: this.state.invite });
  }

  createInvite(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var invite_creation = Mapper.toInviteCreation(this.state.invite);
    createInvite(invite_creation).then(() => {
      this.props.history.push(this.props.return_url + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={this.props.return_url + this.props.location.search}>{this.props.return_name}</Link></BreadcrumbItem>
          <BreadcrumbItem active>New invite</BreadcrumbItem>
        </Breadcrumb>
        
        <div className="container-body">
          
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>New invite</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.createInvite.bind(this)}>

                  { hasType("admin") &&
                    <div className="row">
                      <div className="col-md-4">
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          fullWidth>
                          <InputLabel shrink={!this.isEmpty(this.state.invite.type) } >Account type *</InputLabel>
                          <Select
                            native
                            required
                            label="Account type *"
                            value={this.state.invite.type}
                            name="type"
                            onChange={this.handleChange.bind(this)} >
                              <option key={"type_empty"} value=""></option>
                              {Object.keys(Mapper.invatationTypes()).map(type => {
                                return (<option key={"type_"+type} value={type}>{Mapper.fromInvatationType(type)}</option>);
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  }
                  
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        label="Company email"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="email"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  { hasType("admin") && this.state.invite.type === "partner" &&
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          label="Partner percentage"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          type="number"
                          value={this.state.partner_percentage}
                          name="partner_percentage"
                          onChange={this.handleChange.bind(this)}
                          InputProps={{
                            inputProps: { min: 0, max: 100, step: 0.1 },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </div>
                    </div>
                  }

                  { hasType("admin") && this.state.invite.type === "partner" &&
                    <div className="row">
                      <div className="col-md-4">
                        <Autocomplete
                          multiple
                          options={this.state.countries}
                          getOptionLabel={(option) => option.name}
                          filterSelectedOptions
                          onChange={this.handleAutoCompleteChange.bind(this)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              margin="dense"
                              variant="outlined"
                              label="Territory"
                              placeholder="Countries"
                            />
                          )}
                        />
                      </div>
                    </div>
                  }
                  
                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Invite" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default CreateInvite;

import React from 'react';

class DomUtil extends React.Component {

  static findFormSubmitButton(form) {
    if(form.nodeName === "FORM" && form.hasChildNodes()) {
      var lastRow = form.childNodes[form.childNodes.length - 1];
      if(lastRow.nodeName === "DIV" && lastRow.hasChildNodes()) {
        var firstCol = lastRow.childNodes[0];
        if(firstCol.nodeName === "DIV" && firstCol.hasChildNodes()) {
          for(var mtButton of firstCol.childNodes) {
            if(mtButton.nodeName === "DIV" && mtButton.hasChildNodes()) {
              var button = mtButton.childNodes[0];
              if(button.nodeName === "BUTTON" && button.type === "submit") {
                return button;
              }
            }
          }
        }
      }
    }
  }

  static disableFormSubmitButtonClass(form) {
    var button = this.findFormSubmitButton(form);
    if(button) {
      button.disabled = true;
    }
  }

  static enableFormSubmitButtonClass(form) {
    var button = this.findFormSubmitButton(form);
    if(button) {
      button.disabled = false;
    }
  }

  static disableClass(element) {
    if(element) {
      element.disabled = true;
    }
  }

  static enableClass(element) {
    if(element) {
      element.disabled = false;
    }  
  }
}

export default DomUtil;

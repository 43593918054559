import React from 'react';

class Compliance extends React.Component {

  getText() {
    if(this.props.transaction.states.some(s => s.status === "seller_compliance") && this.props.transaction.states.some(s => s.status === "buyer_compliance")) {
      return "Waiting for Buyer and Seller to complete KYC requirements."
    }
    if(this.props.transaction.states.some(s => s.status === "seller_compliance")) {
      return "Waiting for Seller to complete KYC requirements."
    }
    if(this.props.transaction.states.some(s => s.status === "buyer_compliance")) {
      return "Waiting for Buyer to complete KYC requirements."
    }
    return "Waiting for Buyer and Seller to complete KYC requirements."
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Awaiting KYC submission</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>{this.getText()}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Compliance;

import React from 'react';
import ParentActionRenderer from '../../../../shared/pages/freight-transactions/actions/parent_action_renderer';
import AgreeTransactionAction from '../../../../shared/pages/freight-transactions/actions/agree_transaction_action';
import { isReadOnly } from '../../../../shared/services/authentication_service';
import FreightPayment from './freight_payment';
import ReleaseBrokerFunds from './release_broker_funds';
import ReleaseCarrierFunds from './release_carrier_funds';

class ActionRenderer extends ParentActionRenderer {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    return this.props.updateTransaction(data);
  }

  render() {
    if(!this.props.transaction.states || isReadOnly()) {
      return super.render();
    }

    if(this.props.transaction.states.some(s => s.status === "escrow_agree_transaction" && s.action_allowed)) {
      return <AgreeTransactionAction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="escrow_agree_transaction" type="escrow"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "freight_payment" && s.action_allowed)) {
      return <FreightPayment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "release_broker_funds" && s.action_allowed)) {
      return <ReleaseBrokerFunds transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "release_carrier_funds" && s.action_allowed)) {
      return <ReleaseCarrierFunds transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    return super.render();
  }
}

export default ActionRenderer;

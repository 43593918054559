import React from 'react';
import { Link } from 'react-router-dom';
import ErrorHandler from '../../../../shared/util/error_handler';
import { getLatestComplianceDocument } from '../../../../shared/services/compliances_service';
import ComplianceConfirmation from '../components/compliance_confirmation'

class Compliance extends React.Component {
  constructor(props) {
    super(props)

    this.complianceDocumentConfirmed = this.complianceDocumentConfirmed.bind(this);
    this.complianceDocumentVerified = this.complianceDocumentVerified.bind(this);
    this.complianceDocumentSigned = this.complianceDocumentSigned.bind(this);
    this.changeComplianceDocument = this.changeComplianceDocument.bind(this);
    this.complianceDocumentCreated = this.complianceDocumentCreated.bind(this);
    
    this.state = {
      compliance_document: this.props.compliance_document,
      compliance_document_loaded: false,
      flow_patch: {
        status: this.props.flow_status,
        action: "compliance_confirmed"
      }
    };
  }

  componentDidMount() {
    this.getLatestComplianceDocument();
  }
  
  complianceDocumentConfirmed() {
    return this.props.updateTransaction(this.state.flow_patch);
  }

  getLatestComplianceDocument() {
    getLatestComplianceDocument().then((compliance_document) => {
      this.setState({ compliance_document: compliance_document, compliance_document_loaded: true });
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  updateTransaction(compliance_document) {
    return this.props.updateTransaction({
      status: this.props.flow_status,
      action: "compliance_completed",
      compliance_document_id: compliance_document.id
    });
  }

  complianceDocumentVerified(compliance_document) {
    return this.updateTransaction(compliance_document);
  }

  complianceDocumentSigned(compliance_document) {
    return this.updateTransaction(compliance_document);
  }

  changeComplianceDocument() {
    this.setState({ compliance_document: undefined });
  }

  complianceDocumentCreated(compliance_document) {
    this.setState({ compliance_document: compliance_document });
  }

  isExpired() {
    return new Date(this.state.compliance_document.expires_at) < Date.now();
  }

  getComplianceMessage() {
    if(!this.state.compliance_document_loaded) {
      return <span>Loading compliance..</span>
    }

    if(!this.state.compliance_document) {
      return <span>Your compliance has not been completed yet. Please complete your compliance <Link to={`/compliance/`}>here</Link>.</span>
    }

    if(this.state.compliance_document.status === "rejected") {
      return <span>Your compliance has been rejected.</span>
    }

    if(this.state.compliance_document.status === "draft") {
      return <span>Your compliance has not been completed yet. Please complete your compliance <Link to={`/compliance/`}>here</Link>.</span>
    }

    if(this.state.compliance_document.status === "clarification") {
      return <span>The Escrow Agent asked for clarification on your compliance. Please review and update your compliance where necessary <Link to={`/compliance/`}>here</Link>.</span>
    }

    if(this.state.compliance_document.status === "submitted") {
      return <span>Your compliance has been submitted for review by the Escrow Agent. Once the compliance has been approved you can continue.</span>
    }

    if(this.isExpired()) {
      return <span>Your compliance has expired. Please review and update your compliance where necessary <Link to={`/compliance/`}>here</Link>.</span>
    }

    if(this.state.compliance_document.status === "approved") {
      return <ComplianceConfirmation compliance_document={this.state.compliance_document} complianceDocumentConfirmed={this.complianceDocumentConfirmed} />
    }

    return <span>Unable to determine status of your compliance. Please make sure you have an approved compliance.</span>
  }

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Compliance</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              {this.getComplianceMessage()}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default Compliance;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';

class ShareholderCorporateForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      registered_name: this.props.shareholder.registered_name,
      country_of_incorporation: this.findCountry(this.props.shareholder.country_of_incorporation_code),
      percentage_shareholdings: this.props.shareholder.percentage_shareholdings
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(that.props.index, key, that.state[key], true);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(this.props.index, e.target.name, value);
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.country_of_incorporation = newValue;
    } else {
      this.state.country_of_incorporation = { };
    }
    this.setState({ country_of_incorporation: this.state.country_of_incorporation });
    this.props.propertyUpdate(this.props.index, "country_of_incorporation", this.state.country_of_incorporation);
  }

  findCountry(code) {
    if(!code) {
      return;
    }
    return this.props.countries.find(c => c.code === code);
  }

  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Registered name"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.registered_name}
              name="registered_name"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.registered_name !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <Autocomplete
              key="country_of_incorporation"
              options={this.props.countries}
              groupBy={this.firstLetter.bind(this)}
              getOptionLabel={(option) => option.name}
              value={this.state.country_of_incorporation}
              onChange={this.handleAutoCompleteChange.bind(this)}
              renderInput={(params) => 
                <TextField {...params}
                  label="Country of incorporation"
                  variant="outlined"
                  fullWidth
                  required
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              }
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Percentage shareholdings"
              variant="outlined"
              required
              type="number"
              fullWidth
              margin="dense"
              value={this.state.percentage_shareholdings}
              name="percentage_shareholdings"
              onChange={this.handleChange.bind(this)}
              InputProps={{
                inputProps: { min: 0, max: 100 },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: this.state.percentage_shareholdings !== undefined,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ShareholderCorporateForm;

import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import confirm from '../../../../shared/components/confirm'
import Mapper from '../../../../shared/util/mapper';
import DocumentUpload from '../../../../shared/components/document_upload';

class DirectorsForm extends React.Component {

  constructor(props) {
    super(props)
    
    var director_key_executives = this.props.compliance.director_key_executives;
    if(director_key_executives) {
      for(const director_key_executive of director_key_executives) {
        director_key_executive.key = this.getUniqueKey();
        if(director_key_executive.country_residency_code) {
          director_key_executive.country_residency = this.findCountry(director_key_executive.country_residency_code);
        }
        if(director_key_executive.user_account_id) {
          director_key_executive.user_account = this.findUser(director_key_executive.user_account_id);
        }
        if(!director_key_executive.passport) {
          director_key_executive.passport = {};
        }
        if(!director_key_executive.residential_address_document) {
          director_key_executive.residential_address_document = {};
        }
        if(director_key_executive.user_account) {
          director_key_executive.has_user_account = true;
        } else if (director_key_executive.full_name) {
          director_key_executive.has_user_account = false;
        }
      }
    } else {
      director_key_executives = [];
    }

    this.state = {
      director_key_executives: director_key_executives
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key], true);
    });
  }
  
  addToList() {
    this.state.director_key_executives.push({ key: this.getUniqueKey(), passport: {}, residential_address_document: {} });
    this.setState({ director_key_executives: this.state.director_key_executives });
    this.props.propertyUpdate("director_key_executives", this.state.director_key_executives);
  }

  removeFromList(index) {
    confirm("Please confirm", "Are you sure you want to remove this entry?").then(() => {
      this.state.director_key_executives.splice(index, 1);
      this.setState({ director_key_executives: this.state.director_key_executives });
      this.props.propertyUpdate("director_key_executives", this.state.director_key_executives);
    });
  }
  
  handleListChange(index, e) {
    if(e.target.name === "has_user_account") {
      this.state.director_key_executives[index].full_name = undefined;
      this.state.director_key_executives[index].user_account = undefined;
    }
    var value = e.target.value === "" ? null : e.target.value;
    this.state.director_key_executives[index][e.target.name] = value;
    this.setState({ director_key_executives: this.state.director_key_executives });
    this.props.propertyUpdate("director_key_executives", this.state.director_key_executives);
  }

  documentUploaded(id, file_name, parent_data) {
    this.state.director_key_executives[parent_data.index][parent_data.document].id = id;
    this.state.director_key_executives[parent_data.index][parent_data.document].file_name = file_name;
    this.setState({ director_key_executives: this.state.director_key_executives });
    this.props.propertyUpdate("director_key_executives", this.state.director_key_executives);
  }

  documentCleared(parent_data) {
    this.state.director_key_executives[parent_data.index][parent_data.document].id = undefined;
    this.state.director_key_executives[parent_data.index][parent_data.document].file_name = undefined;
    this.setState({ director_key_executives: this.state.director_key_executives });
    this.props.propertyUpdate("director_key_executives", this.state.director_key_executives);
  }

  handleAutoCompleteChange(index, property, e, newValue) {
    if(newValue) {
      this.state.director_key_executives[index][property] = newValue;
    } else {
      this.state.director_key_executives[index][property] = { };
    }
    this.setState({ director_key_executives: this.state.director_key_executives });
    this.props.propertyUpdate("director_key_executives", this.state.director_key_executives);
  }

  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  findCountry(code) {
    if(!code) {
      return;
    }
    return this.props.countries.find(c => c.code === code);
  }

  findUser(userId) {
    if(!userId) {
      return;
    }
    return this.props.users.find(u => u.id === userId);
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  isTrue(value) {
    return value === "true" || value === true;
  }

  isFalse(value) {
    return value === "false" || value === false;
  }

  render() {
    return (
      <div>

        <div className="row">
          <div className="col-md-12">
            <span>The KYC needs to be signed by the director or a key executive, so there should be at least one director or key executive with a user account.</span>
          </div>
        </div>

        {this.state.director_key_executives.map((director_key_executive, index) => {
          return (
            <div key={director_key_executive.key} className="list-row-item">
              <span className="list-row-item-header-text">Director / Key executive</span>

              <div>
                <div className="col-md-12">
                  <Button
                    className="button-icon-only button-inline clear-list-row-item-button"
                    color="secondary"
                    type="button"
                    onClick={this.removeFromList.bind(this, index)}
                    startIcon={<Icon>clear</Icon>} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <span>Does the director or key executive have a user account on the platform?</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel shrink={!this.isEmpty(this.state.director_key_executives[index].has_user_account) } >User account *</InputLabel>
                    <Select
                      native
                      required
                      label="User account *"
                      value={this.state.director_key_executives[index].has_user_account}
                      name="has_user_account"
                      onChange={this.handleListChange.bind(this, index)} >
                        <option key="has_user_account_empty" value=""></option>
                        {Object.keys(Mapper.booleans()).map(boolean => {
                          return (<option key={"has_user_account_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row">
                { this.isTrue(this.state.director_key_executives[index].has_user_account) &&
                  <div className="col-md-4">
                    <Autocomplete
                      key="user_account"
                      options={this.props.users}
                      getOptionLabel={(option) => option.full_name}
                      value={this.state.director_key_executives[index].user_account}
                      onChange={this.handleAutoCompleteChange.bind(this, index, "user_account")}
                      renderInput={(params) => 
                        <TextField {...params}
                          label="Full name"
                          variant="outlined"
                          fullWidth
                          required
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />                      
                      }
                    />
                  </div>
                }
                { this.isFalse(this.state.director_key_executives[index].has_user_account) &&
                  <div className="col-md-4">
                    <TextField
                      label="Full name"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      value={this.state.director_key_executives[index].full_name}
                      name="full_name"
                      onChange={this.handleListChange.bind(this, index)}
                      InputLabelProps={{
                        shrink: this.state.director_key_executives[index].full_name !== undefined,
                      }}
                    />
                  </div>
                }
                { this.isEmpty(this.state.director_key_executives[index].has_user_account) &&
                  <div className="col-md-4">
                    <TextField
                      label="Full name"
                      variant="outlined"
                      disabled
                      required
                      fullWidth
                      margin="dense"
                    />
                  </div>
                }
                <div className="col-md-4">
                  <TextField
                    label="Passport No."
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.director_key_executives[index].passport_number}
                    name="passport_number"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: this.state.director_key_executives[index].passport_number !== undefined,
                    }}
                  />
                </div>
                <div className="col-md-4">
                    <DocumentUpload 
                      title="Passport"
                      access="escrow_only"
                      allowed_account_ids={[]}
                      allow_authorised_accounts={false}
                      documentUploaded={this.documentUploaded.bind(this)}
                      documentCleared={this.documentCleared.bind(this)}
                      document_id={this.state.director_key_executives[index].passport.id}
                      file_name={this.state.director_key_executives[index].passport.file_name}
                      parent_data={{ index: index, document: "passport" }}  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <TextField
                    label="Nationality"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.director_key_executives[index].nationality}
                    name="nationality"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: this.state.director_key_executives[index].nationality !== undefined,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <Autocomplete
                    key="country_residency"
                    options={this.props.countries}
                    groupBy={this.firstLetter.bind(this)}
                    getOptionLabel={(option) => option.name}
                    value={this.state.director_key_executives[index].country_residency}
                    onChange={this.handleAutoCompleteChange.bind(this, index, "country_residency")}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Country of Residency"
                        variant="outlined"
                        fullWidth
                        required
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    }
                  />
                </div>
                <div className="col-md-4">
                    <DocumentUpload 
                      title="Proof residential address"
                      access="escrow_only"
                      allowed_account_ids={[]}
                      allow_authorised_accounts={false}
                      documentUploaded={this.documentUploaded.bind(this)}
                      documentCleared={this.documentCleared.bind(this)}
                      document_id={this.state.director_key_executives[index].residential_address_document.id}
                      file_name={this.state.director_key_executives[index].residential_address_document.file_name}
                      parent_data={{ index: index, document: "residential_address_document" }}  />
                </div>
              </div>              
              
              <div className="row">
                <div className="col-md-4">
                  <TextField
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.director_key_executives[index].date_of_birth}
                    name="date_of_birth"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel shrink={!this.isEmpty(this.state.director_key_executives[index].gender) } >Gender *</InputLabel>
                    <Select
                      native
                      required
                      label="Gender *"
                      value={this.state.director_key_executives[index].gender}
                      name="gender"
                      onChange={this.handleListChange.bind(this, index)} >
                        <option key={"director_key_executives_"+index+"empty"} value=""></option>
                        {Object.keys(Mapper.genders()).map(gender => {
                          return (<option key={"director_key_executives_"+index+gender} value={gender}>{Mapper.fromGender(gender)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <TextField
                    label="Position"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.director_key_executives[index].position}
                    name="position"
                    onChange={this.handleListChange.bind(this, index)}
                    InputLabelProps={{
                      shrink: this.state.director_key_executives[index].position !== undefined,
                    }}
                  />
                </div>
              </div>

            </div>
          );
        })}

        <div className="row">
          <div className="col-md-12">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addToList.bind(this)}
              startIcon={<Icon>add</Icon>}>
              Add Director / Key executive
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DirectorsForm;

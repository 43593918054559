import React from 'react';
import { getAccountIds, setAccountId, logout } from '../../shared/services/authentication_service';
import { getTradeTransactionCount } from '../../shared/services/trade_transactions_service';
import { getFreightTransactionCount } from '../../shared/services/freight_transactions_service';
import { getInvoiceCount } from '../../shared/services/invoices_service';
import EventBus from '../../shared/components/event_bus';
import ParentMenu from '../../shared/components/parent_menu';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      trade_transaction_count: {},
      freight_transaction_count: {},
      invoice_count: {},
    }
  }

  componentDidMount() {
    this.getTradeTransactionCount();
    this.getFreightTransactionCount();
    this.getInvoiceCount()
    EventBus.on("updateTradeTransaction", (data) =>
      this.getTradeTransactionCount()
    );
    EventBus.on("updateFreightTransaction", (data) =>
      this.getFreightTransactionCount()
    );
    EventBus.on("updateInvoice", (data) =>
      this.getInvoiceCount()
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.getTradeTransactionCount();
    this.getFreightTransactionCount();
  }

  getTradeTransactionCount() {
    getTradeTransactionCount().then((trade_transaction_count) => {
      if (trade_transaction_count) {
        this.setState({ trade_transaction_count: trade_transaction_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getFreightTransactionCount() {
    getFreightTransactionCount().then((freight_transaction_count) => {
      if (freight_transaction_count) {
        this.setState({ freight_transaction_count: freight_transaction_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getInvoiceCount() {
    getInvoiceCount().then((invoice_count) => {
      if (invoice_count) {
        this.setState({ invoice_count: invoice_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }
  
  selectAccount() {
    setAccountId(null);
    this.props.history.push("/");
  }

  getTopbarItems() {
    var items = [];
    
    if(getAccountIds().length > 1) {
      items.push({
        name: "Change account",
        icon: "fas fa-random",
        link: "select-account",
        onClick: () => this.selectAccount()
      });
    }

    items.push({
      name: "Settings",
      icon: "fas fa-cog",
      link: "settings"
    });

    items.push({
      name: "Sign out",
      icon: "fas fa-sign-out-alt",
      onClick: () => logout()
    });

    return items;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={this.getTopbarItems()}
      sidebar_items={[
        {
          title: "Admin",
          items: [
            {
              name: "Dashboard",
              icon: "fas fa-chart-line",
              link: "dashboard"
            },
            {
              name: "Transactions",
              icon: "fas fa-lock",
              link: "transactions/trade",
              sub_items: [
                {
                  name: "Trade",
                  link: "transactions/trade",
                  counter: this.state.trade_transaction_count.actions_allowed
                },
                {
                  name: "Freight",
                  link: "transactions/freight",
                  counter: this.state.freight_transaction_count.actions_allowed
                }
              ]
            },
            {
              name: "Accounts",
              icon: "fas fa-user-friends",
              link: "accounts"
            },
            {
              name: "Compliances",
              icon: "fas fa-user-shield",
              link: "compliances"
            },
            {
              name: "All invitations",
              icon: "fas fa-envelope",
              link: "all_invites"
            },
            {
              name: "All invoices",
              icon: "fas fa-copy",
              link: "all_invoices"
            },
            {
              name: "Calculator",
              icon: "fas fa-calculator",
              link: "calculator"
            }
          ]
        },
        {
          title: "Company",
          items: [
            {
              name: "Account",
              icon: "fas fa-building",
              link: "account"
            },
            {
              name: "Invitations",
              icon: "fas fa-paper-plane",
              link: "invites"
            },
            {
              name: "Invoices",
              icon: "fas fa-file-alt",
              link: "invoices",
              counter: this.state.invoice_count.open
            }
          ]
        }
      ]}
    />;
  }
}

export default Menu;

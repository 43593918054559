import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getInvoice } from '../../../shared/services/invoices_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import StringUtil from '../../../shared/util/string_util';
import Converter from '../../../shared/util/converter';
import LoadingText from '../../../shared/components/loading_text';

class Invoice extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invoice_id: this.props.match.params.invoice_id,
      invoice: undefined
    };
  }

  componentDidMount() {
    this.getInvoice();
  }

  getInvoice() {
    getInvoice(this.state.invoice_id).then((invoice) => {
      if (invoice) {
        this.setState({ invoice: invoice, invoice_loaded: true });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getStatus(invoice) {
    if(invoice.status === "open" &&
        new Date(invoice.due_date) < new Date()) {
      return "overdue";
    }
    return invoice.status;
  }

  openDocument(document, e) {
    e.preventDefault();
    var url = `/document/` + document.id + "?file_name=" + document.file_name;
    window.open(url, "_blank");
  }

  getService(invoice) {
    if(invoice.type === "trade_transaction" || invoice.type === "freight_transaction") {
      return Mapper.fromInvoiceType(invoice.type) + " " + StringUtil.shortId(invoice.resource_id);
    }
    if(invoice.type === "periodic_trade_transaction_settlement" ||
        invoice.type === "periodic_partner_trade_transaction_settlement" ||
        invoice.type === "periodic_freight_transaction_settlement" ||
        invoice.type === "periodic_partner_freight_transaction_settlement") {
      return Mapper.fromInvoiceType(invoice.type) + " " + invoice.resource_id + " (" + Mapper.fromCurrencySymbol(invoice.currency) + ")";
    }
    return "-";
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={`/all_invoices/` + this.props.location.search}>All invoices</Link></BreadcrumbItem>
          <BreadcrumbItem active>{StringUtil.shortId(this.state.invoice_id)}</BreadcrumbItem>
        </Breadcrumb>

        <div className="container-body">
          
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Invoice</label>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-sm-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td style={{width: "230px"}} className="header-text">Invoice #</td>
                    <td><LoadingText text={this.state.invoice ? StringUtil.shortId(this.state.invoice.id) : undefined} /></td>
                  </tr>
                  <tr>
                    <td className="header-text">Status</td>
                    <td><LoadingText text={this.state.invoice ? <span className={"badge " + Mapper.toInvoiceStatusBadge(this.getStatus(this.state.invoice))} >{Mapper.fromInvoiceStatus(this.getStatus(this.state.invoice))}</span> : undefined} /></td>
                  </tr>
                  <tr>
                    <td className="header-text">Due date</td>
                    <td><LoadingText text={this.state.invoice ? Converter.toDate(this.state.invoice.due_date) : undefined} /></td>
                  </tr>
                  <tr>
                    <td className="header-text">Total amount</td>
                    <td><LoadingText text={this.state.invoice ? Converter.toCurrency(this.state.invoice.currency, this.state.invoice.total_amount) : undefined} /></td>
                  </tr>
                  <tr>
                    <td className="header-text">Document</td>
                    <td>
                      {this.state.invoice &&
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<Icon>launch</Icon>}
                          onClick={this.openDocument.bind(this, this.state.invoice.invoice_document)} >
                          Open document
                        </Button>
                      }
                      {!this.state.invoice &&
                        <LoadingText text={undefined} />
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="header-text">Debtor</td>
                    <td><LoadingText text={this.state.invoice ? this.state.invoice.debtor ? this.state.invoice.debtor.registered_name : "-" : undefined} /></td>
                  </tr>
                  <tr>
                    <td className="header-text">Creditor</td>
                    <td><LoadingText text={this.state.invoice ? this.state.invoice.creditor ? this.state.invoice.creditor.registered_name : "-" : undefined} /></td>
                  </tr>
                  <tr>
                    <td className="header-text">Service</td>
                    <td><LoadingText text={this.state.invoice ? this.getService(this.state.invoice) : undefined} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
    );
  }
}

export default Invoice;

import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import confirmText from '../../../components/confirm_text.js'
import alertDialog from '../../../components/alert';
import confirm from '../../../components/confirm.js'
import AsyncButton from '../../../components/async_button';
import DomUtil from '../../../util/dom_util';
import ErrorHandler from '../../../util/error_handler';
import Link from '@material-ui/core/Link';
import { getUserId, isAdmin, isAuthorised } from '../../../services/authentication_service';
import { getLatestComplianceDocument } from '../../../services/compliances_service';

class AgreeTransactionAction extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: {
        status: this.props.flow_status,
        action: "transaction_agreed"
      }
    };
  }

  componentDidMount() {
    this.setAuthorisedUser();
  }

  setAuthorisedUser() {
    if(this.props.type === "buyer" || this.props.type === "seller") {
      this.setComplianceAuthorisedUser();
    } else if(this.props.type === "escrow") {
      this.setSessionAuthorisedUser();
    }
  }

  setSessionAuthorisedUser() {
    var is_authorised = isAdmin() || isAuthorised();
    this.setState({ is_authorised: is_authorised });
  }
  
  setComplianceAuthorisedUser() {
    var userId = getUserId();
    getLatestComplianceDocument().then((compliance_document) => {
      if(compliance_document) {
        var authorised_user = compliance_document.authorised_users.find(c => c.user_account_id === userId);
        if(authorised_user) {
          this.setState({ is_authorised: true });
        } else {
          this.setState({ is_authorised: false });
        }
      } else {
        this.setState({ is_authorised: false });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getParty() {
    if(this.props.type === "buyer") {
      return "Buyer";
    } else if(this.props.type === "seller") {
      return "Seller";
    } else if(this.props.type === "escrow") {
      return "Escrow Agent";
    }
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to accept this escrow agreement?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  requestChangeTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    var message;
    if(this.props.type === "seller") {
      var message = "This will return the transaction to the details phase and allow you to change the transaction details. Are you sure you want to request a change? Please describe what needs to be changed."
    } else {
      var message = "This will return the transaction to the details phase and allow the Seller to change the transaction details. Are you sure you want to request a change? Please describe what needs to be changed."
    }

    confirmText("Please confirm", message, { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({ 
          status: this.props.flow_status,
          action: "transaction_change_requested",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  rejectTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "Are you sure you want to decline this escrow agreement and the transaction? Please state the reason.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({ 
          status: this.props.flow_status,
          action: "rejected",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  openAgreement(e) {
    e.preventDefault();
    var url = `/document/` + this.props.transaction.escrow_agreement.id + "?file_name=" + this.props.transaction.escrow_agreement.file_name;
    window.open(url, "_blank");
  }
  
  render() {
    return (
      <div>

        { this.props.transaction.amended &&
          <div className="row action-required-row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">This is an amended transaction and contans an updated version of the escrow agreement.</Alert>
            </div>
          </div>
        }
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Escrow agreement</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                <div className="row">
                  <div className="col-md">
                    <span>Please review the escrow agreement (available at the link below) carefully before proceeding.</span>
                    <br/>
                    <br/>
                    <span>By clicking on “Accept”, you (1) represent and warrant that you are authorised to accept the terms of the escrow agreement on behalf of {this.getParty()}, and to bind {this.getParty()} to the same; and (2) unconditionally accept the terms of the escrow agreement on behalf of {this.getParty()}.</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <Checkbox
                      required
                      name="checkedB"
                      color="primary"
                    />
                    <span>I have read and agree to the&nbsp;</span>
                    <Link
                      className="inline-link"
                      component="button"
                      variant="body2"
                      type="button"
                      onClick={this.openAgreement.bind(this)}>
                        escrow agreement
                    </Link>
                  </div>
                </div>

                { !this.state.is_authorised &&
                  <div className="row">
                    <div className="col-md-12">
                      <Alert severity="error">You don't have the permission to accept this agreement. It can only be accepted by a director, key executive or authorised representative.</Alert>              
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-md">
                    { this.state.is_authorised &&
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Accept" />
                    }
                    { !this.state.is_authorised &&
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disabled
                        startIcon={<Icon>check</Icon>}>
                        Accept
                      </Button>
                    }
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.requestChangeTransaction.bind(this)}
                      icon="edit"
                      text="Request change" />
                    <AsyncButton
                      className="button-danger-outlined"
                      variant="outlined"
                      color="danger"
                      onClick={this.rejectTransaction.bind(this)}
                      icon="clear"
                      text="Decline" />
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgreeTransactionAction;

import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAccount } from '../../../shared/services/accounts_service';
import Mapper from '../../../shared/util/mapper';
import ErrorHandler from '../../../shared/util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Converter from '../../../shared/util/converter';
import Table from "../../../shared/components/table";
import LoadingText from '../../../shared/components/loading_text';

class Account extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account_id: this.props.match.params.account_id,
      account: {}
    }
  }

  componentDidMount() {
    this.getAccount();
  }

  getAccount() {
    getAccount(this.state.account_id).then((account) => {
      if (account) {
        this.setState({ account: account }, () => {
          this.setUserRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getUserHeaders() {
    return [
      { name: "Email", key: "email" },
      { name: "Full name", key: "full_name" },
      { name: "Role", key: "role" }
    ];
  }

  setUserRows() {
    var rows = [];
    this.state.account.users.map(user => {
      rows.push({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
        role: Mapper.fromUserRole(user.role)
      });
    });
    this.setState({ user_rows: rows });
  }
  
  render() {
    return (
      <div>
          
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/accounts` + this.props.location.search}>Accounts</Link></BreadcrumbItem>
            <BreadcrumbItem active>{StringUtil.shortId(this.state.account_id)}</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Company information</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">ID</td>
                      <td><LoadingText text={StringUtil.shortId(this.state.account.id)} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Account type</td>
                      <td><LoadingText text={StringUtil.capitalize(this.state.account.type)} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Company name</td>
                      <td><LoadingText text={this.state.account.registered_name} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Country of incorporation</td>
                      <td><LoadingText text={this.state.account.country_of_incorporation} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Registration number</td>
                      <td><LoadingText text={this.state.account.registration_number} /></td>
                    </tr>
                    {this.state.account.invited_by &&
                      <tr>
                        <td className="header-text">Invited by</td>
                        <td><LoadingText text={this.state.account.invited_by.registered_name} /></td>
                      </tr>
                    }
                    {this.state.account.type === "partner" &&
                      <tr>
                        <td className="header-text">Partner percentage</td>
                        <td><LoadingText text={Converter.toDigits(this.state.account.partner_percentage, 2) + "%"}/></td>
                      </tr>
                    }
                    {this.state.account.type === "partner" &&
                      <tr>
                        <td className="header-text">Partner territory</td>
                        <td><LoadingText text={this.state.account.partner_territory ? StringUtil.commaSeparated(this.state.account.partner_territory.map(country => country.name)) : "-"} /></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Users</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getUserHeaders()}
                  rows={this.state.user_rows}
                  selectable={false}
                  empty_rows_text="There are no users"
                />
              </div>
            </div>
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default Account;

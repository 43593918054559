import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Mapper from '../../../../shared/util/mapper';
import MultilineTextField from '../../../../shared/components/multiline_textfield';

class SanctionsForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      sanction_us: this.props.compliance.sanction_us,
      sanction_us_details: this.props.compliance.sanction_us_details,
      sanction_un: this.props.compliance.sanction_un,
      sanction_un_details: this.props.compliance.sanction_un_details
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key], true);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);

    if(e.target.name === "sanction_us") {
      if(!this.isTrue(value)) {
        this.setState({ sanction_us_details: undefined });
        this.props.propertyUpdate("sanction_us_details", undefined);
      }
    }

    if(e.target.name === "sanction_un") {
      if(!this.isTrue(value)) {
        this.setState({ sanction_un_details: undefined });
        this.props.propertyUpdate("sanction_un_details", undefined);
      }
    }
  }

  isTrue(value) {
    return value === "true" || value === true;
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <span>Is your company or any affiliate owned, directly or indirectly, by any person on the US Office of Foreign Assets Control’s list of Specially Designated Nationals and Blocked Persons (“SDNs”)?</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.sanction_us) } >SDNs *</InputLabel>
              <Select
                native
                required
                label="SDNs *"
                value={this.state.sanction_us}
                name="sanction_us"
                onChange={this.handleChange.bind(this)} >
                  <option key="sanction_us_empty" value=""></option>
                  {Object.keys(Mapper.booleans()).map(boolean => {
                    return (<option key={"sanction_us_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>

        { this.isTrue(this.state.sanction_us) &&
          <div className="row">
            <div className="col-md-12">
              <MultilineTextField
                label="Details of the SDNs and their percentage ownership interest"
                variant="outlined"
                required
                fullWidth
                min_rows={4}
                margin="dense"
                value={this.state.sanction_us_details}
                name="sanction_us_details"
                onChange={this.handleChange.bind(this)}
                InputLabelProps={{
                  shrink: this.state.sanction_us_details !== undefined,
                }}
              />
            </div>
          </div>
        }
        
        <div className="row">
          <div className="col-md-12">
            <span>Does your company or any affiliate have any offices, transactions, investments or other activities (current or planned) in jurisdictions subject to sanctions by the UN Security Council or the MAS5, or deemed high-risk or monitored by the Financial Action Task Force?</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.sanction_un) } >Sanctions *</InputLabel>
              <Select
                native
                required
                label="Sanctions *"
                value={this.state.sanction_un}
                name="sanction_un"
                onChange={this.handleChange.bind(this)} >
                  <option key="sanction_un_empty" value=""></option>
                  {Object.keys(Mapper.booleans()).map(boolean => {
                    return (<option key={"sanction_un_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>

        { this.isTrue(this.state.sanction_un) &&
          <div className="row">
            <div className="col-md-12">
              <MultilineTextField
                label="Details on such activities (including percentage of revenue and assets exposed to such activities)"
                variant="outlined"
                required
                fullWidth
                min_rows={4}
                margin="dense"
                value={this.state.sanction_un_details}
                name="sanction_un_details"
                onChange={this.handleChange.bind(this)}
                InputLabelProps={{
                  shrink: this.state.sanction_un_details !== undefined,
                }}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SanctionsForm;

import React from 'react';
import { getAccountIds, setAccountId, logout } from '../../shared/services/authentication_service';
import { getInvoiceCount } from '../../shared/services/invoices_service';
import EventBus from '../../shared/components/event_bus';
import ParentMenu from '../../shared/components/parent_menu';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invoice_count: {},
    }
  }

  componentDidMount() {
    this.getInvoiceCount()
    EventBus.on("updateInvoice", (data) =>
      this.getInvoiceCount()
    );
  }

  getInvoiceCount() {
    getInvoiceCount().then((invoice_count) => {
      if (invoice_count) {
        this.setState({ invoice_count: invoice_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  selectAccount() {
    setAccountId(null);
    this.props.history.push("/");
  }

  getTopbarItems() {
    var items = [];
    
    if(getAccountIds().length > 1) {
      items.push({
        name: "Change account",
        icon: "fas fa-random",
        link: "select-account",
        onClick: () => this.selectAccount()
      });
    }

    items.push({
      name: "Settings",
      icon: "fas fa-cog",
      link: "settings"
    });

    items.push({
      name: "Sign out",
      icon: "fas fa-sign-out-alt",
      onClick: () => logout()
    });

    return items;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={this.getTopbarItems()}
      sidebar_items={[
        {
          title: "Partner",
          items: [
            {
              name: "Dashboard",
              icon: "fas fa-chart-line",
              link: "dashboard"
            },
            {
              name: "Accounts",
              icon: "fas fa-user-friends",
              link: "accounts"
            },
            {
              name: "Transactions",
              icon: "fas fa-lock",
              link: "transactions/trade",
              sub_items: [
                {
                  name: "Trade",
                  link: "transactions/trade"
                },
                {
                  name: "Freight",
                  link: "transactions/freight"
                }
              ]
            },
            {
              name: "Invitations",
              icon: "fas fa-paper-plane",
              link: "invites"
            },
            {
              name: "Calculator",
              icon: "fas fa-calculator",
              link: "calculator"
            }
          ]
        },
        {
          title: "Company",
          items: [
            {
              name: "Account",
              icon: "fas fa-building",
              link: "account"
            },
            {
              name: "Invoices",
              icon: "fas fa-file-alt",
              link: "invoices",
              counter: this.state.invoice_count.open
            }
          ]
        }
      ]}
    />;
  }
}

export default Menu;

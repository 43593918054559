import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';

class ConfirmWithTextDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      value: props.defaultValue,
    };
  }

  render() {
    return (
      <Theme>
        <Dialog open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.message}</DialogContentText>
            <br />
            <TextField
              margin="dense"
              id="value"
              label={this.props.fieldName}
              value={this.state.value}
              multiline={this.props.options.multiline}
              onChange={(event) => this.setState({ value: event.target.value })}
              autoFocus
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.cancel()} color="primary">Cancel</Button>
            <Button onClick={() => this.props.proceed(this.state.value)} color="primary">Ok</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

ConfirmWithTextDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,  // arguments of your confirm function
  message: PropTypes.string,  // arguments of your confirm function
  fieldName: PropTypes.string,  // arguments of your confirm function
  defaultValue: PropTypes.number,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(ConfirmWithTextDialog);

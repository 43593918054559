import React from 'react';

class InTransit extends React.Component {
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>In transit</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>The shipment is in transit and is being tracked by the platform.</span>
              <br/>
              <span>The current status of the cargo can be viewed in the Tracking tab above.</span>
              <br/>
              <span>You will recieve a notification once the goods are discharged at to Port of destination.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default InTransit;

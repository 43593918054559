import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import confirmText from '../../../../shared/components/confirm_text.js'
import alertDialog from '../../../../shared/components/alert';
import confirm from '../../../../shared/components/confirm.js'
import Converter from '../../../../shared/util/converter';
import StringUtil from '../../../../shared/util/string_util';
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';
import ErrorHandler from '../../../../shared/util/error_handler';
import { getUserId } from '../../../../shared/services/authentication_service';
import { getLatestComplianceDocument } from '../../../../shared/services/compliances_service';

class PayPayment extends React.Component {
  
  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      notified: !this.props.transaction.states.find(s => s.status === "milestone_payment_notification"),
      flow_patch: {
        status: "milestone_payment_notification",
        action: "milestone_payment_paid"
      }
    };
  }

  componentDidMount() {
    this.setAuthorisedUser();
  }
  
  setAuthorisedUser() {
    var userId = getUserId();
    getLatestComplianceDocument().then((compliance_document) => {
      if(compliance_document) {
        var authorised_user = compliance_document.authorised_users.find(c => c.user_account_id === userId);
        if(authorised_user) {
          this.setState({ is_authorised: true });
        } else {
          this.setState({ is_authorised: false });
        }
      } else {
        this.setState({ is_authorised: false });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you have made the payment?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        this.setState({ notified: true });
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  requestAmendment(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "This will allow the Seller to amend the transaction details. Payment milestones that are already paid in escrow cannot be changed and remain safe in escrow. After the amendment a new Escrow Agreement will be generated that needs to be signed. All parties need to approve the amendment request. Are you sure you want to ament the transaction? Please state why you want to amend.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({
          status: "milestone_payment",
          action: "amendment_requested",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Milestone payment</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please make the payment for milestone #{this.props.transaction.current_payment_milestone.rank} ({this.props.transaction.current_payment_milestone.condition}).</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Payment information:</span>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td>Amount</td>
                    <td>{Converter.toCurrency(this.props.transaction.currency, this.props.transaction.current_payment_milestone.amount)}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Payment reference</td>
                    <td>Payment Transaction {StringUtil.shortId(this.props.transaction.id)} Milestone {this.props.transaction.current_payment_milestone.rank}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank account number</td>
                    <td>{this.props.transaction.bank_details.bank_account}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank account holder</td>
                    <td>{this.props.transaction.bank_details.bank_account_holder}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank name</td>
                    <td>{this.props.transaction.bank_details.bank_name}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank address</td>
                    <td>{this.props.transaction.bank_details.bank_address}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Bank SWIFT</td>
                    <td>{this.props.transaction.bank_details.bank_swift_code}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Correspondent bank</td>
                    <td>{this.props.transaction.bank_details.intermediary_bank}</td>
                  </tr>
                  <tr className="row-no-top-border-and-padding">
                    <td>Correspondent bank SWIFT</td>
                    <td>{this.props.transaction.bank_details.intermediary_bank_swift_code}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          { !this.state.notified &&
            <div>
              <div className="row">
                <div className="col-md">
                  <span>To improve the processing speed of the payment, please notify once the payment has been paid.</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">

                  <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>
                    
                    <div className="row">
                      <div className="col-md">
                        <AsyncButton
                          type="submit"
                          variant="outlined"
                          color="primary"
                          icon="check"
                          text="Paid" />
                        { this.state.is_authorised &&
                          <AsyncButton
                            variant="outlined"
                            color="secondary"
                            onClick={this.requestAmendment.bind(this)}
                            icon="edit"
                            text="Request amendment" />
                        }
                        { !this.state.is_authorised &&
                          <Button
                            variant="outlined"
                            color="secondary"
                            disabled
                            startIcon={<Icon>edit</Icon>}>
                            Request amendment
                          </Button>
                        }
                      </div>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          }

          { this.state.notified &&
            <div>
              <div className="row">
                <div className="col-md-12">
                  <Alert severity="info">The Escrow Agent has been notified of your payment.</Alert>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default PayPayment;

import React from 'react';
import TextField from '@material-ui/core/TextField';

class MultilineTextField extends React.Component {
  
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this);

    this.state = {
      rows: this.getRows(this.props.value)
    };
  }

  onChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ rows: this.getRows(value) });
    this.props.onChange(e);
  }

  getRows(value) {
    var rows = this.props.min_rows;
    if(value) {
      rows = Math.max(value.split(/\r\n|\r|\n/).length, this.props.min_rows);
    }
    return rows;
  }

  render() {
    return (
      <TextField
        multiline
        rows={this.state.rows}
        label={this.props.label}
        variant={this.props.variant}
        required={this.props.required}
        fullWidth={this.props.fullWidth}
        margin={this.props.margin}
        value={this.props.value}
        name={this.props.name}
        InputLabelProps={this.props.InputLabelProps}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}

export default MultilineTextField;

import React from 'react';
import ReviewFee from './review_fee';
import Compliance from './compliance';
import AgreeTransaction from './agree_transaction';
import FreightPayment from './freight_payment';
import LoadOnShip from './load_on_ship';
import ReleaseFunds from './release_funds';
import Completed from './completed';
import Rejected from './rejected';
import Invalid from './invalid';
import Loading from './loading';

class ParentActionRenderer extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    this.props.updateTransaction(data);
  }

  render() {
    if(!this.props.transaction.states) {
      return <Loading/>;
    }

    if(this.props.transaction.states.some(s => s.status === "carrier_review_fee")) {
      return <ReviewFee transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "carrier_compliance") || this.props.transaction.states.some(s => s.status === "charterer_compliance") || this.props.transaction.states.some(s => s.status === "broker_compliance")) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "carrier_agree_transaction") || this.props.transaction.states.some(s => s.status === "charterer_agree_transaction") || this.props.transaction.states.some(s => s.status === "escrow_agree_transaction")) {
      return <AgreeTransaction transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "freight_payment")) {
      return <FreightPayment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "load_on_ship")) {
      return <LoadOnShip transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }
    if(this.props.transaction.states.some(s => s.status === "release_broker_funds")) {
      return <ReleaseFunds transaction={this.props.transaction} updateTransaction={this.updateTransaction} type="broker"/>;
    }
      if(this.props.transaction.states.some(s => s.status === "release_carrier_funds")) {
      return <ReleaseFunds transaction={this.props.transaction} updateTransaction={this.updateTransaction}  type="carrier"/>;
    }
    if(this.props.transaction.states.some(s => s.status === "completed")) {
      return <Completed/>;
    }
    if(this.props.transaction.states.some(s => s.status === "rejected")) {
      return <Rejected transaction={this.props.transaction} />;
    }

    return <Invalid/>;
  }
}

export default ParentActionRenderer;

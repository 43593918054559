import React from 'react';
import TextField from '@material-ui/core/TextField';
import AsyncButton from '../../../components/async_button';
import DomUtil from '../../../util/dom_util';
import PasswordForm from '../../../components/password_form';
import { createAccount } from '../../../services/accounts_service';
import ErrorHandler from '../../../util/error_handler';
import Mapper from '../../../util/mapper'
import alertDialog from '../../../components/alert';

class RegisterForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.createAccount = this.createAccount.bind(this);

    this.state = {
      account: { 
        partner_id: this.props.partner_id
      }
    };
  }

  handleChange(e) {
    this.state.account[e.target.name] = e.target.value;
    this.setState({ account: this.state.account });
  }

  passwordUpdated(password, has_errors) {
    this.state.account.password = password;
    this.state.account.password_has_errors = has_errors;
    this.setState({ account: this.state.account });
  }

  createAccount(e) {
    e.preventDefault();

    if(this.state.account.password_has_errors) {
      alertDialog("Alert", "Password does not match all requirements");
      return;
    }

    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var account_creation = Mapper.toAccountCreation(this.state.account);
    createAccount(account_creation).then((res) => {
      DomUtil.enableFormSubmitButtonClass(form);
      this.props.redirect();
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <form onSubmit={this.createAccount.bind(this)}>

          <div className="row">
            <div className="col-md">
              <TextField
                label="Company email"
                variant="outlined"
                required
                fullWidth
                margin="dense"
                name="email"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>

          <PasswordForm passwordUpdated={this.passwordUpdated.bind(this)} />

          <div className="row">
            <div className="col-md">
              <AsyncButton
                rootClassName="fullwidth-button"
                className="fullwidth-button"
                type="submit"
                variant="contained"
                color="primary"
                icon="check"
                text="Register" />
            </div>
          </div>

        </form>

      </div >
    );
  }
}

export default RegisterForm;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Mapper from '../../../../shared/util/mapper'
import StringUtil from '../../../../shared/util/string_util'

class RouteForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      pick_up_date: this.props.transaction.pick_up_date,
      place_of_loading: this.props.transaction.place_of_loading,
      destination: this.props.transaction.destination
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key]);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);
  }

  render() {
    return (
      <div>

        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Pick up date"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.pick_up_date}
              name="pick_up_date"
              onChange={this.handleChange.bind(this)} 
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Place of loading"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.place_of_loading}
              name="place_of_loading"
              onChange={this.handleChange.bind(this)} 
            />
          </div>
          <div className="col-md-4">
            <TextField
              label={StringUtil.capitalize(Mapper.toDestination(this.props.transaction.incoterms))}
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.destination}
              name="destination"
              onChange={this.handleChange.bind(this)} 
            />
          </div>
        </div>

      </div>
    );
  }
}

export default RouteForm;

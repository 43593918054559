import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from 'react-loading-skeleton';

class LoadingSelectField extends React.Component {
  
  render() {
    return (
      <div>
        <FormControl
          variant="outlined"
          margin="dense"
          fullWidth
          required
          InputLabelProps={{
            shrink: this.props.shrink
          }}>
          <div className="loading-select-field-overlay">
            <Skeleton />
          </div>
          <InputLabel shrink={this.props.shrink}>{this.props.label}</InputLabel>
          <Select
            native
            required
            label={this.props.label}
            InputLabelProps={{
              shrink: this.props.shrink
            }}>
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default LoadingSelectField;

import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DomUtil from '../../util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getUser, updateUser, deleteUser } from '../../services/accounts_service';
import { hasType, getAccountId } from '../../../shared/services/authentication_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper'
import confirm from '../../components/confirm'
import LoadingTextField from '../../components/loading_text_field'
import LoadingSelectField from '../../components/loading_select_field'

class ShowUser extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user_id: this.props.match.params.user_id,
      user: undefined
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    getUser(getAccountId(), this.state.user_id).then((user) => {
      if (user) {
        this.setState({ user: user });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }


  handleChange(e) {
    this.state.user[e.target.name] = e.target.value;
    this.setState({ user: this.state.user });
  }

  updateUser(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var update = Mapper.toAdminUserUpdate(this.state.user);
    updateUser(getAccountId(), this.state.user_id, update).then(() => {
      this.props.history.push(`/account/` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  deleteUser(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to delete this user?`).then((result) => {
      DomUtil.disableClass(button);
      deleteUser(getAccountId(), this.state.user_id).then(() => {
        this.props.history.push(`/account/` + this.props.location.search);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={`/account/` + this.props.location.search}>Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>User</BreadcrumbItem>
        </Breadcrumb>
              
        <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>User</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.updateUser.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md-4">
                      { this.state.user &&
                      <TextField
                        label="Company email"
                        variant="outlined"
                        required
                        fullWidth
                        disabled
                        margin="dense"
                        name="email"
                        value={this.state.user.email}
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                      { !this.state.user &&
                        <LoadingTextField
                          label="Company email"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.user &&
                        <TextField
                          label="Full name"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          name="full_name"
                          value={this.state.user.full_name}
                          onChange={this.handleChange.bind(this)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      }
                      { !this.state.user &&
                        <LoadingTextField
                          label="Full name"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm">
                      <span>Roles</span>
                      <br/>
                      <span>- <strong>Read only:</strong> only has read access to resource on the platform.</span>
                      <br/>
                      <span>- <strong>Default:</strong> can create and update resource on the platform.</span>
                      <br/>
                      { hasType("escrow") &&
                        <div>
                          <span>- <strong>Authorised:</strong> can approved the escrow agreement on the platform.</span>
                          <br/>
                        </div>
                      }
                      <span>- <strong>Admin:</strong> can invite and edit users within the account, there must always be one admin user.</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.user &&
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}>
                          <InputLabel shrink={true}>Role *</InputLabel>
                          <Select
                            native
                            required
                            label="Role *"
                            name="role"
                            value={this.state.user.role}
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={this.handleChange.bind(this)} >
                              {Object.keys(Mapper.userRoles()).map(role => {
                                if(hasType("escrow") || role !== "authorised") {
                                  return (<option key={"role_"+role} value={role}>{Mapper.fromUserRole(role)}</option>);
                                }
                              })}
                          </Select>
                        </FormControl>
                      }
                      { !this.state.user &&
                        <LoadingSelectField
                          label="Role"
                          shrink={true}
                        />
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Update" />
                      <AsyncButton
                        className="button-danger-outlined"
                        variant="outlined"
                        color="delete"
                        onClick={this.deleteUser.bind(this)}
                        icon="delete"
                        text="Delete" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default ShowUser;

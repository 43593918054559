import React from 'react';
import ParentActionRenderer from '../../../../shared/pages/freight-transactions/actions/parent_action_renderer';
import ReviewCosts from './review_costs';
import Compliance from './compliance';
import AgreeTransactionAction from '../../../../shared/pages/freight-transactions/actions/agree_transaction_action';
import { isReadOnly } from '../../../../shared/services/authentication_service';
import FreightPayment from './freight_payment';
import LoadOnShip from './load_on_ship';

class ActionRenderer extends ParentActionRenderer {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    return this.props.updateTransaction(data);
  }

  render() {
    if(!this.props.transaction.states || isReadOnly()) {
      return super.render();
    }

    if(this.props.transaction.states.some(s => s.status === "carrier_review_fee" && s.action_allowed)) {
      return <ReviewCosts transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="carrier_review_fee"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "carrier_compliance" && s.action_allowed)) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="carrier_compliance"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "charterer_compliance" && s.action_allowed)) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="charterer_compliance"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "broker_compliance" && s.action_allowed)) {
      return <Compliance transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="broker_compliance"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "carrier_agree_transaction" && s.action_allowed)) {
      return <AgreeTransactionAction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="carrier_agree_transaction" type="carrier"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "charterer_agree_transaction" && s.action_allowed)) {
      return <AgreeTransactionAction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="charterer_agree_transaction" type="charterer"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "broker_agree_transaction" && s.action_allowed)) {
      return <AgreeTransactionAction transaction={this.props.transaction} updateTransaction={this.updateTransaction} flow_status="broker_agree_transaction" type="broker"/>;
    }

    if(this.props.transaction.states.some(s => s.status === "freight_payment" && s.action_allowed)) {
      return <FreightPayment transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    if(this.props.transaction.states.some(s => s.status === "load_on_ship" && s.action_allowed)) {
      return <LoadOnShip transaction={this.props.transaction} updateTransaction={this.updateTransaction}/>;
    }

    return super.render();
  }
}

export default ActionRenderer;

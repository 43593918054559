import React from 'react';
import Button from '@material-ui/core/Button';
import Converter from '../../../util/converter';
import Mapper from '../../../util/mapper'
import StringUtil from '../../../util/string_util'
import alertDialog from '../../../components/alert';
import { getDocument } from '../../../services/documents_service';
import fileDownload from 'js-file-download';
import AsyncButton from '../../../components/async_button';

class DetailsTab extends React.Component {

  openContainers(e) {
    e.preventDefault();
  }

  downloadDocument(document) {
    getDocument(document.id).then(blob => {
      fileDownload(blob, document.file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  render() {
    return (
      <div>
        <div className="row no-spacing-bottom">
          <div className="col-sm-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td className="header-text">Commercial invoice amount</td>
                  <td>{Converter.toCurrency(this.props.transaction.currency, this.props.transaction.commercial_invoice_amount)}</td>
                </tr>
                <tr>
                  <td className="header-text">Commercial invoice</td>
                  {!this.props.transaction.commercial_invoice &&
                    <td><i>-</i></td>
                  }
                  {this.props.transaction.commercial_invoice &&
                    <td>
                      <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                      <Button
                        className="download-document-button"
                        color="primary"
                        disabled={this.props.read_only}
                        onClick={this.downloadDocument.bind(this, this.props.transaction.commercial_invoice)} >
                        {this.props.transaction.commercial_invoice.file_name}
                      </Button>
                    </td>
                  }
                </tr>
                {this.props.transaction.broker &&
                  <tr>
                    <td className="header-text">Broker fee</td>
                    <td>{Converter.toCurrency(this.props.transaction.currency, this.props.transaction.broker_fee)}</td>
                  </tr>
                }
                <tr>
                  <td className="header-text">Pick up date</td>
                  <td>{Converter.toDate(this.props.transaction.pick_up_date)}</td>
                </tr>
                <tr>
                  <td className="header-text">Bill of lading</td>
                  {!this.props.transaction.bill_of_lading &&
                    <td><i>(available after loading)</i></td>
                  }
                  {this.props.transaction.bill_of_lading &&
                    <td>
                      <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                      <Button
                        className="download-document-button"
                        color="primary"
                        disabled={this.props.read_only}
                        onClick={this.downloadDocument.bind(this, this.props.transaction.bill_of_lading)} >
                        {this.props.transaction.bill_of_lading.file_name}
                      </Button>
                    </td>
                  }
                </tr>
                
              </tbody>
            </table>
          </div>
          <div className="col-sm-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td className="header-text">Pick up date</td>
                  <td>{Converter.toDate(this.props.transaction.pick_up_date)}</td>
                </tr>
                <tr>
                  <td className="header-text">Place of loading</td>
                  <td>{this.props.transaction.place_of_loading}</td>
                </tr>
                <tr>
                  <td className="header-text">Place of destination</td>
                  <td>{this.props.transaction.place_of_destination}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row no-spacing-top">
          <div className="col-sm">
            <table className="table table-details" >
              <tbody>
                <tr>
                  <td style={{ width: "150px" }} className="header-text">Cargo description</td>
                  <td><span style={{ whiteSpace: "pre-line" }}>{this.props.transaction.cargo_description}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {!this.props.read_only &&
          <div className="right-corner">
            <AsyncButton
              className="button-icon-only"
              color="primary"
              onClick={this.downloadDocument.bind(this, this.props.transaction.transaction_agreement)}
              icon="download" />
          </div>
        }
      </div>
    );
  }
}

export default DetailsTab;

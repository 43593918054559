import React from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Converter from '../../../util/converter';
import alertDialog from '../../../components/alert';
import { getDocument } from '../../../services/documents_service';
import { getAccountId  } from '../../../services/authentication_service';
import fileDownload from 'js-file-download';
import Mapper from '../../../util/mapper';

class EscrowTab extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account_id: getAccountId()
    }
  }

  openDocument(agreement_document, e) {
    e.preventDefault();
    var url = `/document/` + agreement_document.id + "?file_name=" + agreement_document.file_name;
    window.open(url, "_blank");
  }

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  getCharge(type) {
    var charges = this.props.transaction.charges;
    if(charges) {
      return charges.find(c => c.type === type);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <table className="table table-details" >
            <tbody>
              <tr className="row-no-top-border-and-padding">
                <td className="header-text">Payment status</td>
                <td><span className={"badge " + Mapper.toPaymentStatusBadge(this.props.transaction.payment_status)} >{Mapper.fromPaymentStatus(this.props.transaction.payment_status)}</span></td>
              </tr>
              <tr>
                <td className="header-text">Payment received at</td>
                  <td>{Converter.toDate(this.props.transaction.payment_received_at)}</td>
              </tr>
              {this.props.transaction.broker &&
                <tr>
                  <td className="header-text">Proof of bank transfer Broker</td>
                  {!this.props.transaction.proof_bank_transfer_broker &&
                    <td><i>(available after release)</i></td>
                  }
                  {this.props.transaction.proof_bank_transfer_broker &&
                    <td>
                      <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                      <Button
                        className="download-document-button"
                        color="primary"
                        disabled={this.props.read_only}
                        onClick={this.downloadDocument.bind(this, this.props.transaction.proof_bank_transfer_broker.id, this.props.transaction.proof_bank_transfer_broker.file_name)} >
                        {this.props.transaction.proof_bank_transfer_broker.file_name}
                      </Button>
                    </td>
                  }
                </tr>
              }
              <tr>
                <td className="header-text">Proof of bank transfer Carrier</td>
                {!this.props.transaction.proof_bank_transfer_carrier &&
                  <td><i>(available after release)</i></td>
                }
                {this.props.transaction.proof_bank_transfer_carrier &&
                  <td>
                    <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                    <Button
                      className="download-document-button"
                      color="primary"
                      disabled={this.props.read_only}
                      onClick={this.downloadDocument.bind(this, this.props.transaction.proof_bank_transfer_carrier.id, this.props.transaction.proof_bank_transfer_carrier.file_name)} >
                      {this.props.transaction.proof_bank_transfer_carrier.file_name}
                    </Button>
                  </td>
                }
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm-6">
          <table className="table table-details" >
            <tbody>
              <tr className="row-no-top-border-and-padding">
                <td className="header-text">Escrow Agent Fee*</td>
                <td>{Converter.toCurrency(this.props.transaction.currency, this.getCharge("escrow_fee").amount)}</td>
              </tr>
              {(this.getCharge("extension_fee") && this.getCharge("extension_fee").status === "final") &&
                <tr>
                  <td className="header-text">Extension fee</td>
                  <td>{Converter.toCurrency(this.props.transaction.currency, this.getCharge("extension_fee").amount)}</td>
                </tr>
              }
              {(this.getCharge("singapore_tax") && this.getCharge("singapore_tax").status === "final") &&
                <tr>
                  <td className="header-text">GST</td>
                  <td>{Converter.toCurrency(this.props.transaction.currency, this.getCharge("singapore_tax").amount)}</td>
                </tr>
              }
              <tr>
                <td className="header-text">Escrow agreement</td>
                {!this.props.transaction.escrow_agreement &&
                  <td><i>(available after compliance)</i></td>
                }
                {this.props.transaction.escrow_agreement &&
                  <td>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={this.props.read_only}
                      startIcon={<Icon>launch</Icon>}
                      onClick={this.openDocument.bind(this, this.props.transaction.escrow_agreement)} >
                      Open agreement
                    </Button>
                  </td>
                }
              </tr>
              <tr>
                <td colSpan="2">
                  <span><i>*The Escrow Agent Fee will be deducted from the funds held in escrow.</i></span>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EscrowTab;

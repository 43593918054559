import React from 'react';
import NumberFormat from 'react-number-format';

class Converter extends React.Component {

  static numberFormatter(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString={true}
        decimalScale={2}
      />
    );
  }

  static toDate(date) {
    if(!date) {
      return "-"
    }

    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(new Date(date))
  }

  static toMonth(date) {
    if(!date) {
      return "-"
    }

    return new Intl.DateTimeFormat('en-GB', {
      month: 'long',
      year: 'numeric',
    }).format(new Date(date))
  }
  
  static toDatetime(datetime) {
    if(!datetime) {
      return "-"
    }

    return new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(new Date(datetime))
  }

  static toDayOfWeek(date) {
    if(!date) {
      return "-"
    }
    
    return new Intl.DateTimeFormat('en-GB', { 
      weekday: 'short',
    }).format(date)
  }
  
  static toCurrency(currency, amount) {
    if(!amount) {
      return "-"
    }

    if(currency) {
      var formatted = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol'
      }).format(amount);
      return formatted;
    } else {
      return this.formatNumber(amount, 2);
    }
  }
  
  static formatNumber(amount, digits) {
    if(!amount) {
      return "-"
    }

    var significantDigits = Math.round(amount).toString().length + digits;
    
    return new Intl.NumberFormat('en-GB', {
      maximumSignificantDigits: significantDigits,
      minimumSignificantDigits: significantDigits,
    }).format(amount);
  }
  
  static toDigits(amount, digits) {
    if(!amount && amount !== 0) {
      return "-"
    }
    return Number(amount).toFixed(digits)
  }

  static getYear(date) {
    var res = new Date(Date.parse(date));
    return res.getFullYear();
  }

  static getWeeknumber(date) {
    var d = new Date(Date.parse(date));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return weekNo;
  }

  static sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }
  
  static isToday(date) {
    var today = new Date();
    return date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate();
  }
  
  static isWeekend(date) {
    var dateValue = date.getDay();
    return (dateValue === 0 || dateValue === 6)
  }
}

export default Converter;

import React from 'react';
import { getAccountIds, setAccountId, logout } from '../../shared/services/authentication_service';
import { getTradeTransactionCount } from '../../shared/services/trade_transactions_service';
import { getFreightTransactionCount } from '../../shared/services/freight_transactions_service';
import { getInvoiceCount } from '../../shared/services/invoices_service';
import { getLatestComplianceDocument } from '../../shared/services/compliances_service';
import EventBus from '../../shared/components/event_bus';
import ParentMenu from '../../shared/components/parent_menu';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      trade_transaction_count: {},
      freight_transaction_count: {},
      invoice_count: {},
      compliance_document: undefined,
      compliance_loaded: false,
    }
  }

  componentDidMount() {
    this.getTradeTransactionCount();
    this.getFreightTransactionCount();
    this.getInvoiceCount();
    this.getLatestComplianceDocument();
    EventBus.on("updateTradeTransaction", (data) =>
      this.getTradeTransactionCount()
    );
    EventBus.on("updateFreightTransaction", (data) =>
      this.getFreightTransactionCount()
    );
    EventBus.on("updateInvoice", (data) =>
      this.getInvoiceCount()
    );
    EventBus.on("updateCompliance", (data) =>
      this.getLatestComplianceDocument()
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.getTradeTransactionCount();
    this.getFreightTransactionCount();
  }

  getTradeTransactionCount() {
    getTradeTransactionCount().then((trade_transaction_count) => {
      if (trade_transaction_count) {
        this.setState({ trade_transaction_count: trade_transaction_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getFreightTransactionCount() {
    getFreightTransactionCount().then((freight_transaction_count) => {
      if (freight_transaction_count) {
        this.setState({ freight_transaction_count: freight_transaction_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getInvoiceCount() {
    getInvoiceCount().then((invoice_count) => {
      if (invoice_count) {
        this.setState({ invoice_count: invoice_count });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLatestComplianceDocument() {
    getLatestComplianceDocument().then((compliance_document) => {
      this.setState({ compliance_document: compliance_document, compliance_loaded: true });
    }).catch(error => {
      console.log(error);
    });
  }

  getComplianceStatusIcon() {

    if(!this.state.compliance_loaded) {
      return;
    }

    var compliance = this.state.compliance_document;

    if(!compliance) {
      return <span className="sidebarIcon alertColor"><i className="fas fa-exclamation-circle"></i> </span>;
    }

    if(compliance.status === "submitted") {
      return <span className="sidebarIcon infoColor"><i className="fas fa-clock"></i> </span>;
    }

    if(compliance.status === "approved" && !this.isExpired(compliance.expires_at)) {
      return <span className="sidebarIcon confirmColor"><i className="fas fa-check-circle"></i> </span>;
    }

    return <span className="sidebarIcon alertColor"><i className="fas fa-exclamation-circle"></i> </span>;
  }

  isExpired(date) {
    return new Date(date) < Date.now();
  }
  
  selectAccount() {
    setAccountId(null);
    this.props.history.push("/");
  }

  getTopbarItems() {
    var items = [];
    
    if(getAccountIds().length > 1) {
      items.push({
        name: "Change account",
        icon: "fas fa-random",
        link: "select-account",
        onClick: () => this.selectAccount()
      });
    }

    items.push({
      name: "Settings",
      icon: "fas fa-cog",
      link: "settings"
    });

    items.push({
      name: "Support",
      icon: "fas fa-question-circle",
      link: "support"
    });

    items.push({
      name: "Sign out",
      icon: "fas fa-sign-out-alt",
      onClick: () => logout()
    });

    return items;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={this.getTopbarItems()}
      sidebar_items={[
        {
          title: "Services",
          items: [
            {
              name: "Transactions",
              icon: "fas fa-lock",
              link: "transactions/trade",
              sub_items: [
                {
                  name: "Trade",
                  link: "transactions/trade",
                  counter: this.state.trade_transaction_count.actions_allowed
                },
                {
                  name: "Freight",
                  link: "transactions/freight",
                  counter: this.state.freight_transaction_count.actions_allowed
                }
              ]
            },
            {
              name: "Calculator",
              icon: "fas fa-calculator",
              link: "calculator"
            }
          ]
        },
        {
          title: "Company",
          items: [
            {
              name: "Account",
              icon: "fas fa-building",
              link: "account"
            },
            {
              name: "Compliance",
              icon: "fas fa-user-shield",
              link: "compliance",
              customerPostfix: this.getComplianceStatusIcon()
            },
            {
              name: "Contacts",
              icon: "fas fa-address-book",
              link: "contacts"
            },
            {
              name: "Invoices",
              icon: "fas fa-file-alt",
              link: "invoices",
              counter: this.state.invoice_count.open
            }
          ]
        }
      ]}
    />;
  }
}

export default Menu;

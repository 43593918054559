import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mapper from '../../../../shared/util/mapper';
import DocumentUpload from '../../../../shared/components/document_upload';

class ShareholderPersonForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      full_name: this.props.shareholder.full_name,
      passport_number: this.props.shareholder.passport_number,
      passport: this.props.shareholder.passport ? this.props.shareholder.passport : {},
      date_of_birth: this.props.shareholder.date_of_birth,
      nationality: this.props.shareholder.nationality,
      country_residency: this.findCountry(this.props.shareholder.country_residency_code),
      residential_address_document: this.props.shareholder.residential_address_document ? this.props.shareholder.residential_address_document : {},
      gender: this.props.shareholder.gender,
      percentage_shareholdings: this.props.shareholder.percentage_shareholdings
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(that.props.index, key, that.state[key], true);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(this.props.index, e.target.name, value);
  }  

  documentUploaded(id, file_name, parent_data) {
    this.state[parent_data.property].id = id;
    this.state[parent_data.property].file_name = file_name;
    this.setState({ [parent_data.property]: this.state[parent_data.property] });
    this.props.propertyUpdate(this.props.index, parent_data.property, this.state[parent_data.property]);
  }

  documentCleared(parent_data) {
    this.state[parent_data.property].id = undefined;
    this.state[parent_data.property].file_name = undefined;
    this.setState({ [parent_data.property]: this.state[parent_data.property] });
    this.props.propertyUpdate(this.props.index, parent_data.property, this.state[parent_data.property]);
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.country_residency = newValue;
    } else {
      this.state.country_residency = { };
    }
    this.setState({ country_residency: this.state.country_residency });
    this.props.propertyUpdate(this.props.index, "country_residency", this.state.country_residency);
  }

  findCountry(code) {
    if(!code) {
      return;
    }
    return this.props.countries.find(c => c.code === code);
  }

  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Full name"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.full_name}
              name="full_name"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.full_name !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Passport No."
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.passport_number}
              name="passport_number"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.passport_number !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
              <DocumentUpload 
                title="Passport"
                access="escrow_only"
                allowed_account_ids={[]}
                allow_authorised_accounts={false}
                documentUploaded={this.documentUploaded.bind(this)}
                documentCleared={this.documentCleared.bind(this)}
                document_id={this.state.passport.id}
                file_name={this.state.passport.file_name}
                parent_data={{ property: "passport" }}  />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Nationality"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.nationality}
              name="nationality"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.nationality !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <Autocomplete
              key="country_residency"
              options={this.props.countries}
              groupBy={this.firstLetter.bind(this)}
              getOptionLabel={(option) => option.name}
              value={this.state.country_residency}
              onChange={this.handleAutoCompleteChange.bind(this)}
              renderInput={(params) => 
                <TextField {...params}
                  label="Country of Residency"
                  variant="outlined"
                  fullWidth
                  required
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              }
            />
          </div>
          <div className="col-md-4">
              <DocumentUpload 
                title="Proof residential address"
                access="escrow_only"
                allowed_account_ids={[]}
                allow_authorised_accounts={false}
                documentUploaded={this.documentUploaded.bind(this)}
                documentCleared={this.documentCleared.bind(this)}
                document_id={this.state.residential_address_document.id}
                file_name={this.state.residential_address_document.file_name}
                parent_data={{ property: "residential_address_document" }}  />
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Date of Birth"
              variant="outlined"
              type="date"
              required
              fullWidth
              margin="dense"
              value={this.state.date_of_birth}
              name="date_of_birth"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.gender) } >Gender *</InputLabel>
              <Select
                native
                required
                label="Gender *"
                value={this.state.gender}
                name="gender"
                onChange={this.handleChange.bind(this)} >
                  <option key={"shareholders_"+this.props.index+"empty"} value=""></option>
                  {Object.keys(Mapper.genders()).map(gender => {
                    return (<option key={"shareholders_"+this.props.index+gender} value={gender}>{Mapper.fromGender(gender)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4">
            <TextField
              label="Percentage shareholdings"
              variant="outlined"
              required
              type="number"
              fullWidth
              margin="dense"
              value={this.state.percentage_shareholdings}
              name="percentage_shareholdings"
              onChange={this.handleChange.bind(this)}
              InputProps={{
                inputProps: { min: 0, max: 100 },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: this.state.percentage_shareholdings !== undefined,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ShareholderPersonForm;

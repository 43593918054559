import React from 'react';
import Link from '@material-ui/core/Link';
import { loggedIn } from '../../services/authentication_service';
import LoginForm from './components/login_form'
import ForgotPasswordForm from './components/forgot_password_form'
import RegisterForm from './components/register_form'
import TokenLogin from './components/token_login'

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
    this.changeView = this.changeView.bind(this);

    const params = new URLSearchParams(this.props.location.search);

    var view_options = [ "login", "forgot-password", "register" ];
    var view = params.get('view');
    if(!view || view_options.indexOf(view) === -1) {
      view = "login";
    }

    var partner_id = params.get('partner_id');
    var token = params.get('token');
    
    if(token) {
      view = "token";
    }
    
    this.state = {
      view: view,
      partner_id: partner_id,
      token: token
    };
  }

  componentWillMount() {
    if (loggedIn()) {
      this.redirect();
    }
  }

  redirect() {
    const params = new URLSearchParams(this.props.location.search);
    var ref = params.get('ref') ? params.get('ref') : "/";
    var search = "?" + params;
    this.props.history.replace({
      pathname: ref,
      search: search
     });
  }

  changeView(view, e) {
    e.preventDefault();
    this.setState({ view: view });
    const params = new URLSearchParams(this.props.location.search);
    params.delete("view");
    params.append("view", view);
    var search = "?" + params;
    this.props.history.replace(search);
  }
  
  render() {
    return (
      <div>

        <div className="container container-content container-center-small">

          <div className="container-body">
            
            <div className="row">
              <div className="col-sm">
                <div className="login-logo">
                  <img alt="logo" src="/images/logo.png" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                { this.state.view === "login" && 
                  <LoginForm redirect={this.redirect.bind(this)} />
                }
                { this.state.view === "forgot-password" && 
                  <ForgotPasswordForm redirect={this.redirect.bind(this)} />
                }
                { this.state.view === "register" && 
                  <RegisterForm redirect={this.redirect.bind(this)} partner_id={this.state.partner_id} />
                }
                { this.state.view === "token" && 
                  <TokenLogin redirect={this.redirect.bind(this)} token={this.state.token} />
                }
                
                { this.state.view !== "login" && this.state.view !== "token" &&
                  <div className="row">
                    <div className="col-md">
                      <Link href="" onClick={this.changeView.bind(this, "login")} >Back to Login</Link>
                    </div>
                  </div>
                }
                { this.state.view === "login" && 
                  <div className="row">
                    <div className="col-6">
                      <Link href="" onClick={this.changeView.bind(this, "forgot-password")} >Forgot Password?</Link>
                    </div>
                    {/* 
                    <div className="col-6 text-right">
                      <Link href="" onClick={this.changeView.bind(this, "register")} >Open Account</Link>
                    </div>
                     */}
                  </div>
                }

              </div>
            </div>

          </div>

        </div>

      </div >
    );
  }
}

export default Login;

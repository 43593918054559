import React from 'react';
import ReactTimeAgo from 'react-time-ago'
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Pagination from '../../../shared/components/pagination'
import { getFreightTransactions  } from '../../../shared/services/freight_transactions_service';
import { getAccountId, isReadOnly, hasType } from '../../../shared/services/authentication_service';
import Mapper from '../../../shared/util/mapper';
import StringUtil from '../../../shared/util/string_util';
import Converter from '../../../shared/util/converter';
import ErrorHandler from '../../../shared/util/error_handler';
import Table from "../../../shared/components/table";

class TradeTransactions extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let page = params.get('page') ? params.get('page') : 1;

    this.state = {
      page: page,
      pages: 0,
      account_id: getAccountId(),
      transactions: [],
      transaction: {},
      transaction_rows: undefined
    }
  }

  componentDidMount() {
    this.getFreightTransactions();
  }

  onNavigate(page) {
    this.setState({ page: page }, () => {
      this.getFreightTransactions();
    });
  }

  getFreightTransactions() {
    this.setState({ transaction_rows: undefined });
    getFreightTransactions(this.state.page - 1, 10).then((pagination) => {
      if (pagination) {
        this.setState({ transactions: pagination.transactions, pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setTransactionsRow();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  openTransaction(transaction) {
    this.props.history.push(`/transactions/freight/` + transaction.full_id + this.props.location.search);
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      page: this.state.page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }

  getHeaders() {
    return [
      { name: "", key: "action_required", className: "action-required-column" },
      { name: "ID", key: "id" },
      { name: "Status", key: "status" },
      { name: "Carrier", key: "carrier" },
      { name: "Charterer", key: "charterer" },
      { name: "Broker", key: "broker" },
      { name: "Amount", key: "amount" },
      { name: "Created", key: "created_at" }
    ];
  }

  setTransactionsRow() {
    var rows = [];
    this.state.transactions.map(transaction => {
      rows.push({
        full_id: transaction.id,
        action_required: transaction.action_allowed ? (<i style={{fontSize: "8px"}} className="fas fa-circle"></i>) : "",
        id: StringUtil.shortId(transaction.id),
        status: (<span className={"badge " + Mapper.toFlowParentStatusBadge(transaction.status)} >{Mapper.fromFlowParentStatus(transaction.status)}</span>),
        carrier: transaction.carrier.registered_name,
        charterer: transaction.charterer.registered_name,
        broker: transaction.broker ? transaction.broker.registered_name : "-",
        amount: Converter.toCurrency(transaction.currency, transaction.commercial_invoice_amount),
        created_at: <ReactTimeAgo date={new Date(transaction.created_at)} />
      });
    });
    this.setState({ transaction_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Freight transactions</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Freight transactions</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getHeaders()}
                  rows={this.state.transaction_rows}
                  selectable={true}
                  onRowSelected={this.openTransaction.bind(this)}
                  empty_rows_text="There are no transactions"
                />
              </div>
            </div>
            
            <Pagination onNavigate={this.onNavigate.bind(this)} page={this.state.page} pages={this.state.pages} />

          </div>
        </div>
      </div >
    );
  }
}

export default TradeTransactions;

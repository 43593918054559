import React from 'react';

class LoadOnShip extends React.Component {
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Load on ship</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for the bill of lading once the goods have been loaded onto the ship and satellite confirmation.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default LoadOnShip;

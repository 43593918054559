import React from 'react';
import { Redirect } from 'react-router-dom'
import { hasType, loggedIn, getExpiration, mfaVerification, actionRequired, getAccountIds, getAccountId, setAccountId } from '../services/authentication_service';

const expirationTimer = {};

class Authenticated extends React.Component {
  
  constructor(props) {
    super(props)
    this.redirectToLogin = this.redirectToLogin.bind(this);
  }
  
  componentDidMount() {
    this.setExpirationTimer();
  }
  
  componentDidUpdate() {
    this.setExpirationTimer();
  }

  setExpirationTimer() {
    if (loggedIn() && !expirationTimer.timer) {
      var msUntilExp = this.getTokenExpiration();

      console.log("Setting timer");

      expirationTimer.timer = setTimeout(() => {
        expirationTimer.timer = undefined;

        if(loggedIn()) {
          console.log("Still logged in, setting new timer");
          this.setExpirationTimer();
        } else {
          console.log("Token expired, redirect to login");
          this.redirectToLogin();
        }
      }, msUntilExp);
    }
  }

  getSearch() {
    const params = new URLSearchParams(window.location.search);
    params.delete("ref");
    params.append("ref", window.location.pathname);
    return "?" + params;
  }

  redirectToLogin() {
    this.props.history.push('/login' + this.getSearch());
  }

  getTokenExpiration() {
    var expiration = getExpiration();
    return (expiration * 1000) - Date.now();
  }

  render() {
    if (!loggedIn()) {
      if(window.location.pathname === "/login") {
        return <div></div>
      }
      return <Redirect to={'/login' + this.getSearch()} />
    }

    if (mfaVerification()) {
      if(window.location.pathname === "/mfa-verification") {
        return <div></div>
      }
      return <Redirect to={'/mfa-verification' + this.getSearch()} />
    }

    if (actionRequired()) {
      if(window.location.pathname === "/update" || window.location.pathname === "/document/terms-and-conditions") {
        return <div></div>
      }
      return <Redirect to={'/update' + this.getSearch()} />
    }

    if (!getAccountId()) {
      var accountIds = getAccountIds();
      if(accountIds.length == 1) {
        setAccountId(accountIds[0]);
      } else {
        if(window.location.pathname === "/select-account") {
          return <div></div>
        }
        return <Redirect to={'/select-account' + this.getSearch()} />
      }
    }

    var that = this;
    if (hasType(this.props.types)) {
      var children = React.Children.map(this.props.children, function (child) {
        return React.cloneElement(child, {
          location: that.props.location
        })
      })
    
      return <div>{children}</div>
    }

    return <div></div>
  }
}

export default Authenticated;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Pagination from '../../../shared/components/pagination'
import { getInvoices } from '../../../shared/services/invoices_service';
import ErrorHandler from '../../../shared/util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Converter from '../../../shared/util/converter';
import Mapper from '../../../shared/util/mapper';
import Table from "../../../shared/components/table";

class Invoices extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let page = params.get('page') ? params.get('page') : 1;

    this.state = {
      page: page,
      pages: 0,
      invoices: []
    }
  }

  componentDidMount() {
    this.getInvoices();
  }

  onNavigate(page) {
    this.setState({ page: page }, () => {
      this.getInvoices();
    });
  }

  getInvoices() {
    this.setState({ invoice_rows: undefined });
    getInvoices(this.state.page - 1, 10, true).then((pagination) => {
      if (pagination) {
        this.setState({ invoices: pagination.invoices, pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setInvoiceRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      page: this.state.page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }
  
  openInvoice(invoice) {
    this.props.history.push(`/all_invoices/` + invoice.full_id + this.props.location.search);
  }

  getStatus(invoice) {
    if(invoice.status === "open" &&
        new Date(invoice.due_date) < new Date()) {
      return "overdue";
    }
    return invoice.status;
  }

  getService(invoice) {
    if(invoice.type === "trade_transaction" || invoice.type === "freight_transaction") {
      return Mapper.fromInvoiceType(invoice.type) + " " + StringUtil.shortId(invoice.resource_id);
    }
    if(invoice.type === "periodic_trade_transaction_settlement" ||
        invoice.type === "periodic_partner_trade_transaction_settlement" ||
        invoice.type === "periodic_freight_transaction_settlement" ||
        invoice.type === "periodic_partner_freight_transaction_settlement") {
      return Mapper.fromInvoiceType(invoice.type) + " " + invoice.resource_id + " (" + Mapper.fromCurrencySymbol(invoice.currency) + ")";
    }
    return "-";
  }

  getInvoiceHeaders() {
    return [
      { name: "Invoice #", key: "id" },
      { name: "Status", key: "status" },
      { name: "Due date", key: "due_date" },
      { name: "Total amount", key: "total_amount" },
      { name: "Service", key: "service" }
    ];
  }

  setInvoiceRows() {
    var rows = [];
    this.state.invoices.map(invoice => {
      rows.push({
        full_id: invoice.id,
        id: StringUtil.shortId(invoice.id),
        status: <span className={"badge " + Mapper.toInvoiceStatusBadge(this.getStatus(invoice))} >{Mapper.fromInvoiceStatus(this.getStatus(invoice))}</span>,
        due_date: Converter.toDate(invoice.due_date),
        total_amount: Converter.toCurrency(invoice.currency, invoice.total_amount),
        service: this.getService(invoice)
      });
    });
    this.setState({ invoice_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>All invoices</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>All invoices</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getInvoiceHeaders()}
                  rows={this.state.invoice_rows}
                  selectable={true}
                  onRowSelected={this.openInvoice.bind(this)}
                  empty_rows_text="There are no invoices"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onNavigate.bind(this)} page={this.state.page} pages={this.state.pages} />
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default Invoices;

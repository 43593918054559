import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Pagination from '../../components/pagination'
import { getInvites } from '../../services/accounts_service';
import ErrorHandler from '../../util/error_handler';
import StringUtil from '../../util/string_util';
import Converter from '../../util/converter';
import { isReadOnly, hasType, getAccountId } from '../../services/authentication_service';
import Table from "../../components/table";
import TextField from '@material-ui/core/TextField';
import alertDialog from '../../components/alert';
import ReactTimeAgo from 'react-time-ago'

class Invites extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let page = params.get('page') ? params.get('page') : 1;

    this.state = {
      page: page,
      pages: 0,
      invites: []
    }
  }

  componentDidMount() {
    this.getInvites();
  }


  onNavigate(page) {
    this.setState({ page: page }, () => {
      this.getInvites();
    });
  }

  getInvites() {
    this.setState({ invitation_rows: undefined });
    getInvites(this.state.page - 1, 10).then((pagination) => {
      if (pagination) {
        this.setState({ invites: pagination.invites, pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setInvitationsRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  openInvite(invite) {
    this.props.history.push(`/invites/` + invite.id + this.props.location.search);
  }  
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      page: this.state.page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }

  getInvitationHeaders() {
    if(hasType("admin")) {
      return [
        { name: "Company email", key: "email" },
        { name: "Invited", key: "invited_at" },
        { name: "Account type", key: "type" },
        { name: "Partner percentage", key: "partner_percentage" },
        { name: "Partner territory", key: "partner_territory" }
      ];

    }
    return [
      { name: "Company email", key: "email" },
      { name: "Invited", key: "invited_at" }
    ];
  }

  setInvitationsRows() {
    var rows = [];
    this.state.invites.map(invite => {
      if(hasType("admin")) {
        if(invite.type === "partner") {
          rows.push({
            id: invite.id,
            email: invite.email,
            invited_at: <ReactTimeAgo date={new Date(invite.invited_at)} />,
            type: StringUtil.capitalize(invite.type),
            partner_percentage: Converter.toDigits(invite.partner_percentage, 2) + "%",
            partner_territory: StringUtil.commaSeparated(invite.partner_territory.map(country => country.name))
          });
        } else {
          rows.push({
            id: invite.id,
            email: invite.email,
            invited_at: <ReactTimeAgo date={new Date(invite.invited_at)} />,
            type: StringUtil.capitalize(invite.type)
          });          
        }
      } else {
        rows.push({
          id: invite.id,
          email: invite.email,
          invited_at: <ReactTimeAgo date={new Date(invite.invited_at)} />
        });
      }
    });
    this.setState({ invitation_rows: rows });
  }

  getPartnerLink() {
    return window.location.origin + "/login?view=register&partner_id=" + getAccountId();
  }

  copyPartnerLink() {
    navigator.clipboard.writeText(this.getPartnerLink());
    alertDialog("Success", "Link copied to your clipboard.")
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Invitations</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Partner link</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <span>Use this partner link to invite new accounts, these registration will automatically be linked to your account.</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-11">
                  <TextField
                    label="Partner link"
                    variant="outlined"
                    fullWidth
                    isReadOnly={true}
                    margin="dense"
                    value={this.getPartnerLink()}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                <Button
                  className="button-icon-only button-inline end-off-input-button"
                  color="primary"
                  startIcon={<Icon>content_copy</Icon>}
                  onClick={this.copyPartnerLink.bind(this)} />
              </div>
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invitations</label>
                </div>
              </div>
            </div>
            
            { !isReadOnly() &&
              <div className="row">
                <div className="col-sm">
                  <Link className="link-button-wrapper" to={`/invites/create` + this.props.location.search}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>send</Icon>}>
                      New
                    </Button>
                  </Link>
                </div>
              </div>
            }
            
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {3}
                  headers={this.getInvitationHeaders()}
                  rows={this.state.invitation_rows}
                  selectable={true}
                  onRowSelected={this.openInvite.bind(this)}
                  empty_rows_text="There are no pending invitations"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onNavigate.bind(this)} page={this.state.page} pages={this.state.page} />
            
          </div>

        </div>
      </div >
    );
  }
}

export default Invites;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Pagination from '../../../shared/components/pagination'
import { getAccounts } from '../../../shared/services/accounts_service';
import ErrorHandler from '../../../shared/util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Table from "../../../shared/components/table";

class Accounts extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let page = params.get('page') ? params.get('page') : 1;

    this.state = {
      page: page,
      pages: 0,
      accounts: []
    }
  }

  componentDidMount() {
    this.getAccounts();
  }

  onNavigate(page) {
    this.setState({ page: page }, () => {
      this.getAccounts();
    });
  }

  getAccounts() {
    this.setState({ account_rows: undefined });
    getAccounts(this.state.page - 1, 10).then((pagination) => {
      if (pagination) {
        this.setState({ accounts: pagination.accounts, pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setAccountRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      page: this.state.page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }

  getAccountHeaders() {
    return [
      { name: "Registered name", key: "registered_name" },
      { name: "Account type", key: "type" },
      { name: "Registration number", key: "registration_number" },
      { name: "Country of incorporation", key: "country_of_incorporation" }
    ];
  }

  setAccountRows() {
    var rows = [];
    this.state.accounts.map(account => {
      rows.push({
        id: account.id,
        registered_name: account.registered_name,
        type: StringUtil.capitalize(account.type),
        country_of_incorporation: account.country_of_incorporation,
        registration_number: account.registration_number
      });
    });
    this.setState({ account_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Accounts</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Accounts</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getAccountHeaders()}
                  rows={this.state.account_rows}
                  empty_rows_text="There are no accounts"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onNavigate.bind(this)} page={this.state.page} pages={this.state.pages} />

          </div>
        </div>
      </div >
    );
  }
}

export default Accounts;

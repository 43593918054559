import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import confirmText from '../../../../shared/components/confirm_text.js'
import alertDialog from '../../../../shared/components/alert';
import confirm from '../../../../shared/components/confirm.js'
import DomUtil from '../../../../shared/util/dom_util';
import MultiDocumentUpload from '../../../../shared/components/multi_document_upload.js'
import Mapper from '../../../../shared/util/mapper'
import AsyncButton from '../../../../shared/components/async_button';
import ErrorHandler from '../../../../shared/util/error_handler';
import { getUserId } from '../../../../shared/services/authentication_service';
import { getLatestComplianceDocument } from '../../../../shared/services/compliances_service';

class PayDeposit extends React.Component {
  
  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: this.initiaState()
    };
  }

  componentDidMount() {
    this.setAuthorisedUser();
  }
  
  setAuthorisedUser() {
    var userId = getUserId();
    getLatestComplianceDocument().then((compliance_document) => {
      if(compliance_document) {
        var authorised_user = compliance_document.authorised_users.find(c => c.user_account_id === userId);
        if(authorised_user) {
          this.setState({ is_authorised: true });
        } else {
          this.setState({ is_authorised: false });
        }
      } else {
        this.setState({ is_authorised: false });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  resetState() {
    var initialValues = this.initiaState();
    var that = this;
    Object.keys(this.state.flow_patch).map(function (key) {
      if(initialValues[key]) {
        that.state.flow_patch[key] = initialValues[key];
      } else {
        that.state.flow_patch[key] = "";
      }
    });
    this.setState({ flow_patch: this.state.flow_patch });
  }
  
  initiaState() {
    return {
      status: "milestone_documents_upload",
      action: "milestone_documents_uploaded",
      required_documents: this.props.transaction.current_payment_milestone.required_documents
    };
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you have want to submit these documents?").then(() => {
      var upload_flow_patch = Mapper.toMilestoneDocumentsUpload(this.state.flow_patch);
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(upload_flow_patch).then(() => {
        this.resetState();
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  requestAmendment(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "This will allow the Seller to amend the transaction details. Payment milestones that are already paid in escrow cannot be changed and remain safe in escrow. After the amendment a new Escrow Agreement will be generated that needs to be signed. All parties need to approve the amendment request. Are you sure you want to ament the transaction? Please state why you want to amend.", { multiline: true }).then((result) => {
      if(result) {
        DomUtil.disableClass(button);
        this.props.updateTransaction({ 
          status: "milestone_documents_upload",
          action: "amendment_requested",
          reason: result
        }).then(() => {
          DomUtil.enableClass(button);
        }).catch(() => {
          DomUtil.enableClass(button);
        });
      } else {
        alertDialog("Alert", "Reason is required!")
      }
    });
  }

  documentsUpdated(documents, parent_data) {
    this.state.flow_patch.required_documents[parent_data.index].documents = documents;
    this.setState({ flow_patch: this.state.flow_patch });
  }

  getDocumentType(required_document) {
    if(required_document.type === "other") {
      return required_document.other_name;
    }
    return Mapper.fromDocumentType(required_document.type);
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Payment milestone document upload</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please upload all required documents for payment milestone #{this.props.transaction.current_payment_milestone.rank} ({this.props.transaction.current_payment_milestone.condition}).</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>
                
                {this.state.flow_patch.required_documents.map((document, index) => {
                  return (
                    <div key={document.id} className="row">
                      <div className="col-md-6">
                        <MultiDocumentUpload
                          title={this.getDocumentType(document)}
                          access="allowed_accounts_and_escrow"
                          allowed_account_ids={[ this.props.transaction.buyer.id, this.props.transaction.seller.id]}
                          allow_authorised_accounts={true}
                          required
                          documentsUpdated={this.documentsUpdated.bind(this)}
                          parent_data={{ index: index }} />
                      </div>
                    </div>
                  )
                })}

                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Submit" />
                    { this.state.is_authorised &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.requestAmendment.bind(this)}
                        icon="edit"
                        text="Request amendment" />
                    }
                    { !this.state.is_authorised &&
                      <Button
                        variant="outlined"
                        color="secondary"
                        disabled
                        startIcon={<Icon>edit</Icon>}>
                        Request amendment
                      </Button>
                    }
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default PayDeposit;

import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../../shared/components/async_button';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getContact, deleteContact } from '../../../shared/services/accounts_service';
import ErrorHandler from '../../../shared/util/error_handler';
import confirm from '../../../shared/components/confirm'
import { isReadOnly } from '../../../shared/services/authentication_service';
import LoadingText from '../../../shared/components/loading_text';

class ShowContact extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      contact_id: this.props.match.params.contact_id,
      contact: {}
    };
  }

  componentDidMount() {
    this.getContact();
  }

  getContact() {
    getContact(this.state.contact_id).then((contact) => {
      if (contact) {
        this.setState({ contact: contact });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  deleteContact(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to delete this contact?`).then((result) => {
      DomUtil.disableClass(button);
      deleteContact(this.state.contact_id).then(() => {
        this.props.history.push(`/contacts/` + this.props.location.search);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/contacts/` + this.props.location.search}>Contacts</Link></BreadcrumbItem>
            <BreadcrumbItem active>Contact</BreadcrumbItem>
          </Breadcrumb>
                
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Contact</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                { !isReadOnly() &&
                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        className="button-danger-outlined"
                        variant="outlined"
                        color="danger"
                        onClick={this.deleteContact.bind(this)}
                        icon="delete"
                        text="Delete" />
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">Company name</td>
                      <td><LoadingText text={this.state.contact.registered_name} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Country of incorporation</td>
                      <td><LoadingText text={this.state.contact.country_of_incorporation} /></td>
                    </tr>
                    <tr>
                      <td className="header-text">Registration number</td>
                      <td><LoadingText text={this.state.contact.registration_number} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default ShowContact;

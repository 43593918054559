"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Mercurion Trade Freight Transactions Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreightTransactionsApi = exports.FreightTransactionsApiFactory = exports.FreightTransactionsApiFp = exports.FreightTransactionsApiAxiosParamCreator = exports.PaymentStatus = exports.PaymentChargeStatus = exports.PaymentCharge = exports.FreightTransactionStatus = exports.FreightTransactionStateStatus = exports.FreightTransactionAction = exports.Currency = exports.AccountType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AccountType = {
    Admin: 'admin',
    Customer: 'customer',
    Escrow: 'escrow',
    Partner: 'partner'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Currency = {
    Eur: 'eur',
    Usd: 'usd'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.FreightTransactionAction = {
    FeeAccepted: 'fee_accepted',
    ComplianceConfirmed: 'compliance_confirmed',
    TransactionAgreed: 'transaction_agreed',
    FreightPaid: 'freight_paid',
    FreightReceived: 'freight_received',
    LoadedOnShip: 'loaded_on_ship',
    FundsReleased: 'funds_released',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.FreightTransactionStateStatus = {
    CarrierReviewFee: 'carrier_review_fee',
    CarrierCompliance: 'carrier_compliance',
    ShipperCompliance: 'shipper_compliance',
    BrokerCompliance: 'broker_compliance',
    CarrierAgreeTransaction: 'carrier_agree_transaction',
    ShipperAgreeTransaction: 'shipper_agree_transaction',
    BrokerAgreeTransaction: 'broker_agree_transaction',
    EscrowAgreeTransaction: 'escrow_agree_transaction',
    FreightPayment: 'freight_payment',
    FreightPaymentNotification: 'freight_payment_notification',
    LoadOnShip: 'load_on_ship',
    ReleaseCarrierFunds: 'release_carrier_funds',
    ReleaseBrokerFunds: 'release_broker_funds',
    Completed: 'completed',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.FreightTransactionStatus = {
    Details: 'details',
    Compliance: 'compliance',
    Agreement: 'agreement',
    Payment: 'payment',
    Loading: 'loading',
    Release: 'release',
    Completed: 'completed',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentCharge = {
    EscrowFee: 'escrow_fee',
    SingaporeTax: 'singapore_tax',
    ExtensionFee: 'extension_fee',
    BrokerFee: 'broker_fee'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentChargeStatus = {
    Estimation: 'estimation',
    Final: 'final'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentStatus = {
    Outstanding: 'outstanding',
    InEscrow: 'in_escrow',
    PartialFundsReleased: 'partial_funds_released',
    FundsReleased: 'funds_released'
};
/**
 * FreightTransactionsApi - axios parameter creator
 * @export
 */
exports.FreightTransactionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * calculate freight transaction charges
         * @param {FreightTransactionCalculation} freightTransactionCalculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFreightTransactionCharges: function (freightTransactionCalculation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'freightTransactionCalculation' is not null or undefined
                            common_1.assertParamExists('calculateFreightTransactionCharges', 'freightTransactionCalculation', freightTransactionCalculation);
                            localVarPath = "/transactions/freight/calculation";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(freightTransactionCalculation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create freight transaction
         * @param {FreightTransactionCreation} freightTransactionCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFreightTransaction: function (freightTransactionCreation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'freightTransactionCreation' is not null or undefined
                            common_1.assertParamExists('createFreightTransaction', 'freightTransactionCreation', freightTransactionCreation);
                            localVarPath = "/transactions/freight";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(freightTransactionCreation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get freight transaction
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransaction: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getFreightTransaction', 'id', id);
                            localVarPath = "/transactions/freight/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get freight transaction count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionCount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions/freight/count";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get freight transactions
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactions: function (page, size, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions/freight";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get freight transactions payments statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionsPaymentsStatistics: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions/freight/payment_statistics";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get freight transactions statistics
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionsStatistics: function (startDate, endDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'startDate' is not null or undefined
                            common_1.assertParamExists('getFreightTransactionsStatistics', 'startDate', startDate);
                            // verify required parameter 'endDate' is not null or undefined
                            common_1.assertParamExists('getFreightTransactionsStatistics', 'endDate', endDate);
                            localVarPath = "/transactions/freight/statistics";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (startDate !== undefined) {
                                localVarQueryParameter['start_date'] = (startDate instanceof Date) ?
                                    startDate.toISOString() :
                                    startDate;
                            }
                            if (endDate !== undefined) {
                                localVarQueryParameter['end_date'] = (endDate instanceof Date) ?
                                    endDate.toISOString() :
                                    endDate;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updated freight transaction
         * @param {string} id
         * @param {FreightTransactionPatch} freightTransactionPatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFreightTransaction: function (id, freightTransactionPatch, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateFreightTransaction', 'id', id);
                            // verify required parameter 'freightTransactionPatch' is not null or undefined
                            common_1.assertParamExists('updateFreightTransaction', 'freightTransactionPatch', freightTransactionPatch);
                            localVarPath = "/transactions/freight/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(freightTransactionPatch, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * FreightTransactionsApi - functional programming interface
 * @export
 */
exports.FreightTransactionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.FreightTransactionsApiAxiosParamCreator(configuration);
    return {
        /**
         * calculate freight transaction charges
         * @param {FreightTransactionCalculation} freightTransactionCalculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFreightTransactionCharges: function (freightTransactionCalculation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.calculateFreightTransactionCharges(freightTransactionCalculation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create freight transaction
         * @param {FreightTransactionCreation} freightTransactionCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFreightTransaction: function (freightTransactionCreation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createFreightTransaction(freightTransactionCreation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get freight transaction
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransaction: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFreightTransaction(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get freight transaction count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFreightTransactionCount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get freight transactions
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactions: function (page, size, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFreightTransactions(page, size, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get freight transactions payments statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionsPaymentsStatistics: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFreightTransactionsPaymentsStatistics(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get freight transactions statistics
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionsStatistics: function (startDate, endDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFreightTransactionsStatistics(startDate, endDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Updated freight transaction
         * @param {string} id
         * @param {FreightTransactionPatch} freightTransactionPatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFreightTransaction: function (id, freightTransactionPatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateFreightTransaction(id, freightTransactionPatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * FreightTransactionsApi - factory interface
 * @export
 */
exports.FreightTransactionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.FreightTransactionsApiFp(configuration);
    return {
        /**
         * calculate freight transaction charges
         * @param {FreightTransactionCalculation} freightTransactionCalculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFreightTransactionCharges: function (freightTransactionCalculation, options) {
            return localVarFp.calculateFreightTransactionCharges(freightTransactionCalculation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create freight transaction
         * @param {FreightTransactionCreation} freightTransactionCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFreightTransaction: function (freightTransactionCreation, options) {
            return localVarFp.createFreightTransaction(freightTransactionCreation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get freight transaction
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransaction: function (id, options) {
            return localVarFp.getFreightTransaction(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get freight transaction count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionCount: function (options) {
            return localVarFp.getFreightTransactionCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get freight transactions
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactions: function (page, size, options) {
            return localVarFp.getFreightTransactions(page, size, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get freight transactions payments statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionsPaymentsStatistics: function (options) {
            return localVarFp.getFreightTransactionsPaymentsStatistics(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get freight transactions statistics
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreightTransactionsStatistics: function (startDate, endDate, options) {
            return localVarFp.getFreightTransactionsStatistics(startDate, endDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updated freight transaction
         * @param {string} id
         * @param {FreightTransactionPatch} freightTransactionPatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFreightTransaction: function (id, freightTransactionPatch, options) {
            return localVarFp.updateFreightTransaction(id, freightTransactionPatch, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * FreightTransactionsApi - object-oriented interface
 * @export
 * @class FreightTransactionsApi
 * @extends {BaseAPI}
 */
var FreightTransactionsApi = /** @class */ (function (_super) {
    __extends(FreightTransactionsApi, _super);
    function FreightTransactionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * calculate freight transaction charges
     * @param {FreightTransactionCalculation} freightTransactionCalculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.calculateFreightTransactionCharges = function (freightTransactionCalculation, options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).calculateFreightTransactionCharges(freightTransactionCalculation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create freight transaction
     * @param {FreightTransactionCreation} freightTransactionCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.createFreightTransaction = function (freightTransactionCreation, options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).createFreightTransaction(freightTransactionCreation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get freight transaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.getFreightTransaction = function (id, options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).getFreightTransaction(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get freight transaction count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.getFreightTransactionCount = function (options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).getFreightTransactionCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get freight transactions
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.getFreightTransactions = function (page, size, options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).getFreightTransactions(page, size, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get freight transactions payments statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.getFreightTransactionsPaymentsStatistics = function (options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).getFreightTransactionsPaymentsStatistics(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get freight transactions statistics
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.getFreightTransactionsStatistics = function (startDate, endDate, options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).getFreightTransactionsStatistics(startDate, endDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updated freight transaction
     * @param {string} id
     * @param {FreightTransactionPatch} freightTransactionPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreightTransactionsApi
     */
    FreightTransactionsApi.prototype.updateFreightTransaction = function (id, freightTransactionPatch, options) {
        var _this = this;
        return exports.FreightTransactionsApiFp(this.configuration).updateFreightTransaction(id, freightTransactionPatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return FreightTransactionsApi;
}(base_1.BaseAPI));
exports.FreightTransactionsApi = FreightTransactionsApi;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { getPartnerAccount } from '../../services/accounts_service';
import ErrorHandler from '../../util/error_handler';
import LoadingText from '../../components/loading_text';
import confirm from '../../components/confirm'
import { getAccountId } from '../../services/authentication_service';
import { getAccountAuthorisations, createAccountAuthorisation } from '../../services/authorisation_service';


class Authorisation extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);

    this.state = {
      account_id: this.props.match.params.account_id,
      account: undefined,
      success_url: params.get('success_url'),
      fail_url: params.get('fail_url'),
      already_authorised: false
    };
  }

  componentDidMount() {
    this.getAccountAuthorisations();
    this.getPartnerAccount();
  }

  getPartnerAccount() {
    getPartnerAccount(this.state.account_id).then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAccountAuthorisations() {
    getAccountAuthorisations().then((authorisations) => {
      if (authorisations) {
        if(authorisations.find(a => a.id === this.state.account_id)) {
          console.log("already_authorised")
          this.setState({ already_authorised: true });
        }
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  isReady() {
    return this.state.account;
  }

  isAlreadyAuthorised() {
    return this.state.already_authorised;
  }

  accept(e) {
    e.preventDefault();
    confirm("Please confirm", `Are you sure you want to authorise ${this.state.account.registered_name}?`).then(() => {      
      createAccountAuthorisation({ id: this.state.account_id}).then(() => {
        window.open(this.state.success_url + `?account_id=${getAccountId()}`, "_self");
      }).catch(error => {
        ErrorHandler.showError(error);
      });
    });
  }

  continue(e) {
    e.preventDefault();
    window.open(this.state.success_url + `?account_id=${getAccountId()}`, "_self");
  }
  
  reject(e) {
    e.preventDefault();
    confirm("Please confirm", `Are you sure you want to decline authorisation for ${this.state.account.registered_name}?`).then(() => {
      window.open(this.state.fail_url, "_self");
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem>Authorise partner</BreadcrumbItem>
        </Breadcrumb>

        <div className="container-body">
        
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Authorisation</label>
              </div>
            </div>
          </div>
          
          {this.isReady() && !this.isAlreadyAuthorised() &&
            <div className="row">
              <div className="col-md">
                <span><b>{this.state.account.registered_name}</b> is requesting authorisation to your Mercurion account.</span>
                <br/>
                <span>This will allow them to manage your transactions through their platform.</span>
              </div>
            </div>
          }
          
          {this.isReady() && !this.isAlreadyAuthorised() &&
            <div className="row">
              <div className="col-md">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  startIcon={<Icon>check</Icon>}
                  onClick={this.accept.bind(this)} >
                    Accept
                </Button>
                <Button
                  className="button-danger-outlined"
                  variant="outlined"
                  color="danger"
                  startIcon={<Icon>clear</Icon>}
                  onClick={this.reject.bind(this)} >
                    Decline
                </Button>
              </div>
            </div>
          }
          
          {this.isReady() && this.isAlreadyAuthorised() &&
            <div className="row">
              <div className="col-md">
                <span><b>{this.state.account.registered_name}</b> already has authorisation to your Mercurion account.</span>
              </div>
            </div>
          }
          
          {this.isReady() && this.isAlreadyAuthorised() &&
            <div className="row">
              <div className="col-md">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  startIcon={<Icon>check</Icon>}
                  onClick={this.continue.bind(this)} >
                    Continue
                </Button>
              </div>
            </div>
          }
          
          {!this.isReady() &&
            <div className="row">
              <div className="col-md">
                <LoadingText rows={3} />
              </div>
            </div>
          }
        
        </div>

      </div>
    </div>
    );
  }
}

export default Authorisation;

import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import confirm from '../../../../shared/components/confirm'
import Mapper from '../../../../shared/util/mapper';
import DocumentUpload from '../../../../shared/components/document_upload';
import ShareholderPersonForm from './shareholder_person_form';
import ShareholderCorporateForm from './shareholder_corporate_form';

class ShareholdersForm extends React.Component {

  constructor(props) {
    super(props)
    
    var shareholders = this.props.compliance.shareholders;
    if(shareholders) {
      for(const shareholder of shareholders) {
        shareholder.key = this.getUniqueKey();
      }
    } else {
      shareholders = [];
    }

    this.state = {
      big_shareholders: this.props.compliance.big_shareholders,
      company_structure_document: this.props.compliance.company_structure_document ? this.props.compliance.company_structure_document : {},
      shareholders: shareholders
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key], true);
    });
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);

    if(e.target.name === "big_shareholders") {
      if(!this.isTrue(value)) {
        this.setState({ company_structure_document: {} });
        this.props.propertyUpdate("company_structure_document", {});
        this.props.propertyUpdate("company_structure_document", {});
        this.setState({ shareholders: [] });
        this.props.propertyUpdate("shareholders", []);
      }
    }
  }
  
  propertyUpdate(index, key, value, initial) {
    this.state.shareholders[index][key] = value;
    this.setState({ shareholders: this.state.shareholders });
    this.props.propertyUpdate("shareholders", this.state.shareholders, initial);
  }

  addToList() {
    this.state.shareholders.push({ key: this.getUniqueKey() });
    this.setState({ shareholders: this.state.shareholders });
    this.props.propertyUpdate("shareholders", this.state.shareholders);
  }

  removeFromList(index) {
    confirm("Please confirm", "Are you sure you want to remove this entry?").then(() => {
      this.state.shareholders.splice(index, 1);
      this.setState({ shareholders: this.state.shareholders });
      this.props.propertyUpdate("shareholders", this.state.shareholders);
    });
  }
  
  handleListChange(index, e) {
    var value = e.target.value === "" ? null : e.target.value;
    if(e.target.name === "type") {
      this.state.shareholders[index] = { key: this.getUniqueKey() };
    }
    this.state.shareholders[index][e.target.name] = value;
    this.setState({ shareholders: this.state.shareholders });
    this.props.propertyUpdate("shareholders", this.state.shareholders);
  }

  documentUploaded(id, file_name, parent_data) {
    if(!this.isEmpty(parent_data.index)) {
      this.state[parent_data.property][parent_data.index][parent_data.document].id = id;
      this.state[parent_data.property][parent_data.index][parent_data.document].file_name = file_name;
    } else {
      this.state[parent_data.property].id = id;
      this.state[parent_data.property].file_name = file_name;
    }
    this.setState({ [parent_data.property]: this.state[parent_data.property] });
    this.props.propertyUpdate(parent_data.property, this.state[parent_data.property]);
  }

  documentCleared(parent_data) {
    if(parent_data.index) {
      this.state[parent_data.property][parent_data.index][parent_data.document].id = undefined;
      this.state[parent_data.property][parent_data.index][parent_data.document].file_name = undefined;
    } else {
      this.state[parent_data.property].id = undefined;
      this.state[parent_data.property].file_name = undefined;
    }
    this.setState({ [parent_data.property]: this.state[parent_data.property] });
    this.props.propertyUpdate(parent_data.property, this.state[parent_data.property]);
  }

  isTrue(value) {
    return value === "true" || value === true;
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>

        <div className="row">
          <div className="col-md-12">
            <span>Are there shareholders with at least 25% direct and/or indirect holdings?</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.big_shareholders) } >Shareholders *</InputLabel>
              <Select
                native
                required
                label="Shareholders *"
                value={this.state.big_shareholders}
                name="big_shareholders"
                onChange={this.handleChange.bind(this)} >
                  <option key="big_shareholders_empty" value=""></option>
                  {Object.keys(Mapper.booleans()).map(boolean => {
                    return (<option key={"big_shareholders_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>
        
        { this.isTrue(this.state.big_shareholders) &&
          <div className="row">
            <div className="col-md-12">
              <span>For a company with more than 2 layers of direct and/or indirect holdings, please provide a graphical presentation of your company’s ownership structure showing shareholders, down to the natural persons, with at least 25% indirect and/or direct holdings.</span>
            </div>
          </div>
        }

        { this.isTrue(this.state.big_shareholders) &&
          <div className="row">
            <div className="col-md-4">
              <DocumentUpload
                title="Company ownership structure"
                access="escrow_only"
                allowed_account_ids={[]}
                allow_authorised_accounts={false}
                required={false}
                documentUploaded={this.documentUploaded.bind(this)}
                documentCleared={this.documentCleared.bind(this)}
                document_id={this.state.company_structure_document.id}
                file_name={this.state.company_structure_document.file_name}
                parent_data={{ property: "company_structure_document" }} />
            </div>
          </div>
        }
        
        { this.isTrue(this.state.big_shareholders) &&
          <div className="row">
            <div className="col-md-12">
              <span>Please provide information of these shareholders</span>
            </div>
          </div>
        }
        
        {this.state.shareholders.map((shareholder, index) => {
          return (
            <div key={shareholder.key} className="list-row-item" >
              <span className="list-row-item-header-text">Shareholder</span>
              
              <div>
                <div className="col-md-12">
                  <Button
                    className="button-icon-only button-inline clear-list-row-item-button"
                    color="secondary"
                    type="button"
                    onClick={this.removeFromList.bind(this, index)}
                    startIcon={<Icon>clear</Icon>} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel shrink={!this.isEmpty(shareholder.type) } >Type *</InputLabel>
                    <Select
                      native
                      required
                      label="Type *"
                      value={shareholder.type}
                      name="type"
                      onChange={this.handleListChange.bind(this, index)} >
                        <option key={"shareholders_"+this.props.index+"empty"} value=""></option>
                        {Object.keys(Mapper.shareholderTypes()).map(type => {
                          return (<option key={"shareholders_"+this.props.index+type} value={type}>{Mapper.fromshareholderType(type)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              
              {shareholder.type === "natural_person" &&
                <ShareholderPersonForm shareholder={shareholder} index={index} propertyUpdate={this.propertyUpdate.bind(this)} countries={this.props.countries} />
              }

              {shareholder.type === "corporate_entity" &&
                <ShareholderCorporateForm shareholder={shareholder} index={index} propertyUpdate={this.propertyUpdate.bind(this)} countries={this.props.countries} />
              }
            </div>
          );
        })}
        
        { this.isTrue(this.state.big_shareholders) &&
          <div className="row">
            <div className="col-md-12">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.addToList.bind(this)}
                startIcon={<Icon>add</Icon>}>
                Add shareholder
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ShareholdersForm;

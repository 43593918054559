import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Mapper from '../../../util/mapper';
import Converter from '../../../util/converter';

class TransportTab extends React.Component {

  openMap(e) {
    e.preventDefault();
    var url = this.getTrackingValue("live_map_url");
    window.open(url, "_blank");
  }

  getTrackingValue(property) {
    return this.getTrackingValue(property, false);
  }

  getTrackingValue(property, placeholder) {
    if(!this.props.transaction.tracking || !this.props.transaction.tracking[property]) {
      if(placeholder) {
        return <i>Not available</i>
      }
      return;
    }
    return this.props.transaction.tracking[property];
  }

  getTrackingDate(property, placeholder) {
    if(!this.props.transaction.tracking || !this.props.transaction.tracking[property]) {
      if(placeholder) {
        return <i>Not available</i>
      }
      return;
    }
    return Converter.toDate(this.props.transaction.tracking[property]);
  }

  getCarrier() {
    var carrier = this.getTrackingValue("carrier");
    if(!carrier) {
      return <i>Not available</i>
    }
    return carrier.name;
  }

  getVesselInformation() {
    var vessel = this.getTrackingValue("vessel");
    var veselImo = this.getTrackingValue("vessel_imo") ;
    if(!vessel) {
      return <i>Not available</i>
    }
    if(veselImo) {
      return vessel + " (" + this.getTrackingValue("vessel_imo") + ")";
    }
    return vessel + " (unknown)";
  }

  getTrackingDocumentType() {
    var trackingDocumentType = this.getTrackingValue("tracking_document_type");
    if(!trackingDocumentType) {
      return "Tracking document";
    }
    return Mapper.fromTrackingDocumentType(trackingDocumentType);
  }

  getTrackingStatus() {
    var status = this.getTrackingValue("status");
    if(!status) {
      return "unknown";
    }
    return status;

  }

  getPortOfLoading() {
    var portOfLoading = this.getTrackingValue("port_of_loading");
    if(!portOfLoading) {
      return <i>Not available</i>
    }
    return portOfLoading + ", " + this.getTrackingValue("from_country");
  }

  getPortOfDischarge() {
    var portOfDischarge = this.getTrackingValue("port_of_discharge");
    if(!portOfDischarge) {
      return <i>Not available</i>
    }
    return portOfDischarge + ", " + this.getTrackingValue("to_country");
  }

  getTrackingMilestoneStatus(status) {
    if(!status) {
      return "unknown";
    }
    return status;

  }

  render() {
    return (
      <div>
        { !this.props.transaction.tracking &&
          <div className="row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="info">Container tracking is not available yet, it will be once the shipment is in transit and the cargo is loaded on the ship.</Alert>
            </div>
          </div>
        }
        
        { this.getTrackingStatus() === "skipped" &&
          <div className="row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">Container tracking was skipped by the Seller, the platform will not track the shipment.</Alert>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-sm-12">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td className="header-text">Carrier</td>
                  <td>{this.getCarrier()}</td>
                </tr>
                <tr>
                  <td className="header-text">Vessel (IMO)</td>
                  <td>{this.getVesselInformation()}</td>
                </tr>
                <tr>
                  <td className="header-text">Container Number</td>
                  <td>{this.getTrackingValue("tracking_number", true)}</td>
                </tr>
                <tr>
                  <td className="header-text">Initial ETA</td>
                  <td>{this.getTrackingDate("initial_eta", true)}</td>
                </tr>
                <tr>
                  <td className="header-text">Status</td>
                  <td><span className={"badge " + Mapper.toTrackingStatusBadge(this.getTrackingStatus())}>{Mapper.fromTrackingStatus(this.getTrackingStatus())}</span></td>
                </tr>
                <tr>
                  <td className="header-text">Map</td>
                  { this.getTrackingValue("live_map_url") &&
                    <td>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Icon>launch</Icon>}
                        onClick={this.openMap.bind(this)} >
                        Open map
                      </Button>
                    </td>
                  }
                  { !this.getTrackingValue("live_map_url") &&
                    <td>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled
                        startIcon={<Icon>launch</Icon>} >
                        Open map
                      </Button>
                    </td>
                  }
                </tr>
                <tr className="row-no-top-border">
                  <td className="header-text">Milestone</td>
                  <td className="header-text">Location</td>
                  <td className="header-text">Date</td>
                  <td className="header-text">Status</td>
                </tr>
                <tr className="row-no-top-border">
                  <td>Load on Board</td>
                  <td>{this.getPortOfLoading()} (POL)</td>
                  <td>{this.getTrackingDate("loading_date", true)}</td>
                  <td><span className={"badge " + Mapper.toTrackingMilestoneStatusBadge(this.getTrackingMilestoneStatus(this.getTrackingValue("loading_status")))}>{Mapper.fromTrackingMilestoneStatus(this.getTrackingMilestoneStatus(this.getTrackingValue("loading_status")))}</span></td>
                </tr>
                <tr>
                  <td>Departure</td>
                  <td>{this.getPortOfLoading()} (POL)</td>
                  <td>{this.getTrackingDate("departure_date", true)}</td>
                  <td><span className={"badge " + Mapper.toTrackingMilestoneStatusBadge(this.getTrackingMilestoneStatus(this.getTrackingValue("departure_status")))}>{Mapper.fromTrackingMilestoneStatus(this.getTrackingMilestoneStatus(this.getTrackingValue("departure_status")))}</span></td>
                </tr>
                <tr>
                  <td>Arrival</td>
                  <td>{this.getPortOfDischarge()} (POD)</td>
                  <td>{this.getTrackingDate("arrival_date", true)}</td>
                  <td><span className={"badge " + Mapper.toTrackingMilestoneStatusBadge(this.getTrackingMilestoneStatus(this.getTrackingValue("arrival_status")))}>{Mapper.fromTrackingMilestoneStatus(this.getTrackingMilestoneStatus(this.getTrackingValue("arrival_status")))}</span></td>
                </tr>
                <tr>
                  <td>Discharge</td>
                  <td>{this.getPortOfDischarge()} (POD)</td>
                  <td>{this.getTrackingDate("discharge_date", true)}</td>
                  <td><span className={"badge " + Mapper.toTrackingMilestoneStatusBadge(this.getTrackingMilestoneStatus(this.getTrackingValue("discharge_status")))}>{Mapper.fromTrackingMilestoneStatus(this.getTrackingMilestoneStatus(this.getTrackingValue("discharge_status")))}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TransportTab;

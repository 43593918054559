import React from 'react';
import alertDialog from '../../../../shared/components/alert';
import Button from '@material-ui/core/Button';
import { getDocument } from '../../../../shared/services/documents_service';
import { downloadComplianceDocumentAttachments } from '../../../../shared/services/compliances_service';
import { getUserId, isReadOnly } from '../../../../shared/services/authentication_service';
import fileDownload from 'js-file-download';
import confirm from '../../../../shared/components/confirm'
import ErrorHandler from '../../../../shared/util/error_handler';
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';
import Mapper from '../../../../shared/util/mapper'
import Converter from '../../../../shared/util/converter'

class ComplianceViewer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      authorised: this.isAuthorised(),
      compliance_document: this.props.compliance_document,
      compliance_document_url: undefined
    };
  }

  isAuthorised() {
    var userId = getUserId();
    return this.props.compliance_document.authorised_users.find(c => c.user_account_id === userId);
  }

  componentDidMount() {
    window.scroll({top: 0, left: 0, behavior: 'smooth' }) 
    this.getDocument();
  }

  getDocument() {
    getDocument(this.state.compliance_document.document.id).then((document) => {
      document = document.slice(0, document.size, "application/pdf")
      const fileURL = URL.createObjectURL(document);
      this.setState({ compliance_document_url: fileURL });      
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  downloadDocument(id, file_name) {
    getDocument(id).then(blob => {
      fileDownload(blob, file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  downloadAttachments(e) {
    var file_name = this.state.compliance_document.document.file_name.replace(".pdf", "") + " - attachments.zip"
    var form = e.currentTarget;
    DomUtil.disableClass(form);

    downloadComplianceDocumentAttachments(this.state.compliance_document.id).then(blob => {
      fileDownload(blob, file_name);
      DomUtil.enableClass(form);
    }).catch(error => {
      DomUtil.enableClass(form);
      alertDialog("Alert", "Failed to download attachments.")
    });
  }

  changeComplianceDocument(e) {
    e.preventDefault();
    confirm("Please confirm", "Are you sure you want to change the KYC document?").then(() => {
      this.props.changeComplianceDocument();
    });
  }
  
  render() {    
    return (
      <div>

        <div className="container-body">
          
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Compliance</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td className="header-text">Status</td>
                    <td><span className={"badge " + Mapper.toComplianceDocumentStatusBadge(this.state.compliance_document.status)} >{Mapper.fromComplianceDocumentStatus(this.state.compliance_document.status)}</span></td>
                  </tr>
                  <tr>
                    <td className="header-text">Signed at</td>
                    <td>{Converter.toDatetime(this.state.compliance_document.signed_at)}</td>
                  </tr>
                  <tr>
                    <td className="header-text">Approved at</td>
                    <td>{Converter.toDatetime(this.state.compliance_document.approved_at)}</td>
                  </tr>
                  <tr>
                    <td className="header-text">Expires at</td>
                    <td>{Converter.toDatetime(this.state.compliance_document.expires_at)}</td>
                  </tr>
                  <tr>
                    <td className="header-text">Compliance document</td>
                    <td>
                      <div>
                        <span className="icon-span"><i className="far fa-file"/>&nbsp;</span>
                        <Button
                          className="download-document-button"
                          color="primary"
                          onClick={this.downloadDocument.bind(this, this.state.compliance_document.document.id, this.state.compliance_document.document.file_name)} >
                          {this.state.compliance_document.document.file_name}
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="header-text">Attachments</td>
                    <td>
                      <AsyncButton
                        variant="outlined"
                        color="primary"
                        disabled={this.props.read_only}
                        onClick={this.downloadAttachments.bind(this)}
                        icon="get_app"
                        text="Download" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>KYC document</label>
              </div>
            </div>
          </div>


          {this.state.compliance_document.status === "submitted" &&
            <div>
              <div className="row">
                <div className="col-md">
                  <span>The Escrow Agent is reviewing your KYC document.</span>
                  <br/>
                  <span>You will receive a notification once the review has been completed.</span>
                </div>
              </div>
              <br/>
            </div>
          }

          {this.state.compliance_document.status === "approved" &&
            <div>
              { !isReadOnly() &&
                <div className="row">
                  <div className="col-md">            
                    <AsyncButton
                      variant="outlined"
                      color="primary"
                      onClick={this.changeComplianceDocument.bind(this)}
                      icon="edit"
                      text="Update" />
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-md">
                  <span>Your compliance has been approved and will be valid for 6 months.</span>
                  <br/>
                  <span>After this period the KYC form needs to be reviewed and updated where necessary.</span>
                  <br/>
                  <span>If anything changes in the meantime, please update the document accordingly.</span>
                </div>
              </div>
              <br/>
            </div>
          }

          <div className="row">
            <div className="col-md">
              <iframe className="pdf-viewer" src={this.state.compliance_document_url} type="application/pdf" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComplianceViewer;

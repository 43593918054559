import React from 'react';
import ReactTimeAgo from 'react-time-ago'
import Mapper from '../../../util/mapper';
import StringUtil from '../../../util/string_util';
import LoadingText from '../../../components/loading_text';

class GeneralTab extends React.Component {

  render() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <table className="table table-details" >
            <tbody>
              <tr className="row-no-top-border-and-padding">
                <td style={{width: "180px"}} className="header-text">ID</td>
                <td ><LoadingText text={this.props.transaction.id ? StringUtil.shortId(this.props.transaction.id) : undefined} /></td>
              </tr>
              <tr>
                <td style={{width: "180px"}} className="header-text">Carrier</td>
                <td ><LoadingText text={this.props.transaction.carrier ? this.props.transaction.carrier.registered_name : undefined} /></td>
              </tr>
              <tr>
                <td className="header-text">Charterer</td>
                <td ><LoadingText text={this.props.transaction.charterer ? this.props.transaction.charterer.registered_name : undefined} /></td>
              </tr>
              {this.props.transaction.broker &&
                <tr>
                  <td className="header-text">Broker</td>
                  <td ><LoadingText text={this.props.transaction.broker ? this.props.transaction.broker.registered_name : undefined} /></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="col-sm-6">
          <table className="table table-details" >
            <tbody>
              <tr className="row-no-top-border-and-padding">
                <td className="header-text">Status</td>
                <td ><LoadingText text={this.props.transaction.status ? <span className={"badge " + Mapper.toFlowParentStatusBadge(this.props.transaction.status)} >{Mapper.fromFlowParentStatus(this.props.transaction.status)}</span> : undefined} /></td>
              </tr>
              <tr>
                <td className="header-text">Created</td>
                <td ><LoadingText text={this.props.transaction.created_at ? <ReactTimeAgo date={new Date(this.props.transaction.created_at)} /> : undefined} /></td>
              </tr>
              <tr>
                <td className="header-text">Last updated</td>
                <td ><LoadingText text={this.props.transaction.updated_at ? <ReactTimeAgo date={new Date(this.props.transaction.updated_at)} /> : undefined} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default GeneralTab;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class Support extends React.Component {

  render() {
    return (
      <div>
                
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Support</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Support</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <span>If you have any questions or need help, please contact support at <a href="mailto:support@mercuriontrade.com">support@mercuriontrade.com</a></span>
              </div>
            </div>

          </div>
          
        </div>
      </div >
    );
  }
}

export default Support;

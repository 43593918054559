import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Pagination from '../../components/pagination'
import { getComplianceDocuments } from '../../services/compliances_service';
import ErrorHandler from '../../util/error_handler';
import StringUtil from '../../util/string_util';
import Mapper from '../../util/mapper';
import Table from "../../components/table";
import { hasType } from '../../services/authentication_service';

class Compliances extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let page = params.get('page') ? params.get('page') : 1;

    this.state = {
      page: page,
      pages: 0,
      compliance_documents: []
    }
  }

  componentDidMount() {
    this.getComplianceDocuments();
  }

  onNavigate(page) {
    this.setState({ page: page }, () => {
      this.getComplianceDocuments();
    });
  }

  getComplianceDocuments() {
    this.setState({ compliance_documents_rows: undefined });
    getComplianceDocuments(this.state.page - 1, 10).then((pagination) => {
      if (pagination) {
        this.setState({ compliance_documents: pagination.compliance_documents, pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setComplianceDocumentsRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      page: this.state.page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }
  
  openComplianceDocument(compliance_document) {
    this.props.history.push(`/compliances/` + compliance_document.full_id + this.props.location.search);
  }

  getStatus(compliance_document) {
    if(new Date(compliance_document.expires_at) < new Date()) {
      return "expired";
    }
    return compliance_document.status;
  }

  getComplianceDocumentHeaders() {
    return [
      { name: "", key: "action_required", className: "action-required-column" },
      { name: "ID", key: "id" },
      { name: "Status", key: "status" },
      { name: "Company name", key: "registered_name" },
      { name: "Registration number", key: "registration_number" },
      { name: "Country of incorporation", key: "country_of_incorporation" }
    ];
  }

  actionRequired(compliance_document) {
    if(hasType("escrow")) {
      return compliance_document.status === "submitted";
    }
    return false;
  }

  setComplianceDocumentsRows() {
    var rows = [];
    this.state.compliance_documents.map(compliance_document => {
      rows.push({
        full_id: compliance_document.id,
        action_required: this.actionRequired(compliance_document) ? (<i style={{fontSize: "8px"}} className="fas fa-circle"></i>) : "",
        id: StringUtil.shortId(compliance_document.id),
        status: <span className={"badge " + Mapper.toComplianceDocumentStatusBadge(this.getStatus(compliance_document))} >{Mapper.fromComplianceDocumentStatus(this.getStatus(compliance_document))}</span>,
        registered_name: compliance_document.account.registered_name,
        registration_number: compliance_document.account.registration_number,
        country_of_incorporation: compliance_document.account.country_of_incorporation,
      });
    });
    this.setState({ compliance_documents_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Compliances</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Compliances</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getComplianceDocumentHeaders()}
                  rows={this.state.compliance_documents_rows}
                  selectable={true}
                  onRowSelected={this.openComplianceDocument.bind(this)}
                  empty_rows_text="There are no compliances"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onNavigate.bind(this)} page={this.state.page} pages={this.state.pages} />
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default Compliances;

import ParentTabRenderer from '../../../../shared/pages/trade-transactions/tabs/parent_tab_renderer';

class TabRenderer extends ParentTabRenderer {

  constructor(props) {
    super(props)

    this.state = {
      current_tab: "general",
      tabs: [ "general", "details", "escrow", "payments", "transport", "tracking" ],
      read_only: true
    };
  }
}

export default TabRenderer;

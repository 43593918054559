import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Pagination from '../../../shared/components/pagination'
import { getContacts, getInvites } from '../../../shared/services/accounts_service';
import ErrorHandler from '../../../shared/util/error_handler';
import { isReadOnly } from '../../../shared/services/authentication_service';
import Table from "../../../shared/components/table";

class Contacts extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let contacts_page = params.get('contacts_page') ? params.get('contacts_page') : 1;
    let invites_page = params.get('invites_page') ? params.get('invites_page') : 1;

    this.state = {
      invites_page: invites_page,
      invites_pages: 0,
      invites: [],
      contacts_page: contacts_page,
      contacts_pages: 0,
      contacts: []
    }
  }

  componentDidMount() {
    this.getContacts();
    this.getInvites();
  }

  onInviteNavigate(page) {
    this.setState({ invites_page: page }, () => {
      this.getInvites();
    });
  }

  onContactsNavigate(page) {
    this.setState({ contacts_page: page }, () => {
      this.getContacts();
    });
  }

  getInvites() {
    this.setState({ invitation_rows: undefined });
    getInvites(this.state.invites_page - 1, 10).then((pagination) => {
      if (pagination) {
        this.setState({ invites: pagination.invites, invites_pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setInvitationsRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getContacts() {
    this.setState({ contact_rows: undefined });
    getContacts(this.state.contacts_page - 1, 10).then((pagination) => {
      if (pagination) {
        this.setState({ contacts: pagination.accounts, contacts_pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setContactsRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  openInvite(invite) {
    this.props.history.push(`/invites/` + invite.id + this.props.location.search);
  }

  openContact(contact) {
    this.props.history.push(`/contacts/` + contact.id + this.props.location.search);
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      contacts_page: this.state.contacts_page,
      invites_page: this.state.invites_page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }

  getInvitationHeaders() {
    return [
      { name: "Pending invitations", key: "email" }
    ];
  }

  setInvitationsRows() {
    var rows = [];
    this.state.invites.map(invite => {
      rows.push({
        id: invite.id,
        email: invite.email
      });
    });
    this.setState({ invitation_rows: rows });
  }

  getContactHeaders() {
    return [
      { name: "Company name", key: "registered_name" },
      { name: "Country of incorporation", key: "country_of_incorporation" },
      { name: "Registration number", key: "registration_number" }
    ];
  }

  setContactsRows() {
    var rows = [];
    this.state.contacts.map(contact => {
      rows.push({
        id: contact.id,
        registered_name: contact.registered_name,
        country_of_incorporation: contact.country_of_incorporation,
        registration_number: contact.registration_number
      });
    });
    this.setState({ contact_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Contacts</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Note</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <span>
                  A counterparty for a transaction can be selected from the list of contacts below.
                  Before a new counterpary can be selected for a transaction, it need to be added to the list of contacts.
                  Contacts can be added by their country of incorporation and company registration number, and must have their own account on the platform.
                  If a counterparty does not yet have an account on the platform you can send them an invite.
                </span>
              </div>
            </div>            
          </div>

          <div className="container-body">

           <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invitations</label>
                </div>
              </div>
            </div>

            { !isReadOnly() &&
              <div className="row">
                <div className="col-sm">
                  <Link className="link-button-wrapper" to={`/invites/create` + this.props.location.search}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>send</Icon>}>
                      Invite
                    </Button>
                  </Link>
                </div>
              </div>
            }
            
            <div className="row">
              <div className="col-sm-12">
                <Table
                  loading_rows = {3}
                  headers={this.getInvitationHeaders()}
                  rows={this.state.invitation_rows}
                  selectable={true}
                  onRowSelected={this.openInvite.bind(this)}
                  empty_rows_text="There are no pending invitations"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onInviteNavigate.bind(this)} page={this.state.invites_page} pages={this.state.invites_pages} />
            
          </div>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Contacts</label>
                </div>
              </div>
            </div>

            { !isReadOnly() &&
              <div className="row">
                <div className="col-sm">
                  <Link className="link-button-wrapper" to={`/contacts/create` + this.props.location.search}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>add</Icon>}>
                      Add
                    </Button>
                  </Link>
                </div>
              </div>
            }
            
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {5}
                  headers={this.getContactHeaders()}
                  rows={this.state.contact_rows}
                  selectable={true}
                  onRowSelected={this.openContact.bind(this)}
                  empty_rows_text="There are no contacts"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onContactsNavigate.bind(this)} page={this.state.contacts_page} pages={this.state.contacts_pages} />
            
          </div>

        </div>
      </div >
    );
  }
}

export default Contacts;

import { PropertiesApi } from "generated-admin-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new PropertiesApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getProperties() {
  return API.getProperties(getHeaders()).then(handleResponse);
}

export function updateProperties(properties) {
  return API.updateProperties(properties, getHeaders()).then(handleResponse);
}

import React from 'react';

class Compliance extends React.Component {

  getParties() {
    var parties = [];
    if(this.props.transaction.states.some(s => s.status === "carrier_compliance")) {
      parties.push("Carrier");
    }
    if(this.props.transaction.states.some(s => s.status === "broker_compliance")) {
      parties.push("Broker");
    }
    if(this.props.transaction.states.some(s => s.status === "charterer_compliance")) {
      parties.push("Charterer");
    }

    if(parties.length === 3) {
      return `${parties[0]}, ${parties[1]} and ${parties[2]}`;
    }
    if(parties.length === 2) {
      return `${parties[0]} and ${parties[1]}`;
    }
    if(parties.length === 1) {
      return parties[0];
    }
    return "parties"
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Awaiting KYC submission</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for {this.getParties()} to complete KYC requirements.</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Compliance;

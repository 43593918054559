import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { createContact } from '../../../shared/services/accounts_service';
import { getCountries } from '../../../shared/services/countries_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper';
import LoadingSelectField from "../../../shared/components/loading_select_field";

class CreateContact extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      countries: undefined,
      contact: { }
    };
  }

  componentWillMount() {
    this.getCountries();
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  handleChange(e) {
    this.state.contact[e.target.name] = e.target.value;
    this.setState({ contact: this.state.contact });
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.contact.country = newValue;
    } else {
      this.state.contact.country = { name: "" };
    }
    this.setState({ contact: this.state.contact });
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  createContact(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var contact_creation = Mapper.toContactCreation(this.state.contact);
    createContact(contact_creation).then(() => {
      this.props.history.push(`/contacts/` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={`/contacts/` + this.props.location.search}>Contacts</Link></BreadcrumbItem>
          <BreadcrumbItem active>Add contact</BreadcrumbItem>
        </Breadcrumb>
              
        <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Add contact</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form autoComplete="off" onSubmit={this.createContact.bind(this)}>

                  <div className="row">
                    <div className="col-md-4">
                      { this.state.countries &&
                        <Autocomplete
                          key="country_code"
                          options={this.state.countries}
                          groupBy={this.firstLetter.bind(this)}
                          getOptionLabel={(option) => option.name}
                          value={this.state.contact.country}
                          onChange={this.handleAutoCompleteChange.bind(this)}
                          renderInput={(params) => 
                            <TextField {...params}
                              label="Country of incorporation"
                              variant="outlined"
                              required
                              fullWidth
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />                      
                          }
                        />
                      }
                      { !this.state.countries &&
                        <LoadingSelectField
                          label="Country of incorporation"
                          shrink={false}
                        />
                      }
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        label="Company registration number"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="registration_number"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Add" />
                    </div>
                  </div>

                </form>

              </div>
            </div>
          
          </div>

        </div>
      </div>
    );
  }
}

export default CreateContact;

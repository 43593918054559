import React from 'react';

class ComplianceRejected extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>KYC document</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Your KYC document has been rejected by the Escrow Agent.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ComplianceRejected;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Pagination from '../../../shared/components/pagination'
import { getInvites } from '../../../shared/services/accounts_service';
import ErrorHandler from '../../../shared/util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Converter from '../../../shared/util/converter';
import Table from "../../../shared/components/table";
import ReactTimeAgo from 'react-time-ago'

class Invites extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let page = params.get('page') ? params.get('page') : 1;

    this.state = {
      page: page,
      pages: 0,
      invites: []
    }
  }

  componentDidMount() {
    this.getInvites();
  }


  onNavigate(page) {
    this.setState({ page: page }, () => {
      this.getInvites();
    });
  }

  getInvites() {
    this.setState({ invitation_rows: undefined });
    getInvites(this.state.page - 1, 10, true).then((pagination) => {
      if (pagination) {
        this.setState({ invites: pagination.invites, pages: pagination.pages }, () => {
          this.updateSearchParams();
          this.setInvitationsRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  updateSearchParams() {
    var search = "?" + new URLSearchParams({
      page: this.state.page,
    }).toString();
    
    this.props.history.replace({
      search: search
    });
  }

  getInvitationHeaders() {
    return [
      { name: "Company email", key: "email" },
      { name: "Invited by", key: "invited_by" },
      { name: "Invited", key: "invited_at" },
      { name: "Account type", key: "type" },
      { name: "Partner percentage", key: "partner_percentage" },
      { name: "Partner territory", key: "partner_territory" }
    ];
  }

  setInvitationsRows() {
    var rows = [];
    this.state.invites.map(invite => {
      if(invite.type === "partner") {
        rows.push({
          id: invite.id,
          email: invite.email,
          invited_by: invite.invited_by,
          invited_at: <ReactTimeAgo date={new Date(invite.invited_at)} />,
          type: StringUtil.capitalize(invite.type),
          partner_percentage: Converter.toDigits(invite.partner_percentage, 2) + "%",
          partner_territory: StringUtil.commaSeparated(invite.partner_territory.map(country => country.name))
        });
      } else {
        rows.push({
          id: invite.id,
          email: invite.email,
          invited_by: invite.invited_by,
          invited_at: <ReactTimeAgo date={new Date(invite.invited_at)} />,
          type: StringUtil.capitalize(invite.type)
        });          
      }
    });
    this.setState({ invitation_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>All invitations</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>All invitations</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows = {3}
                  headers={this.getInvitationHeaders()}
                  rows={this.state.invitation_rows}
                  selectable={false}
                  empty_rows_text="There are no invitations"
                />
              </div>
            </div>

            <Pagination onNavigate={this.onNavigate.bind(this)} page={this.state.page} pages={this.state.page} />
            
          </div>

        </div>
      </div >
    );
  }
}

export default Invites;

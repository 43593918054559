import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SignatureCanvas from 'react-signature-canvas'

class Signature extends React.Component {
  signature = {}
  
  constructor(props) {
    super(props)
    this.handleResize = this.handleResize.bind(this);

    this.state = {
      contains_signature: false
    };

    window.addEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.clearSignature();
  }

  clearSignature() {
    this.setState({ contains_signature: false });
    if(this.signature) {
      this.signature.clear();
    }
    this.props.signatureCompleted(undefined);
  }

  onEnd() {
    this.setState({ contains_signature: true });
    this.props.signatureCompleted(this.signature.getTrimmedCanvas().toDataURL('image/png'));
  }

  render() {
    return (
      <div>
        <SignatureCanvas
          minWidth={1}
          maxWidth={1}
          canvasProps={{ className: "signature-canvas" }}
          ref={(ref) => { this.signature = ref }}
          onEnd={this.onEnd.bind(this)}
        />
        <Button
          className="signature-canvas-clear"
          variant="outlined"
          color="primary"
          disabled={!this.state.contains_signature}
          onClick={this.clearSignature.bind(this)}
          startIcon={<Icon>clear</Icon>}>
          Clear
        </Button>
      </div>
    );
  }
}

export default Signature;

import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mapper from '../../../../shared/util/mapper';
import Converter from '../../../../shared/util/converter'
import DocumentTypeField from '../../../../shared/components/document_type_field'

class PaymentMilestonesForm extends React.Component {

  constructor(props) {
    super(props)

    var payment_milestones = [];
    if(this.props.transaction.payment_milestones && this.props.transaction.payment_milestones.length > 0) {
      this.props.transaction.payment_milestones.splice(this.props.transaction.payment_milestones.length - 1, 1); // Remove the final payment milestone
      for(const payment_milestone of this.props.transaction.payment_milestones) {
        payment_milestone.key = this.getUniqueKey();
        payment_milestones.push(payment_milestone);
      }
    }
    
    this.state = {
      payment_milestones: payment_milestones,
      currency: this.props.transaction.currency,
      commercial_invoice_amount: this.props.transaction.commercial_invoice_amount
    };

    this.sendPaymentMilestonesUpdate();
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.transaction.currency !== prevState.currency) {
      this.setState({currency: prevProps.transaction.currency});
    }
    if(prevProps.transaction.commercial_invoice_amount !== prevState.commercial_invoice_amount) {
      this.setState({commercial_invoice_amount: prevProps.transaction.commercial_invoice_amount}, this.sendPaymentMilestonesUpdate());
    }
  }

  calculateFinalMilestoneAmount() {
    var amount = this.state.commercial_invoice_amount;
    this.state.payment_milestones.forEach(function (payment_milestone) {
      if(payment_milestone.amount) {
        amount -= payment_milestone.amount;
      }
    });
    return Converter.toDigits(amount, 2);
  }

  calculateMilestoneAmountPercentage(amount) {
    var commercial_invoice_amount = this.state.commercial_invoice_amount;
    return Converter.toDigits((amount / commercial_invoice_amount) * 100, 2);
  }

  sendPaymentMilestonesUpdate() {
    var milestones = [...this.state.payment_milestones];
    var finalAmount = this.calculateFinalMilestoneAmount();
    var finalCondition = "Before departure";

    milestones.push({ condition: finalCondition, amount: finalAmount, required_documents: [] });
    milestones.forEach(function (milestone, index) {
      milestone.rank = index + 1;
    });
    
    this.props.propertyUpdate("payment_milestones", milestones);
  }

  addPaymentMilestoneToList() {
    this.state.payment_milestones.push({ key: this.getUniqueKey(), required_documents: [] });
    this.setState({ payment_milestones: this.state.payment_milestones });
    this.sendPaymentMilestonesUpdate();
  }

  removePaymentMilestoneFromList(index) {
    this.state.payment_milestones.splice(index, 1);
    this.setState({ payment_milestones: this.state.payment_milestones });
    this.sendPaymentMilestonesUpdate();
  }
  
  handlPaymentMilestoneChange(index, property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.payment_milestones[index][property] = value;
    this.setState({ payment_milestones: this.state.payment_milestones });
    this.sendPaymentMilestonesUpdate();
  }

  addRequiredDocument(index, e) {
    this.state.payment_milestones[index].required_documents.push({ key: this.getUniqueKey() });
    this.setState({ payment_milestones: this.state.payment_milestones });
    this.sendPaymentMilestonesUpdate();
  }

  removeRequiredDocument(index, document_index, e) {
    this.state.payment_milestones[index].required_documents.splice(document_index, 1);
    this.setState({ payment_milestones: this.state.payment_milestones });
    this.sendPaymentMilestonesUpdate();
  }
  
  requiredDocumentTypeUpdated(required_document, parent_data) {
    this.state.payment_milestones[parent_data.index].required_documents[parent_data.document_index] = required_document;
    this.setState({ payment_milestones: this.state.payment_milestones });
    this.sendPaymentMilestonesUpdate();
  }

  isPaid(payment_milestone) {
    return payment_milestone.status && payment_milestone.status !== "outstanding";
  }

  render() {
    return (
      <div>
      
      <div className="row">
        <div className="col-md">
          <span>The escrow service is provided by Baltic Exchange (Asia) Pte Ltd, a wholly owned subsidiary of Baltic Exchange Limited. All funds are paid directly to Baltic Exchange’s escrow account and governed by the escrow agreement. Mercurion Trade Services B.V. facilitates access to the Escrow service, which is operated independently by Baltic Exchange (Asia) Pte Ltd.</span>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <span>All funds will be in escrow before the departure of the cargo.&nbsp;</span>
          <span>Additional payment miltestones, such as a deposit or an inspection, can be added to the transaction.&nbsp;</span>
          <span>The payment amount and required documents can be specified per milestone.</span>
        </div>
      </div>
      
      {this.state.payment_milestones.map((payment_milestone, index) => {
        return (
          <div key={payment_milestone.key} className="list-row-item">
            <span className="list-row-item-header-text">Payment Milestone {index + 1}</span>

              <div>
                <div className="col-md-12">
                  <Button
                    className="button-icon-only button-inline clear-list-row-item-button"
                    color="secondary"
                    type="button"
                    disabled={this.isPaid(payment_milestone)}
                    onClick={this.removePaymentMilestoneFromList.bind(this, index)}
                    startIcon={<Icon>clear</Icon>} />
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-4">
                  <div className="form-label">
                    <TextField
                      label="Condition"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      disabled={this.isPaid(payment_milestone)}
                      value={payment_milestone.condition}
                      onChange={this.handlPaymentMilestoneChange.bind(this, index, "condition")}                      
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <TextField
                    className={ payment_milestone.amount < 0 ? "negative-input" : ""}
                    label="Amount"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    disabled={this.isPaid(payment_milestone)}
                    value={payment_milestone.amount}
                    onChange={this.handlPaymentMilestoneChange.bind(this, index, "amount")}          
                    InputProps={{
                      inputComponent: Converter.numberFormatter,
                      startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.state.currency)}</InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <div className='span-after-field'>
                    <span><i>{this.calculateMilestoneAmountPercentage(payment_milestone.amount)}%</i></span>
                  </div>
                </div>
              </div>

              {payment_milestone.required_documents.map((required_document, document_index) => {
                return (
                  <div key={required_document.key} className="row">
                    <div className="col-md-4">
                      <DocumentTypeField
                        requiredDocumentTypeUpdated={this.requiredDocumentTypeUpdated.bind(this)}
                        required_document={required_document}
                        parent_data={{ index: index, document_index: document_index }}
                        disabled={this.isPaid(payment_milestone)}
                      />
                      <Button
                        className="button-icon-only button-inline end-off-input-button"
                        color="secondary"
                        type="button"
                        disabled={this.isPaid(payment_milestone)}
                        onClick={this.removeRequiredDocument.bind(this, index, document_index)}
                        startIcon={<Icon>clear</Icon>} />
                    </div>
                  </div>
                );
              })}
              
              <div className="row">
                <div className="col-md">
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={this.isPaid(payment_milestone)}
                    onClick={this.addRequiredDocument.bind(this, index)}
                    startIcon={<Icon>add</Icon>}>
                    Add required document
                  </Button>
                </div>
              </div>
            </div>
          );
        })}

        <div className="row">
          <div className="col-md">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addPaymentMilestoneToList.bind(this)}
              startIcon={<Icon>playlist_add_check</Icon>}>
              Add milestone
            </Button>
          </div>
        </div>

        <div className="list-row-item">
          <span className="list-row-item-header-text">Final Payment Milestone</span>

          <div className="row">
            <div className="col-md-4">
              <div className="form-label">
                <TextField
                  label="Condition"
                  variant="outlined"
                  required
                  disabled={true}
                  fullWidth
                  margin="dense"
                  value="Before departure"
                />
              </div>
            </div>
            <div className="col-md-4">
              <TextField
                className={ this.calculateFinalMilestoneAmount() < 0 ? "negative-input" : ""}
                label="Amount"
                variant="outlined"
                required
                disabled={true}
                fullWidth
                margin="dense"
                value={this.calculateFinalMilestoneAmount()}
                InputProps={{
                  inputComponent: Converter.numberFormatter,
                  startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.props.transaction.currency)}</InputAdornment>,
                }}
              />
            </div>
            <div className="col-md-4">
              <div className='span-after-field'>
                <span><i>{this.calculateMilestoneAmountPercentage(this.calculateFinalMilestoneAmount())}%</i></span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default PaymentMilestonesForm;

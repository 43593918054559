import React from 'react';
import confirm from '../../../../shared/components/confirm.js'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';

class ReviewCosts extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: {
        status: this.props.flow_status,
        action: "fee_accepted"
      }
    };
  }

  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to accept the costs for this transaction?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  rejectTransaction(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to reject this transaction?`).then((result) => {
      DomUtil.disableClass(button);
      this.props.updateTransaction( { 
        status: this.props.flow_status,
        action: "rejected"
      }).then(() => {
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }
    
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Confirm details</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please review the transaction details in the tabs above. The calculated Escrow Agent Fee can be found in the Escrow tab.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>

                <div className="row">
                  <div className="col-md">
                  <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Accept" />
                    <AsyncButton
                      className="button-danger-outlined"
                      variant="outlined"
                      color="danger"
                      onClick={this.rejectTransaction.bind(this)}
                      icon="delete"
                      text="Reject" />
                  </div>
                </div>

              </form>

            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default ReviewCosts;

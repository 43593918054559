"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Mercurion Trade Compliances Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompliancesApi = exports.CompliancesApiFactory = exports.CompliancesApiFp = exports.CompliancesApiAxiosParamCreator = exports.ComplianceDocumentsApi = exports.ComplianceDocumentsApiFactory = exports.ComplianceDocumentsApiFp = exports.ComplianceDocumentsApiAxiosParamCreator = exports.ShareholderType = exports.LegalForm = exports.Gender = exports.ComplianceDocumentStatus = exports.AuthorisationType = exports.AccountType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AccountType = {
    Admin: 'admin',
    Customer: 'customer',
    Escrow: 'escrow',
    Partner: 'partner'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AuthorisationType = {
    Director: 'director',
    Authorised: 'authorised'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ComplianceDocumentStatus = {
    Draft: 'draft',
    Submitted: 'submitted',
    Clarification: 'clarification',
    Approved: 'approved',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Gender = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.LegalForm = {
    Private: 'private',
    Public: 'public',
    Partnership: 'partnership',
    Limited: 'limited',
    Other: 'other'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ShareholderType = {
    NaturalPerson: 'natural_person',
    CorporateEntity: 'corporate_entity'
};
/**
 * ComplianceDocumentsApi - axios parameter creator
 * @export
 */
exports.ComplianceDocumentsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new compliance document
         * @param {Compliance} compliance
         * @param {string} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComplianceDocument: function (compliance, accountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'compliance' is not null or undefined
                            common_1.assertParamExists('createComplianceDocument', 'compliance', compliance);
                            localVarPath = "/compliance-documents";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (accountId !== undefined) {
                                localVarQueryParameter['account_id'] = accountId;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(compliance, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Download compliance document attachments
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComplianceDocumentAttachments: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('downloadComplianceDocumentAttachments', 'id', id);
                            localVarPath = "/compliance-documents/{id}/attachments"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get compliance document
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocument: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getComplianceDocument', 'id', id);
                            localVarPath = "/compliance-documents/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get compliance document count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocumentCount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/compliance-documents/count";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get compliance documents
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocuments: function (page, size, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/compliance-documents";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get latest compliance document
         * @param {string} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestComplianceDocument: function (accountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/compliance-documents/latest";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (accountId !== undefined) {
                                localVarQueryParameter['account_id'] = accountId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Sign compliance document
         * @param {string} id
         * @param {ComplianceDocumentSignature} complianceDocumentSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signComplianceDocument: function (id, complianceDocumentSignature, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('signComplianceDocument', 'id', id);
                            // verify required parameter 'complianceDocumentSignature' is not null or undefined
                            common_1.assertParamExists('signComplianceDocument', 'complianceDocumentSignature', complianceDocumentSignature);
                            localVarPath = "/compliance-documents/{id}/sign"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(complianceDocumentSignature, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update compliance document
         * @param {string} id
         * @param {ComplianceDocumentUpdate} complianceDocumentUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplianceDocument: function (id, complianceDocumentUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateComplianceDocument', 'id', id);
                            // verify required parameter 'complianceDocumentUpdate' is not null or undefined
                            common_1.assertParamExists('updateComplianceDocument', 'complianceDocumentUpdate', complianceDocumentUpdate);
                            localVarPath = "/compliance-documents/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(complianceDocumentUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ComplianceDocumentsApi - functional programming interface
 * @export
 */
exports.ComplianceDocumentsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ComplianceDocumentsApiAxiosParamCreator(configuration);
    return {
        /**
         * Create new compliance document
         * @param {Compliance} compliance
         * @param {string} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComplianceDocument: function (compliance, accountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createComplianceDocument(compliance, accountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Download compliance document attachments
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComplianceDocumentAttachments: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadComplianceDocumentAttachments(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get compliance document
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocument: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getComplianceDocument(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get compliance document count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocumentCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getComplianceDocumentCount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get compliance documents
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocuments: function (page, size, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getComplianceDocuments(page, size, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get latest compliance document
         * @param {string} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestComplianceDocument: function (accountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLatestComplianceDocument(accountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Sign compliance document
         * @param {string} id
         * @param {ComplianceDocumentSignature} complianceDocumentSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signComplianceDocument: function (id, complianceDocumentSignature, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.signComplianceDocument(id, complianceDocumentSignature, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update compliance document
         * @param {string} id
         * @param {ComplianceDocumentUpdate} complianceDocumentUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplianceDocument: function (id, complianceDocumentUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateComplianceDocument(id, complianceDocumentUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ComplianceDocumentsApi - factory interface
 * @export
 */
exports.ComplianceDocumentsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ComplianceDocumentsApiFp(configuration);
    return {
        /**
         * Create new compliance document
         * @param {Compliance} compliance
         * @param {string} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComplianceDocument: function (compliance, accountId, options) {
            return localVarFp.createComplianceDocument(compliance, accountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Download compliance document attachments
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComplianceDocumentAttachments: function (id, options) {
            return localVarFp.downloadComplianceDocumentAttachments(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get compliance document
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocument: function (id, options) {
            return localVarFp.getComplianceDocument(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get compliance document count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocumentCount: function (options) {
            return localVarFp.getComplianceDocumentCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get compliance documents
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceDocuments: function (page, size, options) {
            return localVarFp.getComplianceDocuments(page, size, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get latest compliance document
         * @param {string} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestComplianceDocument: function (accountId, options) {
            return localVarFp.getLatestComplianceDocument(accountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Sign compliance document
         * @param {string} id
         * @param {ComplianceDocumentSignature} complianceDocumentSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signComplianceDocument: function (id, complianceDocumentSignature, options) {
            return localVarFp.signComplianceDocument(id, complianceDocumentSignature, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update compliance document
         * @param {string} id
         * @param {ComplianceDocumentUpdate} complianceDocumentUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplianceDocument: function (id, complianceDocumentUpdate, options) {
            return localVarFp.updateComplianceDocument(id, complianceDocumentUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ComplianceDocumentsApi - object-oriented interface
 * @export
 * @class ComplianceDocumentsApi
 * @extends {BaseAPI}
 */
var ComplianceDocumentsApi = /** @class */ (function (_super) {
    __extends(ComplianceDocumentsApi, _super);
    function ComplianceDocumentsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new compliance document
     * @param {Compliance} compliance
     * @param {string} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.createComplianceDocument = function (compliance, accountId, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).createComplianceDocument(compliance, accountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Download compliance document attachments
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.downloadComplianceDocumentAttachments = function (id, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).downloadComplianceDocumentAttachments(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get compliance document
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.getComplianceDocument = function (id, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).getComplianceDocument(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get compliance document count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.getComplianceDocumentCount = function (options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).getComplianceDocumentCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get compliance documents
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.getComplianceDocuments = function (page, size, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).getComplianceDocuments(page, size, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get latest compliance document
     * @param {string} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.getLatestComplianceDocument = function (accountId, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).getLatestComplianceDocument(accountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Sign compliance document
     * @param {string} id
     * @param {ComplianceDocumentSignature} complianceDocumentSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.signComplianceDocument = function (id, complianceDocumentSignature, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).signComplianceDocument(id, complianceDocumentSignature, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update compliance document
     * @param {string} id
     * @param {ComplianceDocumentUpdate} complianceDocumentUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplianceDocumentsApi
     */
    ComplianceDocumentsApi.prototype.updateComplianceDocument = function (id, complianceDocumentUpdate, options) {
        var _this = this;
        return exports.ComplianceDocumentsApiFp(this.configuration).updateComplianceDocument(id, complianceDocumentUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ComplianceDocumentsApi;
}(base_1.BaseAPI));
exports.ComplianceDocumentsApi = ComplianceDocumentsApi;
/**
 * CompliancesApi - axios parameter creator
 * @export
 */
exports.CompliancesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get account compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCompliance: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/compliances/current";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update account compliance
         * @param {Compliance} compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountCompliance: function (compliance, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'compliance' is not null or undefined
                            common_1.assertParamExists('updateAccountCompliance', 'compliance', compliance);
                            localVarPath = "/compliances/current";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(compliance, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * CompliancesApi - functional programming interface
 * @export
 */
exports.CompliancesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CompliancesApiAxiosParamCreator(configuration);
    return {
        /**
         * Get account compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCompliance: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccountCompliance(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update account compliance
         * @param {Compliance} compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountCompliance: function (compliance, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAccountCompliance(compliance, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CompliancesApi - factory interface
 * @export
 */
exports.CompliancesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CompliancesApiFp(configuration);
    return {
        /**
         * Get account compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCompliance: function (options) {
            return localVarFp.getAccountCompliance(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update account compliance
         * @param {Compliance} compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountCompliance: function (compliance, options) {
            return localVarFp.updateAccountCompliance(compliance, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CompliancesApi - object-oriented interface
 * @export
 * @class CompliancesApi
 * @extends {BaseAPI}
 */
var CompliancesApi = /** @class */ (function (_super) {
    __extends(CompliancesApi, _super);
    function CompliancesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get account compliance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompliancesApi
     */
    CompliancesApi.prototype.getAccountCompliance = function (options) {
        var _this = this;
        return exports.CompliancesApiFp(this.configuration).getAccountCompliance(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update account compliance
     * @param {Compliance} compliance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompliancesApi
     */
    CompliancesApi.prototype.updateAccountCompliance = function (compliance, options) {
        var _this = this;
        return exports.CompliancesApiFp(this.configuration).updateAccountCompliance(compliance, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CompliancesApi;
}(base_1.BaseAPI));
exports.CompliancesApi = CompliancesApi;

import ParentActionRenderer from '../../../../shared/pages/freight-transactions/actions/parent_action_renderer';

class ActionRenderer extends ParentActionRenderer {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);
  }
  
  updateTransaction(data) {
    return this.props.updateTransaction(data);
  }

  render() {
    return super.render();
  }
}

export default ActionRenderer;

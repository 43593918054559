import React from 'react';

class StringUtil extends React.Component {

  static capitalize(str) {
    if(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  }

  static shortId(str) {
    if(str) {
      return str.slice(str.length - 6).toUpperCase();
    } else {
      return str;
    }
  }

  static maxOneDigit(num) {
    if(!num) {
      return ""
    }

    if (Number.isInteger(num)) { 
      return num;
    }
    
    return parseFloat(num).toFixed(1)
  }

  static commaSeparated(array) {
    if(array && array.length > 0) {
      return array.join(", ");
    } else {
      return "-";
    }
  }

}

export default StringUtil;
